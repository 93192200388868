import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import store from './store/store'
import './index.scss'

// import LoginScreen from './components/LoginScreen/LoginScreen'
// import Router from './Router/Router'
import {BrowserRouter} from 'react-router-dom'
import App from './Web/App/App'
import {HelmetProvider} from "react-helmet-async";
import ScrollToTop from "./Utils/ScrollToTop";
import {createStore} from "@reduxjs/toolkit";


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <HelmetProvider>
        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop enabled={true} />
                <App/>
            </BrowserRouter>
        </Provider>
    </HelmetProvider>
)
