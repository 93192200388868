import React, { useState, useEffect } from 'react'
import p from '../../../Web/profile/profile.module.scss'

import { Button, Table, Modal, Input, Select, Layout } from 'antd'

import { ReactComponent as EdditSVG } from '../../../image/Edit_light.svg'
import { Asterisk } from 'lucide-react'

import useFetch_profile from '../hooks/useFetch_profile'
const Staff_Seller = ({ fetchSellerStaff, setFetcSellerStaff }) => {
  const staffSellerResponse = useFetch_profile()
  const { Search } = Input
  const [dataList, setDataList] = useState([
    {
      key: '1',
      name: 'Ксения Потапова',
      status: 'Активен',
      role: 'Администратор',
      email: 'potapova@gmail.com',
    },
  ])
  const [newClient, setNewClient] = useState({
    key: dataList.length + 1,
    name: '',
    status: '',
    role: '',
    email: '',
  })
  const [sbr, setSbr] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const showModal2 = () => {
    setIsModalOpen2(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    console.log(newClient)
    setDataList([...dataList, newClient])
    setSbr(!sbr)
  }
  const handleOk2 = () => {
    console.log(newClient)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const handleCancel2 = () => {
    setIsModalOpen2(false)
  }
  const columns = [
    {
      title: '',
      dataIndex: 'red',
      render: (text) => (
        <Button type="text" onClick={showModal2}>
          <EdditSVG />
        </Button>
      ),
      width: '5%',
    },
    {
      title: 'Название',
      dataIndex: 'firstName',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (text) => {
        return (
          <Layout className={p.common_tableStatus}>
            <span>{text}</span>
          </Layout>
        )
      },
    },
    {
      title: 'Роль',
      dataIndex: 'username',
      render: (text) => {
        return (
          <Layout className={p.common_tableRol}>
            <span>{text}</span>
          </Layout>
        )
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
  ]

  useEffect(() => {
    staffSellerResponse.get(
      `${process.env.REACT_APP_DOMAIN_BFF}/trade/company/employee`,
      setFetcSellerStaff
    )
  }, [])

  useEffect(() => {
    setNewClient({
      key: dataList.length + 1,
      name: '',
      role: '',
      email: '',
    })
  }, [sbr])
  console.log(fetchSellerStaff)
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_staff_topInfo}>
        <span className="fontSiz_24_500">Сотрудники</span>
        <Layout style={{ width: '100%' }} className={p.common_staff_btnGrup}>
          <Search
            placeholder="Поиск"
            className={`${p.common_searchHead} searchHeadG`}
            //   prefix={<UserOutlined />}
          />
          <Button className={p.common_btnGrup_addClineBTN} onClick={showModal}>
            Добавить сотрудника
          </Button>
        </Layout>
      </Layout>
      <Layout>
        <Table
          className={p.common_table}
          columns={columns}
          dataSource={fetchSellerStaff}
        />
      </Layout>
      <Modal
        // className={p.common_staff_modalMain}
        // title="Добавить сотрудника"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'80%'}
        style={{ padding: '20px' }}
        className="modalDeled"
        footer={null}
      >
        <Layout className={p.common_modal_form}>
          <Layout className={p.common_modal_column}>
            <Layout
              className="fontSiz_32_500"
              style={{ textAlign: 'center', width: '100%' }}
            >
              Добавить сотрудника
            </Layout>
            <Layout className={p.common_modal_changePassForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  ФИО
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="ФИО"
                  value={newClient.name}
                  required
                  onChange={(e) =>
                    setNewClient({ ...newClient, name: e.target.value })
                  }
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Роль
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>

                <Select
                  placeholder="Роль"
                  className={p.common_chaild_Input}
                  defaultValue="Роль"
                  onChange={(val) => setNewClient({ ...newClient, role: val })}
                  //   onChange={handleChange}
                  options={[
                    {
                      value: 'Администратор',
                      label: 'Администратор',
                    },
                    {
                      value: 'Продавец',
                      label: 'Продавец',
                    },
                    {
                      value: 'Менеджер',
                      label: 'Менеджер',
                    },
                  ]}
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  E-mail
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="E-mail"
                  value={newClient.email}
                  onChange={(e) =>
                    setNewClient({ ...newClient, email: e.target.value })
                  }
                />
              </Layout>
            </Layout>
            <Button
              type="primary"
              className={p.common_modal_column_btnDelPost}
              style={{ width: '100%' }}
              onClick={handleOk}
            >
              Отправить заявку
            </Button>
          </Layout>
        </Layout>
      </Modal>
      <Modal
        // className={p.common_staff_modalMain}
        // title="Добавить сотрудника"
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        width={'80%'}
        style={{ padding: '20px' }}
        className="modalDeled"
        footer={null}
      >
        <Layout className={p.common_modal_form}>
          <Layout className={p.common_modal_column}>
            <Layout
              className="fontSiz_32_500"
              style={{ textAlign: 'center', width: '100%' }}
            >
              Редактировать данные сотрудника
            </Layout>
            <Layout className={p.common_modal_changePassForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  ФИО
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="ФИО"
                  value={newClient.name}
                  required
                  onChange={(e) =>
                    setNewClient({ ...newClient, name: e.target.value })
                  }
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Роль
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>

                <Select
                  placeholder="Роль"
                  className={p.common_chaild_Input}
                  defaultValue="Роль"
                  onChange={(val) => setNewClient({ ...newClient, role: val })}
                  //   onChange={handleChange}
                  options={[
                    {
                      value: 'Администратор',
                      label: 'Администратор',
                    },
                    {
                      value: 'Продавец',
                      label: 'Продавец',
                    },
                    {
                      value: 'Менеджер',
                      label: 'Менеджер',
                    },
                  ]}
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  E-mail
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="E-mail"
                  value={newClient.email}
                  onChange={(e) =>
                    setNewClient({ ...newClient, email: e.target.value })
                  }
                />
              </Layout>
            </Layout>
            <Button
              type="primary"
              className={p.common_modal_column_btnDelPost}
              style={{ width: '100%' }}
              onClick={handleOk2}
            >
              Отправить заявку
            </Button>
          </Layout>
        </Layout>
      </Modal>
    </Layout>
  )
}

export default Staff_Seller
