import React, {useEffect, useState} from 'react'
import ga from "../../../Web/general_admin.module.scss";
import {Button, Flex, Form, Input, InputNumber, Radio, Typography} from "antd";
import aca from "../AddProductsAdmin/AddProductsAdmin.module.scss";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import vnpa from './ViewNewProductAdmin.module.scss'
import TextArea from "antd/lib/input/TextArea";
import {useForm} from "antd/lib/form/Form";
import {useNotificationContext} from "../../../context/NotificationProvider";
import ModalViewNewProductAdminSendToRenew from "./ModalViewNewProductAdminSendToRenew";


const ViewNewProductAdmin = () => {
    const { product_id } = useParams()
    const navigate = useNavigate()
    const [form] = useForm()
    const [formData, setFormData] = useState(null)

    useEffect(() => {
        if (product_id) {
            fetch(`${process.env.REACT_APP_DOMAIN_BFF}/admin/draft/body/${product_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.status !== 200) {
                        openNotification('error', 'Ошибка получения данных!', `Статус: ${res.status}. Мы уже разбираемся в этой ошибке, приносим свои извинения!`)
                    } else return res
                })
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    setFormData(res)
                })
                .catch(e => {
                    openNotification('error', 'Ошибка получения данных!', `${e}`)
                    console.log(e)
                })
        }
    }, [product_id]);

    const [fetchProcess, setFetchProcess] = useState(false)
    const { openNotification } = useNotificationContext()

    const submitSendToRenew = (message) => {
        console.log(message)

        fetch(`${process.env.REACT_APP_DOMAIN_BFF}`, {
            method: ''
        })
            .then(res => {
                if (res.status !== 200) {
                    openNotification('error', 'Ошибка отправления на доработку!', `Статус: ${res.status}`)
                } else {
                    openNotification('success', 'Успешно отправлено на доработку!', `Администратор изменил статус продукта`)
                    handleCloseModalViewNewProductAdminSendToRenew()
                    navigate(-1)
                }
            })
    }

    const [openModalViewNewProductAdminSendToRenew, setOpenModalViewNewProductAdminSendToRenew] = useState(false)
    const handleCloseModalViewNewProductAdminSendToRenew = () => { setOpenModalViewNewProductAdminSendToRenew(false) }

    return (
        <Flex className={ga.container}>
            <Flex vertical justify={'flex-start'} align={'flex-start'} gap={'8px'}>
                <Button
                    className={aca.headerBackButton}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => { navigate(-1) }}
                    type={'link'}
                >
                    Назад
                </Button>
                <Typography.Text className={aca.headerTitle}>{`Просмотр нового продукта ${product_id}`}</Typography.Text>
            </Flex>
            <Flex vertical className={vnpa.container}>
                <Flex vertical className={vnpa.container_body}>
                    <Form
                        className={vnpa.form}
                        name={'first_step_form'}
                        form={form}
                        layout={'vertical'}
                        variant={'outlined'}
                    >
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Артикул'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Наименование'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Категория'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Страна производства'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                    <Form
                        className={vnpa.form}
                        name={'second_step_form'}
                        form={form}
                        layout={'vertical'}
                        variant={'outlined'}
                    >
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Описание'}
                            name={'фыв'}
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                        >
                            изображения
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                        >
                            2d чертеж
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                        >
                            Лицензия / сертификат
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                        >
                            3d модель
                        </Form.Item>
                    </Form>
                    <Form
                        className={vnpa.form}
                        name={'third_step_form'}
                        form={form}
                        layout={'vertical'}
                        variant={'outlined'}
                    >
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Цена, ₽'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'НДС (налог на добавочную стоимость)'}
                            name={'фыв'}
                        >
                            <Radio.Group>
                                <Radio value={'10'}>10%</Radio>
                                <Radio value={'20'}>20%</Radio>
                                <Radio value={'0'}>Налог не облагается</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Минимальный заказ, шт'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Количество в упаковке, шт'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Вес, кг'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Длина/ширина/высота упаковки, см'}
                            name={'фыв'}
                        >
                            <Flex>
                                <Form.Item name={'product_size_x'}>
                                    <InputNumber min={'0'} step={'0.001'} placeholder={'Длина'} />
                                </Form.Item>
                                <Form.Item name={'product_size_y'}>
                                    <InputNumber min={'0'} step={'0.001'} placeholder={'Ширина'} />
                                </Form.Item>
                                <Form.Item name={'product_size_z'}>
                                    <InputNumber min={'0'} step={'0.001'} placeholder={'Высота'} />
                                </Form.Item>
                            </Flex>
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Количество в наличии, шт'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Наличие'}
                            name={'фыв'}
                        >
                            <Radio.Group>
                                <Radio value={'true'}>В наличии</Radio>
                                <Radio value={'false'}>Под заказ</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Гарантийный срок, лет'}
                            name={'фыв'}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={vnpa.form_item}
                            label={'Делимость упаковки'}
                            name={'фыв'}
                        >
                            <Radio.Group>
                                <Radio value={'true'}>Да</Radio>
                                <Radio value={'false'}>Нет</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                    <Form
                        className={vnpa.form}
                        name={'fourth_step_form'}
                        form={form}
                        layout={'vertical'}
                        variant={'outlined'}
                    >
                        параметры
                    </Form>
                </Flex>
                <Flex className={vnpa.container_controls}>
                    <Button disabled={fetchProcess} onClick={() => {
                        setFetchProcess(true)
                        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/admin/draft/update/${product_id}/status/approved`, {
                            method: 'PUT'
                        })
                            .then(res => {
                                if (res.status !== 200) {
                                    openNotification('error', 'Ошибка изменения статуса', `Статус: ${res.status}. Мы уже разбираемся в этой ошибке, приносим свои извинения!`)
                                } else {
                                    openNotification('success', 'Успешно изменен статус', `Продукт подтвержден администратором`)
                                }
                                setFetchProcess(false)
                            })
                    }} className={vnpa.controls_button} type={'primary'}>Одобрить</Button>
                    <Button onClick={() => { setOpenModalViewNewProductAdminSendToRenew(true) }} disabled={fetchProcess} className={vnpa.controls_button} type={'primary'} ghost>Отправить на доработку</Button>
                </Flex>
            </Flex>

            <ModalViewNewProductAdminSendToRenew
                callback_open={openModalViewNewProductAdminSendToRenew}
                callback_close={handleCloseModalViewNewProductAdminSendToRenew}
                callback_submit={submitSendToRenew}
                fetchProcess={fetchProcess}
            />
        </Flex>
    )
}

export default ViewNewProductAdmin