import React, { useEffect, useState } from 'react'
import p from '../../../../Web/profile/profile.module.scss'
import { Asterisk } from 'lucide-react'
import { Button, Table, Modal, Input, Layout } from 'antd'
const ListClient_Distributor = () => {
  const [dataList, setDataList] = useState([
    {
      key: '1',
      name: 'ООО "Стройтех"',
      telephone: '+7 800 500-35-65',
      email: 'stroitex@example.com',
      website: 'example.com',
      responsible: 'Краков Андрей Иванович',
    },
    {
      key: '2',
      name: 'ООО "Стройтех"',
      telephone: '+7 800 500-35-65',
      email: 'stroitex@example.com',
      website: 'example.com',
      responsible: 'Краков Андрей Иванович',
    },
    {
      key: '3',
      name: 'ООО "Стройтех"',
      telephone: '+7 800 500-35-65',
      email: 'stroitex@example.com',
      website: 'example.com',
      responsible: 'Краков Андрей Иванович',
    },
    {
      key: '4',
      name: 'ООО "Стройтех"',
      telephone: '+7 800 500-35-65',
      email: 'stroitex@example.com',
      website: 'example.com',
      responsible: 'Краков Андрей Иванович',
    },
    {
      key: '5',
      name: 'ООО "Стройтех"',
      telephone: '+7 800 500-35-65',
      email: 'stroitex@example.com',
      website: 'example.com',
      responsible: 'Краков Андрей Иванович',
    },
    {
      key: '6',
      name: 'ООО "Стройтех"',
      telephone: '+7 800 500-35-65',
      email: 'stroitex@example.com',
      website: 'example.com',
      responsible: 'Краков Андрей Иванович',
    },
    {
      key: '7',
      name: 'ООО "Стройтех"',
      telephone: '+7 800 500-35-65',
      email: 'stroitex@example.com',
      website: 'example.com',
      responsible: 'Краков Андрей Иванович',
    },
    {
      key: '8',
      name: 'ООО "Стройтех"',
      telephone: '+7 800 500-35-65',
      email: 'stroitex@example.com',
      website: 'example.com',
      responsible: 'Краков Андрей Иванович',
    },
  ])
  const [newClient, setNewClient] = useState({
    key: dataList.length + 1,
    name: '',
    telephone: '',
    email: '',
    website: '',
    responsible: '',
  })
  const [sbr, setSbr] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    console.log(newClient)
    setDataList([...dataList, newClient])
    setSbr(!sbr)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setSbr(!sbr)
  }
  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Телефон',
      dataIndex: 'telephone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Сайт',
      dataIndex: 'website',
    },
    {
      title: 'Ответственное лицо',
      dataIndex: 'responsible',
    },
  ]

  useEffect(() => {
    setNewClient({
      key: dataList.length + 1,
      name: '',
      telephone: '',
      email: '',
      website: '',
      responsible: '',
    })
  }, [sbr])
  return (
    <Layout className={p.mainDiv}>
      <Layout
        className={p.common_staff_topInfo}
        style={{ flexDirection: 'row' }}
      >
        <span className="fontSiz_24_500">Список клиентов</span>
        <Button className={p.common_addClineBTN} onClick={showModal}>
          Добавить
        </Button>
      </Layout>
      <Layout>
        <Table
          className={p.common_table}
          rowSelection={{
            type: 'checkbox',
          }}
          columns={columns}
          dataSource={dataList}
        />
      </Layout>

      <Modal
        width={'80%'}
        style={{ padding: '20px' }}
        className="modalDeled"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Layout className={p.common_modal_form}>
          <Layout className={p.common_modal_column}>
            <Layout
              className="fontSiz_32_500"
              style={{ textAlign: 'center', width: '100%' }}
            >
              новый клиент
            </Layout>
            <Layout className={p.common_modal_changePassForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  TEXT
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="pass"
                  name="username"
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  TEXT
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="password"
                  name="username"
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  TEXT
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="text"
                  name="username"
                  required
                />
              </Layout>
            </Layout>
            <Button
              type="primary"
              className={p.common_modal_column_btnDelPost}
              style={{ width: '100%' }}
              //   onClick={handleChangePass}
            >
              TEXT
            </Button>
          </Layout>
        </Layout>
      </Modal>
    </Layout>
  )
}

export default ListClient_Distributor
