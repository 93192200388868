import React, {useState} from 'react';
import {Canvas, useThree} from '@react-three/fiber';
import {OrbitControls} from '@react-three/drei';
import '../3D.module.scss'
import StepModel from './StepModel';
import Ruler from '../Ruler/Ruler';
import Sphere from '../Ruler/Sphere';
import SpriteSpheres from '../Ruler/SpriteSpheres';
import Line from '../Ruler/Line';
import SpriteLine from '../Ruler/SpriteLine';
import OCIxyz from "./OCIxyz"
import SettingsScene from "./SettingsScene"
import Size from "./Size"
import useContextViewer from "../ContextViewer";
import {Flex} from "antd";

import css from '../3D.module.scss'
import StlLoader from "../../Loaders/StlLoader";
import GlbLoader from "../../Loaders/GlbLoader";
import ObjLoader from "../../Loaders/ObjLoader";

const Sized = ({objectName}) => {
    const { gl, camera, scene } = useThree();
    const obj=scene.getObjectByName( objectName );
    console.log(obj)
    return (
        <Size obj={obj} />
    )
}

const Scene = ({url}) => {
    const {intersections} = useContextViewer();

    const [isLoaded, setIsLoaded] = useState(false)

    const defineType = (url) => {
        switch (url.split('/').splice(-1)[0]) {
            case 'stp':
                return <StepModel url={url} />;
            case 'stl':
                return <>
                    <StlLoader setIsLoaded={setIsLoaded} url={url} />
                    { isLoaded && <Sized objectName={"StlObject"} />}
                </>;
            case 'glb':
                return <GlbLoader url={url} />;
            case 'obj':
                return <ObjLoader url={url} />;
            default:
                return <></>
        }
    }

    return (
        <Flex id="canvas_container" className={css.canvas_container}>
            <Canvas>
                <SettingsScene/>
                <OrbitControls/>
                {defineType(url)}
                <OCIxyz/>
                <gridHelper rotation={[Math.PI / 2, 0, 0]} position={[0, 0, 0.1]} renderOrder={1} args={[500, 500]}/>
                <Ruler/>

                {intersections.map((point, i, uuid) => (
                    <Sphere key={`sphere-${i}`} position={point} uuidData={uuid}/>
                ))}
                {intersections.map((start, end, index) => (
                    <Line key={`line-${index}`} start={start} end={end} color='red'/>
                ))}
                {intersections.map((point, index) => (
                    <SpriteSpheres point={point} key={`sprite-${index}`}/>
                ))}
                {intersections.map((point, index) => (
                    <SpriteLine point={point} key={`sprite-${index}`}/>
                ))}
            </Canvas>
        </Flex>
    );
}
export default Scene;