import React, { useState } from 'react'
import p from '../../../Web/profile/profile.module.scss'
//! ANTD
import {
  Button,
  Input,
  Modal,
  Layout,
  Select,
  Radio,
  Rate,
  Switch,
  Checkbox,
} from 'antd'
import { Link } from 'react-router-dom'
import SVGfavorite from '../../../image/Favorites.svg'
import SVGstate from '../../../image/Stat.svg'
import Star_violet from '../../../image/Star_violet.svg'
const Favourites_Buyer = () => {
  const [choiceFavourites, setChoosingFavourites] = useState('2024')
  const [Arr, setArr] = useState(5)
  return (
    <Layout className={p.mainDivBuyer}>
      <Layout className={p.common_buyer_Left}>
        <Layout className={p.common_buyer_LeftHedr}>
          <span className="fontSiz_24_500">Избранное</span>
        </Layout>
        <Layout className={p.common_buyer_LSelectAll}>
          <Radio.Group
            className="radioGrpBtn"
            defaultValue="all"
            buttonStyle="solid"
            onChange={(e) => setChoosingFavourites(e.target.value)}
          >
            <Radio.Button className="radBtn" value="all">
              Все
            </Radio.Button>
            <Radio.Button className="radBtn" value="Category">
              Категория
            </Radio.Button>
            <Radio.Button className="radBtn" value="Category2">
              Категория
            </Radio.Button>
            <Radio.Button className="radBtn" value="Category3">
              Категория
            </Radio.Button>
          </Radio.Group>
        </Layout>
        <Layout className={p.common_buyer_cardDiv}>
          <Layout
            className={p.common_buyer_cardOrderSlid}
            // style={{ width: '100%' }}
          >
            <Layout className={p.common_buyer_slider}>
              <span className="fontSiz_12_400">{`Артикул 2`}</span>
              <Layout className={p.common_buyer_boxImgStar}>
                <img
                  src={require('../../../image/freza2.png')}
                  alt="df"
                  style={{ width: '21px', height: '140px' }}
                />
                <Layout>
                  <img src={SVGfavorite} alt="React Logo" />
                  <img src={SVGstate} alt="React Logo" />
                </Layout>
              </Layout>
              <span className="fontSiz_16_400Blc">
                Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                Туламаш
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                +2 предложения от 20 000 ₽
              </span>
              <Layout className={p.common_buyer_btnPrice}>
                <span className="fontSiz_16_500">{`10000 ₽`}</span>
                <Button className={p.common_buyer_addBasket}>В корзину</Button>
              </Layout>
            </Layout>
            <Layout className={p.common_buyer_slider}>
              <span className="fontSiz_12_400">{`Артикул 2`}</span>
              <Layout className={p.common_buyer_boxImgStar}>
                <img
                  src={require('../../../image/freza2.png')}
                  alt="df"
                  style={{ width: '21px', height: '140px' }}
                />
                <Layout>
                  <img src={SVGfavorite} alt="React Logo" />
                  <img src={SVGstate} alt="React Logo" />
                </Layout>
              </Layout>
              <span className="fontSiz_16_400Blc">
                Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                Туламаш
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                +2 предложения от 20 000 ₽
              </span>
              <Layout className={p.common_buyer_btnPrice}>
                <span className="fontSiz_16_500">{`10000 ₽`}</span>
                <Button className={p.common_buyer_addBasket}>В корзину</Button>
              </Layout>
            </Layout>
            <Layout className={p.common_buyer_slider}>
              <span className="fontSiz_12_400">{`Артикул 2`}</span>
              <Layout className={p.common_buyer_boxImgStar}>
                <img
                  src={require('../../../image/freza2.png')}
                  alt="df"
                  style={{ width: '21px', height: '140px' }}
                />
                <Layout>
                  <img src={SVGfavorite} alt="React Logo" />
                  <img src={SVGstate} alt="React Logo" />
                </Layout>
              </Layout>
              <span className="fontSiz_16_400Blc">
                Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                Туламаш
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                +2 предложения от 20 000 ₽
              </span>
              <Layout className={p.common_buyer_btnPrice}>
                <span className="fontSiz_16_500">{`10000 ₽`}</span>
                <Button className={p.common_buyer_addBasket}>В корзину</Button>
              </Layout>
            </Layout>
            <Layout className={p.common_buyer_slider}>
              <span className="fontSiz_12_400">{`Артикул 2`}</span>
              <Layout className={p.common_buyer_boxImgStar}>
                <img
                  src={require('../../../image/freza2.png')}
                  alt="df"
                  style={{ width: '21px', height: '140px' }}
                />
                <Layout>
                  <img src={SVGfavorite} alt="React Logo" />
                  <img src={SVGstate} alt="React Logo" />
                </Layout>
              </Layout>
              <span className="fontSiz_16_400Blc">
                Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                Туламаш
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                +2 предложения от 20 000 ₽
              </span>
              <Layout className={p.common_buyer_btnPrice}>
                <span className="fontSiz_16_500">{`10000 ₽`}</span>
                <Button className={p.common_buyer_addBasket}>В корзину</Button>
              </Layout>
            </Layout>
            <Layout className={p.common_buyer_slider}>
              <span className="fontSiz_12_400">{`Артикул 2`}</span>
              <Layout className={p.common_buyer_boxImgStar}>
                <img
                  src={require('../../../image/freza2.png')}
                  alt="df"
                  style={{ width: '21px', height: '140px' }}
                />
                <Layout>
                  <img src={SVGfavorite} alt="React Logo" />
                  <img src={SVGstate} alt="React Logo" />
                </Layout>
              </Layout>
              <span className="fontSiz_16_400Blc">
                Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                Туламаш
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                +2 предложения от 20 000 ₽
              </span>
              <Layout className={p.common_buyer_btnPrice}>
                <span className="fontSiz_16_500">{`10000 ₽`}</span>
                <Button className={p.common_buyer_addBasket}>В корзину</Button>
              </Layout>
            </Layout>
            <Layout className={p.common_buyer_slider}>
              <span className="fontSiz_12_400">{`Артикул 2`}</span>
              <Layout className={p.common_buyer_boxImgStar}>
                <img
                  src={require('../../../image/freza2.png')}
                  alt="df"
                  style={{ width: '21px', height: '140px' }}
                />
                <Layout>
                  <img src={SVGfavorite} alt="React Logo" />
                  <img src={SVGstate} alt="React Logo" />
                </Layout>
              </Layout>
              <span className="fontSiz_16_400Blc">
                Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                Туламаш
              </span>
              <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                +2 предложения от 20 000 ₽
              </span>
              <Layout className={p.common_buyer_btnPrice}>
                <span className="fontSiz_16_500">{`10000 ₽`}</span>
                <Button className={p.common_buyer_addBasket}>В корзину</Button>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Favourites_Buyer
