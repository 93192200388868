import React from 'react';
import {Button, Drawer, Layout} from "antd";
import { createStyles, useTheme } from 'antd-style';
import {Link} from 'react-router-dom'
import closeButton from '../../../image/closeButton.svg'
import d from '../ItemDrawers.module.scss'

const DrawerItemDescription = ({data, callback_open, callback_cancel}) => {
    const token = useTheme();
    const drawerStyles = {
        content: {
            borderRadius: '12px 0 0 12px',
            backgroundColor: '#F0F3F5',
        },
    };

    return (
        <Drawer
            className={d.drawerBlock}
            width={656}
            title={'Описание'}
            onClose={callback_cancel}
            open={callback_open}
            styles={drawerStyles}
        >
            <Layout className={d.container}>
                <Layout className={d.infoBlock}>
                    <Layout className={d.infoBlockItem}>
                        <span className={d.infoBlockItemHeader}>Описание</span>
                        <Layout className={`${d.infoBlockItemBody}`}>
                            <span className={d.infoBlockItemBodyTextarea}>
                                {data?.description}
                            </span>
                        </Layout>
                    </Layout>
                    <Layout className={d.infoBlockItem}>
                        <span className={d.infoBlockItemHeader}>Все характеристики</span>
                        <Layout className={`${d.infoBlockItemBody}`}>
                            <table className={d.infoBlockItemBodyTable}>
                                <tbody>
                                <tr>
                                    <td>
                                        <span>
                                            Производитель
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {data?.manufacture?.name}
                                        </span>
                                    </td>
                                </tr>
                                {data?.params?.map((el, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <span>
                                                    {el.alias}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {el.value}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </Layout>
                    </Layout>
                    <Layout className={d.infoBlockItem}>
                        <span className={d.infoBlockItemHeader}>Области применения</span>
                        <Layout className={`${d.infoBlockItemBody}`}>
                            <Layout className={d.infoBlockItemBodyTags}>
                                <Link className={d.infoBlockItemBodyTag}>
                                    Сталь
                                </Link>
                                <Link className={d.infoBlockItemBodyTag}>
                                Нержавеющая сталь
                                </Link>
                                <Link className={d.infoBlockItemBodyTag}>
                                    Цветные металлы
                                </Link>
                                <Link className={d.infoBlockItemBodyTag}>
                                    Чугун
                                </Link>
                                <Link className={d.infoBlockItemBodyTag}>
                                    Цветные металлы
                                </Link>
                                <Link className={d.infoBlockItemBodyTag}>
                                    Цветные металлы
                                </Link>
                                <Link className={d.infoBlockItemBodyTag}>
                                    Цветные металлы
                                </Link>
                            </Layout>
                        </Layout>
                    </Layout>
                    <Layout className={d.infoBlockItem}>
                        <span className={d.infoBlockItemHeader}>Дополнительная информация</span>
                        <Layout className={`${d.infoBlockItemBody}`}>
                            <table className={d.infoBlockItemBodyTable}>
                                <tbody>
                                <tr>
                                    <td>
                                        <span>
                                            Характеристика
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            значение
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            Характеристика
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            значение
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            Характеристика
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            значение
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Layout>
                    </Layout>
                </Layout>
                <Layout className={d.buttonBlock}>
                    <Button type={'link'} className={d.closeButton} onClick={() => {callback_cancel()}}>
                        <img src={closeButton}/>
                    </Button>
                </Layout>
            </Layout>
        </Drawer>
    )
}

export default DrawerItemDescription;