import {createContext, React, useContext, useEffect, useState} from 'react'
import {Button, ConfigProvider, Layout, notification} from 'antd'
import {Outlet} from 'react-router'
import s from '../App/App.module.css'
import MenuModel from '../../components/MenuModel/MenuModel'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

import g from './GeneralComponent.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {getNewCard} from '../../store/newProducts/newProductsSlice'
import {THEME_GENERAL, THEME_TEST} from "../../Utils/Themes";

import theme_css from '../../Utils/Themes.module.scss'
import ruRU from "antd/locale/ru_RU";
import ModalRegistrationForm from "../../components/AuthForms/ModalRegistrationForm";
import DrawerLoginForm from "../../components/AuthForms/DrawerLoginForm";
import useNotification from "antd/lib/notification/useNotification";
import {NotificationProvider} from "../../context/NotificationProvider";

const GeneralComponent = () => {
    const [userName, setUserName] = useState()

    // useEffect(() => {
    //     localStorage.setItem('userName', )
    // }, [userName])

    const dispatch = useDispatch()

    const isLoginOpen = useSelector((state) => state.modals.openLogin)
    const isRegisterOpen = useSelector((state) => state.modals.openRegister)


    const handleCloseModalRegistrationForm = () => { dispatch({type: 'TOGGLE_REGISTER_FORM', payload: false}) }
    const handleCloseDrawerLoginForm = () => { dispatch({type: 'TOGGLE_LOGIN_FORM', payload: false}) }

    // useEffect(() => {
    //     dispatch({type: 'modals/login', payload: isLogin ?? false})
    // }, [openDrawerLoginForm])
    // useEffect(() => {
    //     dispatch({type: 'modals/register', payload: isRegister ?? false})
    // }, [openModalRegistrationForm])

    useEffect(() => {
        dispatch(getNewCard('/catalog/news'))
    }, [dispatch])
    const [openDrawerLogin, setOpenDrawerLogin] = useState(false)
    const handleCloseDrawerLogin = () => {
        setOpenDrawerLogin(false)
    }

    return (
        <Layout>
            <ConfigProvider
                locale={ruRU} theme={THEME_TEST} wave={{disabled: true}}
            >
                <NotificationProvider>
                    <Layout className={`${s.app} ${theme_css.GeneralContainer__Theme}`}>
                        {/*<MenuModel*/}
                        {/*    setToggleClass={setOpenDrawerLogin}*/}
                        {/*    setUserName={setUserName}*/}
                        {/*    openDrawerLogin={openDrawerLogin}*/}
                        {/*    handleCloseDrawerLogin={handleCloseDrawerLogin}*/}
                        {/*/>*/}
                        <Header

                            // setOpenDrawerLogin={setOpenDrawerLogin}
                            userName={userName}
                            // role={role}
                        />
                        <Layout className={g.containerBody}>
                            <Outlet/>
                        </Layout>
                        <Footer/>

                        <ModalRegistrationForm
                            callback_open={isRegisterOpen}
                            callback_close={handleCloseModalRegistrationForm}
                        />
                        <DrawerLoginForm
                            callback_open={isLoginOpen}
                            callback_close={handleCloseDrawerLoginForm}
                        />
                    </Layout>
                </NotificationProvider>
            </ConfigProvider>
        </Layout>
    )
}

export default GeneralComponent
