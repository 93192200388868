import React from 'react'
import s from './NewProducts.module.scss'
import { Link } from 'react-router-dom'
import { Button, Layout, Skeleton } from 'antd'
import SVGstate from '../../image/Stat.svg'
import SVGfavorite from '../../image/Favorites.svg'
import Star_violet from '../../image/Star_violet.svg'
//! SLIDER
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
}
const NewProducts = ({ card, isLoading }) => {
  // console.log(isLoading)
  // console.log(card)
  console.log(card)
  return (
    <div className={s.mainNewPr}>
      <span className="fontSiz_32_500">Новинки</span>

      <Slider {...settings}>
        {!isLoading
          ? Array.from({ length: 6 }, (e, i) => (
              <Skeleton
                key={i}
                active
                style={{
                  height: '100%',
                  width: '100%',
                }}
                //  className={cei.gridItemPicture}
                className={s.sliderDivMain}
              ></Skeleton>
            ))
          : card.map(
              ({ id, article, preview, manufacture_name, name, category, manufacturer_id }) => {
                // console.log(category.filter((el, i) => i != 0).join('+'))
                // console.log(category.map((el) => `${el}`).join('+'))
                return (
                  <Link to={`catalog/${category.toSpliced(0, 1).join('+')}/content/${id}`} className={s.sliderDivMain} key={id}>
                    <span className="fontSiz_12_400">{`Артикул ${article}`}</span>
                    <div className={s.boxImgStar}>
                      <img
                        src={`${process.env.REACT_APP_DOMAIN_CDN}${preview}`}
                        alt="df"
                        style={{ width: '140px', height: '140px' }}
                      />
                      <div>
                        <img src={SVGfavorite} alt="React Logo" />
                        <img src={SVGstate} alt="React Logo" />
                      </div>
                    </div>
                    <div className={s.starFlex}>
                      <div className={s.stChFlex}>
                        {Array.from(Array(5)).map((el, i) => {
                          return (
                            <img src={Star_violet} alt="React Logo" key={i} />
                          )
                        })}
                      </div>
                      <div>
                        <span className="fontSiz_12_400">5 отзывов</span>
                      </div>
                    </div>
                    <div className={s.slTexLink}>
                      <span style={{ overflow: 'hidden', color: '#0B0B0C', fontWeight: '400' }}>{name}</span>
                      <Link
                          style={{width: '100%', color: '#8D24F8'}}
                        to={manufacture_name ? `/manufacturer/${manufacture_name}` : ''}
                        state={{ id: id }}
                        // to={`${process.env.REACT_APP_DOMAIN_BFF}/catalog/product/${id}`}
                      >
                        {manufacture_name}
                      </Link>
                    </div>
                    <span
                      className="fontSiz_12_400"
                      style={{ color: '#8D24F8' }}
                    >
                      +2 предложения от 20 000 ₽
                    </span>
                    <div className={s.btnStar} style={{color: '#0B0B0C'}}>
                      {`${'3500'} ₽`}
                      <Button size={'small'} className={s.addBasket}>В корзину</Button>
                    </div>
                  </Link>
                )
              }
            )}
      </Slider>
    </div>
  )
}

export default NewProducts
