import React, {useState} from 'react'
import {Alert, Checkbox, Flex, Form, Input, Modal, Segmented, Typography} from "antd";
import {Link} from 'react-router-dom'
import {useForm} from "antd/lib/form/Form";

import af from './AuthForms.module.scss'
import theme from '../../Utils/Themes.module.scss'

const ModalRegistrationForm = ({
    callback_open,
    callback_close
                               }) => {
    const [form] = useForm()
    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    const callback_submit = () => {

    }

    const [registerType, setRegisterType] = useState('buyer')

    const [result, setResult] = useState([])
    const handleSearch = (value) => {
        let res = []
        if (!value || value.indexOf('@') >= 0) {
            res = []
        } else {
            res = ['gmail.com', '163.com', 'qq.com'].map(
                (domain) => `${value}@${domain}`
            )
        }

        setResult(res)
    }
    const [stateRole, setStateRole] = useState("buyer")
    const [inputsValue, setInputsValue] = useState({
        name: "",
        username: "",
        email: "",
        password: ""
    })
    const fetchRegister = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN_BFF}/auth/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(inputsValue)
            })
            const data = await response.json()
            console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    const [innStatus, setInnStatus] = useState('success')

    const validateInn = (value) => {
        let status = 'error'

        if (/[0-9]/.test(value)) {
            let numberArray = value.split('').map((char, i) => {
                return parseInt(char)
            })
            if (numberArray.length === 10) {
                const pattern = [2,4,10,3,5,9,4,6,8]
                let sum = pattern.map((char, index) => char * numberArray[index]).reduce((sum, current) => sum + current)
                let sumResult = sum % 11 <= 9 ? sum % 11 : parseInt(`${sum % 11}`[`${sum % 11}`.length-1])

                if (sumResult === numberArray.slice(-1)[0]) {
                    status = 'success'
                }
            } else if (numberArray.length === 12) {
                const patternFirst =  [7,2,4,10,3 ,5,9,4,6,8];
                const patternSecond = [3,7,2,4 ,10,3,5,9,4,6,8];

                let sumFirst = patternFirst.map((char, index) => char * numberArray[index]).reduce((sum, current) => sum + current)
                let sumSecond = patternSecond.map((char, index) => char * numberArray[index]).reduce((sum, current) => sum + current)

                if (sumFirst % 11 === numberArray.slice(-2, -1)[0] && sumSecond % 11 === numberArray.slice(-1)[0]) {
                    status = 'success'
                }
            }
        }

        setInnStatus(status)
    }

    return (
        <Modal
            className={`${theme.GeneralContainer__Theme} ${af.modal}`}
            centered
            open={callback_open}
            onCancel={callback_close}
            width={1064}
            okButtonProps={{
                style: {margin: 0},
                size: 'large',
                type: 'default',
                htmlType: 'submit',
            }}
            cancelButtonProps={{
                size: 'large',
                type: 'primary'
            }}
            okText={'Отправить заявку'}
            confirmLoading={isWaitingResponse}
            modalRender={(dom) => (
                <Form
                    form={form}
                    className={af.modal_layout}
                    layout={'vertical'}
                    variant={'outlined'}
                    style={{
                        maxWidth: '100%',
                    }}
                    onFinish={(values) => callback_submit(values)}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Flex vertical className={af.registerBlock} align={'center'} justify={'center'}>
                <Typography.Text className={af.registerBlock__title}>Регистрация</Typography.Text>
                <Segmented
                    defaultValue={registerType}
                    options={[
                        {label: 'Для покупателя', value: 'buyer'},
                        {label: 'Для производителя', value: 'manufacture'}
                    ]}
                    onChange={(value) => {
                        setRegisterType(value)
                    }}
                />
                <Alert className={af.registerMessage} message={`Уважаемые ${registerType === 'manufacture' ? "производители" : "покупатели"}! Для доступа к маркетплейсу Вам необходимо заполнить контактные данные. Подтверждение о регистрации Вам придет в течение одного рабочего дня на указанную почту.`} />
                {registerType === 'manufacture' &&<Flex justify={'flex-start'} style={{width: '100%'}} gap={'small'}>
                    <Checkbox/>
                    <Typography.Text>Являюсь Торговой компанией</Typography.Text>
                </Flex>}
                <Flex vertical style={{width: '100%'}}>
                    <Flex style={{gap: '24px'}}>
                        <Form.Item
                            className={af.formItem}
                            layout="vertical"
                            label="E-mail"
                            name="email"
                            rules={[
                                {required: true, type: 'email'},
                            ]}
                        >
                            <Input placeholder={'Электронная почта'} />
                        </Form.Item>
                        {registerType === 'manufacture' &&
                        <Form.Item
                            className={af.formItem}
                            layout="vertical"
                            label="Сайт"
                            name="site"
                            rules={[
                                {required: false, type: 'url'},
                            ]}
                        >
                            <Input placeholder={'Ссылка на ваш сайт'} />
                        </Form.Item>}
                    </Flex>
                    <Flex style={{gap: '24px'}}>
                        <Form.Item
                            className={af.formItem}
                            layout="vertical"
                            label="Страна"
                            name="country"
                            rules={[
                                {required: true,},
                            ]}
                        >
                            <Input placeholder={'Например: Российская Федерация'} />
                        </Form.Item>
                        <Form.Item
                            className={af.formItem}
                            layout="vertical"
                            label="ФИО"
                            name="fio"
                            rules={[
                                {required: true,},
                            ]}
                        >
                            <Input placeholder={'Например: Гаджиев А.Т.'} />
                        </Form.Item>
                    </Flex>
                    <Flex style={{gap: '24px', marginBottom: '-24px'}}>
                        {registerType === 'manufacture' && <Form.Item
                            className={af.formItem}
                            layout="vertical"
                            label="ИНН"
                            name="inn"
                            rules={[
                                {required: true, },
                            ]}
                            validateStatus={innStatus}
                            help={innStatus !== 'success' && 'ИНН не корректный'}
                        >
                            <Input onChange={(e) => validateInn(e.target.value)} placeholder={'Ваш ИНН'} />
                        </Form.Item>}
                        <Form.Item
                            className={af.formItem}
                            layout="vertical"
                            label="Телефон"
                            name="phone"
                            rules={[
                                {required: true},
                            ]}
                        >
                            <Input placeholder={'Например: +79871234567'} />
                        </Form.Item>
                    </Flex>
                </Flex>
                <Flex vertical style={{width: '100%'}}>
                    <Flex gap={'small'} align={'flex-start'}>
                        <Checkbox />
                        <Typography.Text>Я согласен (согласна) на обработку моих персональных данных в соответствии с условиями <Link>«Политики конфиденциальности»</Link></Typography.Text>
                    </Flex>
                    <Flex gap={'small'} align={'flex-start'}>
                        <Checkbox />
                        <Typography.Text>Я ознакомился (ознакомилась) и принимаю <Link>«Условия использования Платформы»</Link></Typography.Text>
                    </Flex>
                </Flex>
            </Flex>
        </Modal>
    )
}

export default ModalRegistrationForm