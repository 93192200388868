import React, { useEffect, useState } from 'react'
import s from './Registration.module.css'
import { Link } from 'react-router-dom'
import {
  Button,
  Input,
  Tooltip,
  AutoComplete,
  InputNumber,
  Segmented,
} from 'antd'
const { Option } = AutoComplete
const Registration = ({ handleOk }) => {
  const [result, setResult] = useState([])
  const handleSearch = (value) => {
    let res = []
    if (!value || value.indexOf('@') >= 0) {
      res = []
    } else {
      res = ['gmail.com', '163.com', 'qq.com'].map(
          (domain) => `${value}@${domain}`
      )
    }

    setResult(res)
  }
  const [stateRole, setStateRole] = useState("buyer")
  const [inputsValue, setInputsValue] = useState({
    name: "",
    username: "",
    email: "",
    password: ""
  })
  const fetchRegister = async () => {
    try {
      const response = await fetch("http://mp.istokmw.tech/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(inputsValue)
      })
      const data = await response.json()
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className={s.wrapper}>
      <div className={s.wrpchld}>
        <span className="fontSiz_32_500">Регистрация</span>
        <div className={s.radio_frm}>
          <div className={s.radio_container}>
           <div style={{backgroundColor:stateRole === "buyer" ? "#FFF" :"" }} onClick={()=>setStateRole("buyer")} className={s.registration__userRole}>
            <span style={{color:stateRole === "buyer" ? "#8D24F8" :"" }}>Для покупателя</span>
           </div>
           <div style={{backgroundColor:stateRole === "manufacturer" ? "#FFF" :"" }} onClick={()=>setStateRole("manufacturer")} className={s.registration__userRole}>
            <span  style={{color:stateRole === "manufacturer" ? "#8D24F8" :"" }}>Для производителя</span>
           </div>
          </div>
        </div>
        <div className={s.infoReg}>
          <span className="fontSiz_16_400Blc">
            Уважаемые производители! Для регистрации Вам необходимо заполнить
            форму заявки. После обработки заявки Вам придет уведомление на
            электронную почту.
          </span>
        </div>
        <div className={s.inputGrp}>
          <div className={`${s.inpGrp1} ${s.inpGrpChld}`}>
            <div className={s.inputGrpCh}>
              <label className="fontSiz_16_400Blc" htmlFor="email">
                Логин / E-mail
              </label>
              {/* <input type="email" id="email" /> */}
              <AutoComplete
                className={s.inAutoReg}
                style={{
                  height: '5vh',
                }}
                onSearch={handleSearch}
                placeholder="email"
                onChange={(e)=>setInputsValue({...inputsValue, email:e})}
              >
                {result.map((email) => (
                  <Option key={email} value={email}>
                    {email}
                  </Option>
                ))}
              </AutoComplete>
            </div>
            <div className={s.inputGrpCh}>
              <label className="fontSiz_16_400Blc" htmlFor="country">
                Страна
              </label>
              <Input value={"Российская федерация"} disabled placeholder="Страна" id="country" />
            </div>
            <div className={s.inputGrpCh}>
              <label className="fontSiz_16_400Blc" htmlFor="inn">
                ИНН
              </label>
              {/* <input type="number" id="inn" /> */}
              <InputNumber
                placeholder="ИНН"
                min={1}
                max={9999999999}
                id="inn"
                style={{ height: '5vh' }}
              />
            </div>
          </div>
          <div className={`${s.inpGrp2} ${s.inpGrpChld}`}>
            <div className={s.inputGrpCh}>
              <label className="fontSiz_16_400Blc" htmlFor="web">
                Сайт
              </label>
              {/* <input type="text" id="web" /> */}
              <Input onChange={(e)=>setInputsValue({...inputsValue, username:e.target.value})} placeholder="Сайт" id="web" />
            </div>
            <div className={s.inputGrpCh}>
              <label className="fontSiz_16_400Blc" htmlFor="fio">
                ФИО
              </label>
              <Input onChange={(e)=>setInputsValue({...inputsValue, name:e.target.value})} placeholder="ФИО" id="fio" />
            </div>
            <div className={s.inputGrpCh}>
              <label className="fontSiz_16_400Blc" htmlFor="telephone">
                Телефон
              </label>
              <Input onChange={(e)=>setInputsValue({...inputsValue, password:e.target.value})} placeholder="Телефон" id="telephone" />
            </div>
          </div>
        </div>
        <div className={s.cheakboxReg}>
          <div className={s.cheak_1reg}>
            <input type="checkbox" name="radio" id="agree_1" />
            <label htmlFor="agree_1">
              Я согласен (согласна) на обработку моих персональных данных в
              соответствии с условиями&nbsp;
              <Link
                to="#"
                className="fontSiz_16_400Blc"
                style={{ color: '#8A24F8' }}
              >
                «Политики конфиденциальности»
              </Link>
            </label>
          </div>
          <div className={s.cheak_2reg}>
            <input type="checkbox" name="radio" id="agree_2" />
            <label htmlFor="agree_2">
              Я ознакомился (ознакомилась) и принимаю&nbsp;
              <Link
                to="#"
                className="fontSiz_16_400Blc"
                style={{ color: '#8A24F8' }}
              >
                «Условия использования Платформы»
              </Link>
            </label>
          </div>
        </div>
        <div className={s.btnSub}>
          <Button  type="primary" className={s.subReg} onClick={()=>{
            const firstCheckbox = document.querySelector("#agree_1")
            const secondCheckbox = document.querySelector("#agree_2")
            console.log(firstCheckbox.checked, secondCheckbox.checked)
            if(firstCheckbox.checked && secondCheckbox.checked){
                 fetchRegister();
            }
          }}>
            Отправить заявку{' '}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Registration
