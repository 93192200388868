import React, {useEffect, useState} from 'react'
import {Breadcrumb, Button, Flex, Layout, message, Rate, Skeleton, Typography} from "antd";

import mp from './ManufacturerPage.module.scss'
import {Link, useParams} from "react-router-dom";
import logo from '../../image/manufacturer_gkinstrument.png'
import cei from "../../components/for_Catalog/CatalogExplorerItem/CatalogExplorerItem.module.scss";

const ManufacturerItem = ({element}) => {
    return (
        <Link
            to={`/catalog/${element?.path?.slice(1).join('+')}`}
        >
            <Layout className={cei.gridItem}>
                <Layout className={cei.gridItemPictureBlock}>
                    {element.img_url === undefined ? (
                        <Skeleton.Image
                            active
                            style={{height: '100%'}}
                            className={cei.gridItemPicture}
                        ></Skeleton.Image>
                    ) : (
                        <img
                            src={`${process.env.REACT_APP_DOMAIN_CDN}${element?.img_url}`}
                            className={cei.gridItemPicture}
                        />
                    )}
                </Layout>
                <Layout className={cei.gridItemDescription}>
                      <span className={cei.gridItemName}>
                        {element?.alias}
                      </span>
                </Layout>
            </Layout>
        </Link>
    )
}

const ManufacturerPage = ({page=''}) => {
    const {manufacturer_id} = useParams()
    const [manufacturer, setManufacturer] = useState(null)
    const [manufacturerDescription, setManufacturerDescription] = useState([])
    const [manufacturerGoods, setManufacturerGoods] = useState([])
    const [type, setType] = useState(page)
    useEffect(() => {
        setType(page)
    }, [page])


    useEffect(() => {
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/catalog/brand/${manufacturer_id}`, {
            method: 'GET'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error('Что-то пошло не так!')
                } else return res
            })
            .then(res => res.json())
            .then(res => {
                setManufacturer(res)
            })
    }, [manufacturer_id]);

    useEffect(() => {
        console.log(manufacturer)
        if (!!manufacturer?.description) {
            const result = []
            manufacturer?.description?.replace(/[\s\S]*?[\.|!|\?]/gm, (text) => {
                result.push(text)
            })
            setManufacturerDescription(result)
        }
    }, [manufacturer])

    useEffect(() => {
        if (!!!manufacturer) return
        if (type === 'goods') {
            fetch(`${process.env.REACT_APP_DOMAIN_BFF}/catalog/api/nav?brand=${manufacturer?.fullname}`, {
                method: 'GET'
            })
                .then(res => {
                    if (res.status !== 200) {
                        message.error('Что-то пошло не так!')
                    } else return res
                })
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    setManufacturerGoods(res)
                })
        }
    }, [type, manufacturer])

    return (
        <Flex vertical className={mp.container}>
            <Breadcrumb />
            <Layout className={mp.container_header}>
                <Flex className={mp.container_header__container}>
                    <div style={{backgroundImage: `url(${process.env.REACT_APP_DOMAIN_CDN}${manufacturer?.logo})`}} className={mp.container_header__logo}></div>
                    <Flex vertical className={mp.container_header__title}>
                        <Typography.Text className={mp.container_header__title_brandName}>{manufacturer?.fullname}: каталог и витрина произодителя</Typography.Text>
                        <Layout className={mp.container_header_title_rating}>
                            <Rate disabled allowHalf defaultValue={manufacturer?.rating ?? 4.7} style={{ color: "#8D24F8", fontSize:10 }} />
                        </Layout>
                    </Flex>
                </Flex>
            </Layout>
            <Flex className={mp.container_body__description} justify={'space-between'}>
                <Flex className={mp.container_body__description_leftSide}>
                    <Typography.Text className={mp.container_body__description_leftSide__title}>О компании</Typography.Text>
                </Flex>
                <Flex vertical className={mp.container_body__description_rightSide}>
                    <Flex vertical className={mp.container_body__description_rightSide__textBlock}>
                        {manufacturerDescription?.map(text => (<Typography.Text className={mp.container_body__description_rightSide__textItem}>{text}</Typography.Text>))}
                        {/*<Typography.Text className={mp.container_body__description_rightSide__textItem}>Комплексные поставки промышленного инструмента и техоснастки.*/}
                        {/*    Производственный консалтинг.</Typography.Text>*/}
                        {/*<Typography.Text className={mp.container_body__description_rightSide__textItem}>*/}
                        {/*    Более 13 лет мы успешно осуществляем комплексные поставки промышленного инструмента и техоснастки. На сегодняшний день наша компания является одним из лидеров рынка по поставкам инструмента в России.*/}
                        {/*</Typography.Text>*/}
                        {/*<Typography.Text className={mp.container_body__description_rightSide__textItem}>*/}
                        {/*    Наши клиенты — это предприятия, занятые производством в различных отраслях промышленности на территории РФ.*/}
                        {/*</Typography.Text>*/}
                        {/*<Typography.Text className={mp.container_body__description_rightSide__textItem}>*/}
                        {/*    Производитель оказывает услуги по изготовлению и ремонту инструментов.*/}
                        {/*</Typography.Text>*/}
                    </Flex>
                    <Link to={type !== 'goods' ? `/manufacturer/${manufacturer_id}/goods` : `/manufacturer/${manufacturer_id}`} className={mp.container_body__description_rightSide__link_all}>{type !== 'goods' ? "Все товары компании" : "Страница компании"}</Link>
                    <Button disabled ghost className={mp.container_body__description_rightSide__button_write}>Написать представителю</Button>
                </Flex>
            </Flex>
            {type === 'goods' && <Flex gap={'small'}>
                {manufacturerGoods?.map((category, i) => {
                    return (
                        <ManufacturerItem key={i} element={category} />
                    )
                })}
            </Flex>}
            {type !== 'goods' && <>
            <Flex className={mp.container_body__addition}>
                <Flex vertical className={mp.container_body__addition_leftSide}>
                    <Typography.Text className={mp.container_body__addition_title}>Реквизиты</Typography.Text>
                    <table>
                        <tbody>
                        <tr>
                            <td>Название компании</td>
                            <td>{manufacturer?.requisites?.company_name ?? "ООО «ГРУППА КОМПАНИЙ ИНСТРУМЕНТ»"}</td>
                        </tr>
                        <tr>
                            <td>Юридический адрес</td>
                            <td>{manufacturer?.requisites?.legalAddress ?? "Не указано"}</td>
                        </tr>
                        <tr>
                            <td>Фактический адрес</td>
                            <td>{manufacturer?.requisites?.actualAddress ?? "Не указано"}</td>
                        </tr>
                        <tr>
                            <td>ОГРН</td>
                            <td>{manufacturer?.requisites?.ogrn ?? "Не указано"}</td>
                        </tr>
                        <tr>
                            <td>ИНН</td>
                            <td>{manufacturer?.requisites?.inn ?? "Не указано"}</td>
                        </tr>
                        <tr>
                            <td>КПП</td>
                            <td>{manufacturer?.requisites?.kpp ?? "Не указано"}</td>
                        </tr>
                        </tbody>
                    </table>
                </Flex>
                <Layout className={mp.container_body__addition_rightSide}>
                    {!!manufacturer?.location?.latitude && !!manufacturer?.location?.longitude &&
                    <iframe src={`https://yandex.ru/map-widget/v1/?ll=${manufacturer?.location?.longitude}%2C${manufacturer?.location?.latitude}&z=12`} width="100%"
                            height="100%" frameBorder="1" allowFullScreen="true"></iframe>}
                </Layout>
            </Flex>
            <Flex vertical className={mp.container_body__rating}>
                <Typography.Text className={mp.container_body__rating_title}>Отзывы</Typography.Text>
                <Flex className={mp.container_body__rating_list}>
                    {Array(3).fill('').map((item, index) => (
                        <Flex vertical key={index} className={mp.container_body__rating_item}>
                            <Typography.Text className={mp.container_body__rating_item__userName}>Покупатель</Typography.Text>
                            <Typography.Text className={mp.container_body__rating_item__userDate}>22.05.2024</Typography.Text>
                            <Typography.Text className={mp.container_body__rating_item__userText}>Хороший выбор, быстрый ответ.</Typography.Text>
                            <Flex className={mp.container_body__rating_item__userRating}>
                                <Rate disabled allowHalf defaultValue={4.7} style={{ color: "#8D24F8", fontSize:10 }} />
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            </Flex> </>}
        </Flex>
    )
}

export default ManufacturerPage;