import React from 'react'
import ctc from '../common_TngCustomer.module.scss'
import { Layout } from 'antd'

const Version_KIP = () => {
  return (
    <Layout className={ctc.common_versionKIP}>
      <span className="fontSiz_24_500">Проект изделия</span>
      <span className="fontSiz_16_400Blc">Срок загрузки </span>
      <Layout className={ctc.common_loadingDiv}></Layout>
    </Layout>
  )
}

export default Version_KIP
