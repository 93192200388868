import React, { useState } from 'react'
import p from '../../../Web/profile/profile.module.scss'
//! ANTD
import {
  Button,
  Input,
  Modal,
  Layout,
  Select,
  Radio,
  Space,
  Rate,
  Switch,
  Checkbox,
} from 'antd'
import { Link } from 'react-router-dom'
import Star_violet from '../../../image/Star_violet.svg'
import SVGfavorite from '../../../image/Favorites.svg'
import SVGstate from '../../../image/Stat.svg'
const Comparison_Buyer = () => {
  const [choiceComparison, setChoosingComparison] = useState('cat_6')
  const [valueRadioShow, setValueRadioShow] = useState(1)
  const onChangeRadioShow = (e) => {
    console.log('radio checked', e.target.value)
    setValueRadioShow(e.target.value)
  }

  return (
    <Layout className={p.mainDivBuyer}>
      <Layout className={p.common_buyer_Left}>
        <Layout className={p.common_buyer_LeftHedr}>
          <span className="fontSiz_24_500">Сравнение</span>
        </Layout>
        <Layout className={p.common_buyer_LSelectAll}>
          <Radio.Group
            className="radioGrpBtn"
            defaultValue="cat_6"
            buttonStyle="solid"
            onChange={(e) => setChoosingComparison(e.target.value)}
          >
            <Radio.Button className="radBtn" value="cat_6">
              Категория (6)
            </Radio.Button>
            <Radio.Button className="radBtn" value="cat_2">
              Категория (2)
            </Radio.Button>
          </Radio.Group>
        </Layout>
        <Layout className={p.common_buyer_cardDiv}>
          <Layout className={p.common_buyer_cardDiv_comparison}>
            <Layout className={p.common_buyer_showInfo}>
              <Radio.Group onChange={onChangeRadioShow} value={valueRadioShow}>
                <Space direction="vertical">
                  <Radio value={1}>
                    <span>Показать все характеристики</span>
                  </Radio>
                  <Radio value={2}>
                    <span>Показать различия </span>
                  </Radio>
                </Space>
              </Radio.Group>
            </Layout>
            <Layout
              className={p.common_buyer_cardOrderSlid}
              style={{
                paddingBottom: '10px',
                overflowY: 'auto',
                position: 'relative',
                flexWrap: 'nowrap',
              }}
            >
              <Layout className={p.common_buyer_slider}>
                <span className="fontSiz_12_400">{`Артикул 2`}</span>
                <Layout className={p.common_buyer_boxImgStar}>
                  <img
                    src={require('../../../image/freza2.png')}
                    alt="df"
                    style={{ width: '21px', height: '140px' }}
                  />
                  <Layout>
                    <img src={SVGfavorite} alt="React Logo" />
                    <img src={SVGstate} alt="React Logo" />
                  </Layout>
                </Layout>
                <span className="fontSiz_16_400Blc">
                  Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  Туламаш
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  +2 предложения от 20 000 ₽
                </span>
                <Layout className={p.common_buyer_btnPrice}>
                  <span className="fontSiz_16_500">{`10000 ₽`}</span>
                  <Button className={p.common_buyer_addBasket}>
                    В корзину
                  </Button>
                </Layout>
              </Layout>
              <Layout className={p.common_buyer_slider}>
                <span className="fontSiz_12_400">{`Артикул 2`}</span>
                <Layout className={p.common_buyer_boxImgStar}>
                  <img
                    src={require('../../../image/freza2.png')}
                    alt="df"
                    style={{ width: '21px', height: '140px' }}
                  />
                  <Layout>
                    <img src={SVGfavorite} alt="React Logo" />
                    <img src={SVGstate} alt="React Logo" />
                  </Layout>
                </Layout>
                <span className="fontSiz_16_400Blc">
                  Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  Туламаш
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  +2 предложения от 20 000 ₽
                </span>
                <Layout className={p.common_buyer_btnPrice}>
                  <span className="fontSiz_16_500">{`10000 ₽`}</span>
                  <Button className={p.common_buyer_addBasket}>
                    В корзину
                  </Button>
                </Layout>
              </Layout>
              <Layout className={p.common_buyer_slider}>
                <span className="fontSiz_12_400">{`Артикул 2`}</span>
                <Layout className={p.common_buyer_boxImgStar}>
                  <img
                    src={require('../../../image/freza2.png')}
                    alt="df"
                    style={{ width: '21px', height: '140px' }}
                  />
                  <Layout>
                    <img src={SVGfavorite} alt="React Logo" />
                    <img src={SVGstate} alt="React Logo" />
                  </Layout>
                </Layout>
                <span className="fontSiz_16_400Blc">
                  Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  Туламаш
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  +2 предложения от 20 000 ₽
                </span>
                <Layout className={p.common_buyer_btnPrice}>
                  <span className="fontSiz_16_500">{`10000 ₽`}</span>
                  <Button className={p.common_buyer_addBasket}>
                    В корзину
                  </Button>
                </Layout>
              </Layout>
              <Layout className={p.common_buyer_slider}>
                <span className="fontSiz_12_400">{`Артикул 2`}</span>
                <Layout className={p.common_buyer_boxImgStar}>
                  <img
                    src={require('../../../image/freza2.png')}
                    alt="df"
                    style={{ width: '21px', height: '140px' }}
                  />
                  <Layout>
                    <img src={SVGfavorite} alt="React Logo" />
                    <img src={SVGstate} alt="React Logo" />
                  </Layout>
                </Layout>
                <span className="fontSiz_16_400Blc">
                  Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  Туламаш
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  +2 предложения от 20 000 ₽
                </span>
                <Layout className={p.common_buyer_btnPrice}>
                  <span className="fontSiz_16_500">{`10000 ₽`}</span>
                  <Button className={p.common_buyer_addBasket}>
                    В корзину
                  </Button>
                </Layout>
              </Layout>
              <Layout className={p.common_buyer_slider}>
                <span className="fontSiz_12_400">{`Артикул 2`}</span>
                <Layout className={p.common_buyer_boxImgStar}>
                  <img
                    src={require('../../../image/freza2.png')}
                    alt="df"
                    style={{ width: '21px', height: '140px' }}
                  />
                  <Layout>
                    <img src={SVGfavorite} alt="React Logo" />
                    <img src={SVGstate} alt="React Logo" />
                  </Layout>
                </Layout>
                <span className="fontSiz_16_400Blc">
                  Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  Туламаш
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  +2 предложения от 20 000 ₽
                </span>
                <Layout className={p.common_buyer_btnPrice}>
                  <span className="fontSiz_16_500">{`10000 ₽`}</span>
                  <Button className={p.common_buyer_addBasket}>
                    В корзину
                  </Button>
                </Layout>
              </Layout>
              <Layout className={p.common_buyer_slider}>
                <span className="fontSiz_12_400">{`Артикул 2`}</span>
                <Layout className={p.common_buyer_boxImgStar}>
                  <img
                    src={require('../../../image/freza2.png')}
                    alt="df"
                    style={{ width: '21px', height: '140px' }}
                  />
                  <Layout>
                    <img src={SVGfavorite} alt="React Logo" />
                    <img src={SVGstate} alt="React Logo" />
                  </Layout>
                </Layout>
                <span className="fontSiz_16_400Blc">
                  Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  Туламаш
                </span>
                <span className="fontSiz_14_400" style={{ color: '#8D24F8' }}>
                  +2 предложения от 20 000 ₽
                </span>
                <Layout className={p.common_buyer_btnPrice}>
                  <span className="fontSiz_16_500">{`10000 ₽`}</span>
                  <Button className={p.common_buyer_addBasket}>
                    В корзину
                  </Button>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
          <Layout className={p.common_buyer_characteristic}>
            <span className="fontSiz_16_500">Важные характеристики</span>
            <Layout className={p.common_buyer_characteristicChaild}>
              <Layout className={p.common_buyer_characteristic_Checkbox}>
                <Checkbox>
                  <span className="fontSiz_14_400">Наличие товара</span>
                </Checkbox>
                <Checkbox>
                  <span className="fontSiz_14_400">Рейтинг</span>
                </Checkbox>
              </Layout>
              <Layout>
                <span>В наличии</span>
                <Layout className={p.common_buyer_ratStar}>
                  <Layout className={p.common_buyer_rating}>
                    {Array.from({ length: 5 }, (e, i) => (
                      <img
                        key={i}
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    ))}
                  </Layout>
                  <span>5 отзывов</span>
                </Layout>
              </Layout>
              <Layout>
                {' '}
                <span>В наличии</span>
                <Layout className={p.common_buyer_ratStar}>
                  <Layout className={p.common_buyer_rating}>
                    {Array.from({ length: 5 }, (e, i) => (
                      <img
                        key={i}
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    ))}
                  </Layout>
                  <span>5 отзывов</span>
                </Layout>
              </Layout>
              <Layout>
                {' '}
                <span>В наличии</span>
                <Layout className={p.common_buyer_ratStar}>
                  <Layout className={p.common_buyer_rating}>
                    {Array.from({ length: 5 }, (e, i) => (
                      <img
                        key={i}
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    ))}
                  </Layout>
                  <span>5 отзывов</span>
                </Layout>
              </Layout>
              <Layout>
                {' '}
                <span>В наличии</span>
                <Layout className={p.common_buyer_ratStar}>
                  <Layout className={p.common_buyer_rating}>
                    {Array.from({ length: 5 }, (e, i) => (
                      <img
                        key={i}
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    ))}
                  </Layout>
                  <span>5 отзывов</span>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
          <Layout className={p.common_buyer_characteristic}>
            <span className="fontSiz_16_500">Наличие и способы получения</span>
            <Layout className={p.common_buyer_characteristicChaild}>
              <Layout className={p.common_buyer_characteristic_Checkbox}>
                <Checkbox>
                  <span className="fontSiz_14_400">Наличие товара</span>
                </Checkbox>
                <Checkbox>
                  <span className="fontSiz_14_400">Доставка</span>
                </Checkbox>
                <Checkbox>
                  <span className="fontSiz_14_400">Самовывоз</span>
                </Checkbox>
              </Layout>
              <Layout>
                <span>В наличии</span>
                <span>Есть</span>
                <span>Есть</span>
              </Layout>
              <Layout>
                <span>В наличии</span>
                <span>Есть</span>
                <span>Есть</span>
              </Layout>
              <Layout>
                <span>В наличии</span>
                <span>Есть</span>
                <span>Есть</span>
              </Layout>
              <Layout>
                <span>В наличии</span>
                <span>Есть</span>
                <span>Есть</span>
              </Layout>
            </Layout>
          </Layout>
          <Layout className={p.common_buyer_characteristic}>
            <span className="fontSiz_16_500">Оценки пользователей</span>
            <Layout className={p.common_buyer_characteristicChaild}>
              <Layout className={p.common_buyer_characteristic_Checkbox}>
                <Checkbox>
                  <span className="fontSiz_14_400">Рейтинг</span>
                </Checkbox>
                <Checkbox>
                  <span className="fontSiz_14_400">Цена/качество</span>
                </Checkbox>
              </Layout>
              <Layout>
                <Layout className={p.common_buyer_ratStar}>
                  <Layout className={p.common_buyer_rating}>
                    {Array.from({ length: 5 }, (e, i) => (
                      <img
                        key={i}
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    ))}
                  </Layout>
                  <span>5 отзывов</span>
                </Layout>
                <span>-</span>
              </Layout>
              <Layout>
                <Layout className={p.common_buyer_ratStar}>
                  <Layout className={p.common_buyer_rating}>
                    {Array.from({ length: 5 }, (e, i) => (
                      <img
                        key={i}
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    ))}
                  </Layout>
                  <span>5 отзывов</span>
                </Layout>
                <span>-</span>
              </Layout>
              <Layout>
                <Layout className={p.common_buyer_ratStar}>
                  <Layout className={p.common_buyer_rating}>
                    {Array.from({ length: 5 }, (e, i) => (
                      <img
                        key={i}
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    ))}
                  </Layout>
                  <span>5 отзывов</span>
                </Layout>
                <span>-</span>
              </Layout>
              <Layout>
                {' '}
                <Layout className={p.common_buyer_ratStar}>
                  <Layout className={p.common_buyer_rating}>
                    {Array.from({ length: 5 }, (e, i) => (
                      <img
                        key={i}
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    ))}
                  </Layout>
                  <span>5 отзывов</span>
                </Layout>
                <span>-</span>
              </Layout>
            </Layout>
          </Layout>
          <Layout className={p.common_buyer_characteristic}>
            <span className="fontSiz_16_500">Общие характеристики</span>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Comparison_Buyer
