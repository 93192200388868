import React, {useEffect, useState} from 'react'
import {Flex, message, Tree} from "antd";
import ga from '../../../Web/general_admin.module.scss'
import ca from './CategoriesAdmin.module.scss'
import Search from "antd/lib/input/Search";
import {DownOutlined} from "@ant-design/icons";

const updateTreeData = (list, key, children) => {
    return list.map((node) => {
        if (node.key === key) {
            return {
                ...node,
                children
            }
        }
        else if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children)
            }
        }
        else return node
    })
}

const CategoriesAdmin = () => {
    const [treeData, setTreeData] = useState()

    useEffect(() => {
        // fetch(`${process.env.REACT_APP_DOMAIN_BFF}/catalog/api/nav`, {
        //     method: 'GET'
        // })
        //     .then(res => {
        //         if (res.status !== 200) {
        //             message.error('Ошибка!')
        //         } else return res
        //     })
        //     .then(res => res.json())
        //     .then(res => {
        //         console.log(res)
        //         setTreeData([{
        //             key: res.name,
        //             title: res.alias,
        //             full_path: res.full_path,
        //             children: res.inner.map((el) => ({
        //                 title: el.alias,
        //                 key: el.name,
        //                 full_path: el.full_path,
        //             }))
        //         }])
        //     })
        //
    }, []);

    const onLoadData = ({key, children, title}) =>
        new Promise((resolve) => {
            if (children) {
                resolve();
                return
            }
            // fetch(`${process.env.REACT_APP_DOMAIN_BFF}/catalog/api/${full_path?.join('/')}`, {
            //     method: 'GET'
            // })
            //     .then(res => {
            //         if (res.status !== 200) {
            //             message.error('Ошибка!')
            //         } else return res
            //     })
            //     .then(res => res.json())
            //     .then(res => {
            //         console.log(!!res.inner[0].id, res)
            //         setTreeData((origin) =>
            //             updateTreeData(
            //                 origin,
            //                 key,
            //                 res.inner?.map(row => ({title: row.alias, key: row.name, full_path: row.full_path, isLeaf: false}))
            //             )
            //         )
            //         resolve();
            //     })
            let res = children.find(f => f.key === key)
            console.log(res)

            // setTreeData((origin) =>
            //     updateTreeData(
            //         origin,
            //         key,
            //         res.children?.map(row => ({title: row.alias, key: row.name, full_path: row.full_path, isLeaf: false}))
            //     )
            // )
            resolve();
        })

    const [categories, setCategories] = useState(null)
    useEffect(() => {
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/catalog/export/1`, {
            method: 'GET'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error('Ошибка!', res.status)
                } else return res
            })
            .then(res => res.json())
            .then(res => {
                console.log(res)

                function recursive(data) {
                    if (Array.isArray(data)) {
                        return data.map((element,i) => {
                            if (element.children) {
                                return {key: element.name, title: element.name, children: recursive(element.children)}
                            } else return {key: element.name, title: element.name, children: null, isLeaf: true}
                        })
                    } else if (data.children) {
                        return {key: data.name, title: data.name, children: recursive(data.children)}
                    } else {
                        return data // вроде не вызывается
                    }
                }


                // setCategories(res)
                // setTreeData([{
                //     key: res.name,
                //     title: res.name,
                //     children: res.children.map((el) => ({
                //         title: el.name,
                //         key: el.name,
                //     }))
                // }])

                const recursiveData = [recursive(res)]
                console.log(recursiveData)

                setTreeData(recursiveData)
            })
    }, []);


    return (
        <Flex className={ga.container}>
            <Search className={ga.search} />
            <Flex className={ca.treeBlock}>
                <Tree
                    className={ca.Tree}
                    switcherIcon={<DownOutlined />}
                    loadData={onLoadData}
                    treeData={treeData}
                    defaultExpandAll={true}
                    defaultExpandedKeys={['Главная']}
                />
                {/*<Collapse*/}
                {/*    items={items?.map((el,i) => ({*/}
                {/*        key: el.key,*/}
                {/*        label: el.alias,*/}
                {/*        children: <Collapse*/}
                {/*            items={el.children?.map((e,j) => ({*/}
                {/*                key: e.key,*/}
                {/*                label: e.alias,*/}
                {/*                children: []*/}
                {/*            }))}*/}
                {/*        />,*/}
                {/*        extra: <span></span>*/}
                {/*    }))}*/}
                {/*    defaultActiveKey={['nav']}*/}
                {/*    onChange={onChange}*/}
                {/*/>*/}
            </Flex>
        </Flex>
    )
}

export default CategoriesAdmin