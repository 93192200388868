import {ProviderViewer} from "./ContextViewer";
import {Collection3D} from "./Сollection3D";

function Component3D({url}) {

 return (
    <ProviderViewer>
        <Collection3D url={url}/>
    </ProviderViewer>
 )

}
export default Component3D;