import React from 'react'
import f from './Footer.module.scss'
import {Link} from "react-router-dom";
import logo from '../../image/logo_full.svg'

const Footer = () => {
  return (
      <>
          <div className={f.mainFooter}>
              <div className={f.footerGrid}>
                  <img height={40} src={logo} alt="Stuff"/>
                  <div className={f.helpList}>
                      <div className={f.categoryBlock}>
                          <span>Покупателям</span>
                          <Link to={'/'}>Как заказать товар</Link>
                          <Link to={'/'}>Помощь</Link>
                      </div>
                      <div className={f.categoryBlock}>
                          <span>Партнерам</span>
                          <Link to={'/'}>Инструкция для поставщиков</Link>
                          <Link to={'/'}>Условия использования платформы</Link>
                          <Link to={'/'}>Стать поставщиком</Link>
                      </div>
                      <div className={f.categoryBlock}>
                          <span>Компания</span>
                          <Link to={'/'}>О проекте</Link>
                          <Link to={'/'}>TKMP.Istok</Link>
                          <Link to={'/'}>Каталог</Link>
                          <Link to={'/'}>Контакты и реквизиты</Link>
                          <Link to={'/'}>Правовая информация</Link>
                      </div>
                  </div>
                  <div className={f.contactsList}>
                      <div>
                          <a href={'tel:+74954658680'}>+7 (495) 465-86-80</a>
                          <a href={'mailto:info@istokmw.ru'}>info@istokmw.ru</a>
                      </div>
                  </div>
              </div>
              <span className={f.lineDescription}>© 2024 TKMP.Istok  — маркетплейс производителей инструмента и оснастки. Все права защищены.</span>
          </div>
      </>
  )
}

export default Footer;
