import React from 'react'
import {Layout} from "antd";

const Distributor_CreateProductManually = () => {
    return (
        <Layout>
            
        </Layout>
    )
}

export default Distributor_CreateProductManually