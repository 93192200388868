import React from 'react'
import {Button, Checkbox, Drawer, Flex, Form, Input, message, Typography} from "antd";
import af from './AuthForms.module.scss'
import {useForm} from "antd/lib/form/Form";
import {Link} from 'react-router-dom'
import theme from '../../Utils/Themes.module.scss'

const DrawerLoginForm = ({callback_close, callback_open}) => {
    const [form] = useForm()

    const callback_submit = (values) => {
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                usernameOrEmail: values.email,
                password: values.password
            })
        })
            .then(res => {
                console.log(res)
                if (res.status === 401) {
                } else if (res.status !== 200) {
                    message.error('Авторизация неуспешная')
                } else return res
            })
            .then(res => res.json())
            .then(res => {
                console.log('=>', res)
                message.success('Авторизован')
                localStorage.setItem('accessToken', res.accessToken)
                localStorage.setItem('userName', values.email)
                localStorage.setItem('role', res.role)
                callback_close()
                form.setFieldsValue({
                    email: '',
                    password: '',
                })
            })
            .catch(e => {
                message.error(`Ошибка: ${e}`)
            })
        // event.preventDefault()

        // try {
        //     const response = await fetch(
        //         `${process.env.REACT_APP_DOMAIN_BFF}/auth/login`,
        //         {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify({
        //                 usernameOrEmail: user,
        //                 password: pass,
        //             }),
        //         }
        //     )
        //
        //     if (response.ok) {
        //         const result = await response.json()
        //         console.log(result)
        //         localStorage.setItem('accessToken', result.accessToken)
        //         localStorage.setItem('userName', user)
        //         localStorage.setItem('role', result.role)
        //         setUserName(result.name)
        //         setRole(result.role)
        //         setToggleClass(false)
        //     }
        //     if (response.status === 401) {
        //         setUser()
        //         setPass()
        //         console.log('error', response.status)
        //     }
        // } catch (error) {
        //     console.log(error)
        //     error === '' ? alert('Ошибка:', error) : alert('ERR_CONNECTION_TIMED_OUT')
        // }
    }

    return (
        <Drawer
            open={callback_open}
            onClose={callback_close}
            className={af.drawer}
            width={450}
        >
            <Form
                form={form}
                className={af.drawer_Form}
                layout={'vertical'}
                variant={'outlined'}
                style={{
                    maxWidth: '100%',
                }}
                onFinish={(values) => callback_submit(values)}
                clearOnDestroy
            >
                <Flex className={`${theme.GeneralContainer__Theme} ${af.drawer_formBlock}`} vertical justify={'center'} align={'center'} style={{height: '100%'}}>
                    <Flex align={'flex-start'} style={{width: '100%'}}>
                        <Typography.Text className={af.drawer_title}>Вход</Typography.Text>
                    </Flex>
                    <Form.Item style={{width: '100%'}}
                               className={af.drawer_formItem}
                       layout="vertical"
                       label="Имя пользователя или E-mail"
                       name="email"
                       rules={[
                           {required: true},
                       ]}
                    >
                        <Input placeholder={'example@example.example'} />
                    </Form.Item>
                    <Form.Item style={{width: '100%', margin: 0}}
                               className={af.formItem}
                       layout="vertical"
                       label="Пароль"
                       name="password"
                       rules={[
                           {required: true },
                       ]}
                    >
                        <Input placeholder={'Тсс...'} />
                    </Form.Item>
                    <Flex align={'baseline'} justify={'space-between'} style={{width: '100%'}}>
                        <Flex align={'baseline'} gap={'small'} justify={'flex-start'}>
                            <Form.Item
                                style={{margin: 0}}
                                className={af.formItem}
                                name="isRemember"
                                rules={[
                                    {required: false},
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>
                            <Typography.Text>Запомнить меня</Typography.Text>
                        </Flex>
                        <Link style={{color: '#8D24F8'}}>Забыли пароль?</Link>
                    </Flex>
                    <Button htmlType={'submit'} style={{width: '100%'}}>Войти</Button>
                    <Button ghost style={{width: '100%'}}>Стать участником</Button>
                </Flex>
            </Form>
        </Drawer>
    )
}

export default DrawerLoginForm