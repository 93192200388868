import React from 'react'
import p from '../../../../../Web/profile/profile.module.scss'
import { Button, Layout } from 'antd'
const Kanban_Distributor = ({ cardTransaction }) => {
  return (
    <Layout className={p.common_wrappMainChaild}>
      <Layout className={p.common_wrappChaild}>
        <Layout
          className={`${p.common_wrappChaild_tTitl} ${p.common_wrappChaild_bacgr1}`}
        >
          <span
            className={`${p.common_wrappChaild_tTitl_span} fontSiz_16_400Blc`}
          >
            Первичный контакт
            <span className="fontSiz_12_400">1 сделка </span>
          </span>
        </Layout>

        {cardTransaction.map((el, i) => {
          console.log(el)
          if (el.type === 'conversation') {
            return (
              <Layout className={p.common_wrappChaild_card}>
                <Layout className={p.common_card_titl}>
                  <span className="fontSiz_16_600">EXPO ZONE</span>
                  <span className="fontSiz_14_400">{el.data}</span>
                </Layout>
                <span className="fontSiz_14_400">{el.user}</span>
                <ul className={p.common_card_Ul}>
                  {el.product.map((elem, i) => {
                    return <li>{elem}</li>
                  })}
                </ul>
                <Layout className={p.common_wrappChaild_btnBuy}>
                  <Button className={p.common_wrappChaild_card_btn}>
                    Покупка
                  </Button>
                </Layout>
              </Layout>
            )
          }
        })}
        {/* <Layout className={`${p.common_wrappChaild_tTitl} ${p.common_wrappChaild_bacgr}`}>
<span className={`${p.common_wrappChaild_tTitl_span} fontSiz_16_400Blc`}>
  Первичный контакт
  <span className="fontSiz_12_400">1 сделка </span>
</span>
</Layout> */}
      </Layout>
      <Layout className={p.common_wrappChaild}>
        <Layout
          className={`${p.common_wrappChaild_tTitl} ${p.common_wrappChaild_bacgr2}`}
        >
          <span
            className={`${p.common_wrappChaild_tTitl_span} fontSiz_16_400Blc`}
          >
            Переговоры
            <span className="fontSiz_12_400">1 сделка </span>
          </span>
        </Layout>
        {cardTransaction.map((el, i) => {
          console.log(el)
          if (el.type === 'conversation') {
            return (
              <Layout className={p.common_wrappChaild_card}>
                <Layout className={p.common_card_titl}>
                  <span className="fontSiz_16_600">EXPO ZONE</span>
                  <span className="fontSiz_14_400">{el.data}</span>
                </Layout>
                <span className="fontSiz_14_400">{el.user}</span>
                <ul className={p.common_card_Ul}>
                  {el.product.map((elem, i) => {
                    return <li>{elem}</li>
                  })}
                </ul>
                <Layout className={p.common_wrappChaild_btnBuy}>
                  <Button className={p.common_wrappChaild_card_btn}>
                    Покупка
                  </Button>
                </Layout>
              </Layout>
            )
          }
        })}
      </Layout>
      <Layout className={p.common_wrappChaild}>
        <Layout
          className={`${p.common_wrappChaild_tTitl} ${p.common_wrappChaild_bacgr3}`}
        >
          <span
            className={`${p.common_wrappChaild_tTitl_span} fontSiz_16_400Blc`}
          >
            Согласование заказа
            <span className="fontSiz_12_400">1 сделка </span>
          </span>
        </Layout>
        {cardTransaction.map((el, i) => {
          console.log(el)
          if (el.type === 'agreement') {
            return (
              <Layout className={p.common_wrappChaild_card}>
                <Layout className={p.common_card_titl}>
                  <span className="fontSiz_16_600">EXPO ZONE</span>
                  <span className="fontSiz_14_400">{el.data}</span>
                </Layout>
                <span className="fontSiz_14_400">{el.user}</span>
                <ul className={p.common_card_Ul}>
                  {el.product.map((elem, i) => {
                    return <li>{elem}</li>
                  })}
                </ul>
                <Layout className={p.common_wrappChaild_btnBuy}>
                  <Button className={p.common_wrappChaild_card_btn}>
                    Покупка
                  </Button>
                </Layout>
              </Layout>
            )
          }
        })}
      </Layout>
    </Layout>
  )
}

export default Kanban_Distributor
