import React, {useState} from 'react'

import ca from '../common__Admin.module.scss'
import gca from '../../../Web/GeneralComponentAdmin/GeneralComponentAdmin.module.scss'

import {ReactComponent as EdditSVG} from '../../../image/Edit_light.svg'

import {useNavigate} from 'react-router-dom'
import {Button, Input, Layout, Table} from 'antd'

const RequestsProduction_admin = () => {
  const navigate = useNavigate()
  const { Search } = Input
  const [dataRequestsProduction, setDataRequestsProduction] = useState([
    {
      key: '1',
      issued: 'ГК Инструмент',
      registrationDate: '24.05.2025',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
      tz: 'Есть',
    },
  ])
  const [newDataRequestsProduction, setNewDataRequestsProduction] = useState({
    key: dataRequestsProduction.length + 1,
    issued: 'ГК Инструмент',
    registrationDate: '24.05.2025',
    category: 'Текст',
    name: 'Сверло',
    price: '1200',
    description: 'Есть',
    plan: 'Есть',
    tz: 'Есть',
  })
  const columnsRequestsProduction = [
    {
      title: '',
      dataIndex: 'red',
      render: (text) => (
        <Button type="text" onClick={() => navigate('#')}>
          <EdditSVG />
        </Button>
      ),
      width: '5%',
    },
    {
      title: 'Оформил',
      dataIndex: 'issued',
    },
    {
      title: 'Дата оформления',
      dataIndex: 'registrationDate',
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      render: (text) => (
        <span style={{ color: 'rgba(115, 209, 61, 1)' }}>text</span>
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'Чертеж',
      dataIndex: 'plan',
      render: (text) => (
        <span style={{ color: 'rgba(115, 209, 61, 1)' }}>text</span>
      ),
    },
    {
      title: 'ТЗ',
      dataIndex: 'plan',
      render: (text) => (
        <span style={{ color: 'rgba(115, 209, 61, 1)' }}>text</span>
      ),
    },
  ]
  return (
    <Layout className={ca.common_MainDiv}>
      <Layout className={ca.common_InputGrup}>
        <Search
          suffix
          placeholder="input search text"
          style={{
            width: 200,
          }}
        />
      </Layout>
      <Layout className={ca.common_scrollWrapper}>
        <Table
          columns={columnsRequestsProduction}
          dataSource={dataRequestsProduction}
          className={gca.tableGeneral}
          pagination={{
            pageSize: 40,
          }}
          scroll={{ x: 'max-content' }}
        />
      </Layout>
    </Layout>
  )
}

export default RequestsProduction_admin
