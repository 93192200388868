import React from 'react'
import {Flex, Table} from "antd";
import ga from '../../../Web/general_admin.module.scss'
import Search from "antd/lib/input/Search";

const AddCategoriesAdmin = () => {



    return (
        <Flex className={ga.container}>
            <Search className={ga.search} />
            <Flex>
                <Table
                    className={ga.Table}

                />
            </Flex>
        </Flex>
    )
}

export default AddCategoriesAdmin