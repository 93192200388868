import React, { useState } from 'react'
import p from '../../../Web/profile/profile.module.scss'
//! ANTD
import {
  Button,
  Input,
  Modal,
  Layout,
  Select,
  Radio,
  Rate,
  Switch,
  Checkbox,
} from 'antd'
import { Link } from 'react-router-dom'

const MyOrders_Buyer = () => {
  const [checkbox, setCheckbox] = useState(false)
  const [checked, setChecked] = useState(false)
  const [isModalOrder, setIsModalOrder] = useState(false)
  const [testMyOrder, setTestMyOrder] = useState([
    {
      manufacturer: 'Туламаш',
      order: '480328845',
      article: '0000666666',
      name: 'manufacturer',
      quantity: 100,
      registrationDate: '21 июня 2024',
      status: 'Оформлен',
    },
    {
      manufacturer: 'Туламаш',
      order: '13',
      article: '00001518353',
      name: 'manufacturer',
      quantity: 100,
      registrationDate: '21 июня 2024',
      status: 'Получен счет/предложение',
    },
    {
      manufacturer: 'Туламаш',
      order: '14',
      article: '0000151000',
      name: 'manufacturer',
      quantity: 100,
      registrationDate: '21 июня 2024',
      status: 'Завершен',
    },
    {
      manufacturer: 'Туламаш',
      order: '300',
      article: '00001511111',
      name: 'manufacturer',
      quantity: 100,
      registrationDate: '21 июня 2024',
      status: 'Отменен',
    },
  ])
  const [choiceList, setChoosing] = useState('2024')
  const optionsSelect = [
    {
      value: 'key1',
      label: 'Оформлен',
    },
    {
      value: 'key2',
      label: 'Получен счет/предложение',
    },
    {
      value: 'key3',
      label: 'Завершен',
    },
    {
      value: 'key4',
      label: 'Отменен',
    },
  ]
  const showModalOrder = () => {
    setIsModalOrder(true)
  }
  const handleCancelOrder = () => {
    setIsModalOrder(false)
  }
  const onChangeSwitch = (checked) => {
    console.log(`switch to ${checked}`)
  }
  const onChangeChecked = (e) => {
    console.log('checked = ', e.target.checked)
    setChecked(e.target.checked)
    setCheckbox(!checkbox)
  }
  return (
    <Layout className={p.mainDivBuyer}>
      <Layout className={p.common_buyer_Left}>
        <Layout className={p.common_buyer_LeftHedr}>
          <span className="fontSiz_24_500">Мои заказы</span>
        </Layout>
        <Layout className={p.common_buyer_LSelectAll}>
          <Select
            defaultValue="Статус заказа"
            style={{
              width: 260,
            }}
            // onChange={handleChange}
            options={optionsSelect}
          />
        </Layout>
        <Layout className={p.common_buyer_LSelectAll}>
          <Radio.Group
            className="radioGrpBtn"
            defaultValue="2024"
            buttonStyle="solid"
            onChange={(e) => setChoosing(e.target.value)}
          >
            <Radio.Button className="radBtn" value="2024">
              2024
            </Radio.Button>
            <Radio.Button className="radBtn" value="2023">
              2023
            </Radio.Button>
          </Radio.Group>
        </Layout>
        <Layout className={p.common_buyer_cardDiv}>
          <Layout className={p.common_buyer_cardDivChaild}>
            {testMyOrder?.map((el, i) => {
              console.log(el)
              return (
                <Layout className={p.common_buyer_cardOrder}>
                  <Layout className={p.common_buyer_cardTop}>
                    <Layout style={{ rowGap: '8px' }}>
                      <span className="fontSiz_16_500">{`Заказ № ${el?.order}`}</span>
                      <span
                        className="fontSiz_14_400"
                        style={{ color: 'rgba(141, 36, 248, 1)' }}
                      >
                        {el.manufacturer}
                      </span>
                    </Layout>
                    {el.status === 'Оформлен' && <Button>Отменить</Button>}
                    {el.status === 'Получен счет/предложение' && (
                      <Button>Ответить</Button>
                    )}
                    {el.status === 'Завершен' && <Button>Повторить</Button>}
                  </Layout>
                  <Layout className={p.common_buyer_info}>
                    <img
                      src={require('../../../image/freza.png')}
                      alt=""
                      style={{ background: 'rgba(240, 243, 245, 1)' }}
                    />
                    <Layout className={p.common_info_chaild}>
                      <span className="fontSiz_12_400">
                        {`Артикул ${el.article}`}
                      </span>
                      <span className="fontSiz_16_400Blc">Торцевая фреза</span>
                      <span className="fontSiz_16_400Blc">100 шт.</span>
                      <span
                        className={`fontSiz_12_400`}
                        style={{
                          color: 'rgba(141, 36, 248, 1)',
                          // paddingBottom: '16px',
                        }}
                      >
                        {el.manufacturer}
                      </span>
                    </Layout>
                    <Layout
                      className={p.common_info_chaild}
                      style={{ paddingLeft: '35px' }}
                    >
                      <span className="fontSiz_16_400Blc">
                        Дата оформления:
                      </span>
                      <span className="fontSiz_16_400Blc">Статус:</span>
                      {el.status === 'Завершен' && (
                        <Layout className={p.common_info_Rate}>
                          <span className="fontSiz_16_500">Оцените товар</span>
                          <Rate
                            onClick={showModalOrder}
                            color={'rgba(141, 36, 248, 1)'}
                            className={p.common_Rate}
                          />
                        </Layout>
                      )}
                    </Layout>
                    <Layout className={p.common_info_chaild}>
                      <span className="fontSiz_16_400Blc">
                        {el?.registrationDate}
                      </span>
                      <span
                        className={`fontSiz_16_400Blc ${
                          el.status === 'Оформлен'
                            ? p.common_info_decoration
                            : el.status === 'Завершен'
                            ? p.common_info_completed
                            : el.status === 'Отменен'
                            ? p.common_info_cancelled
                            : p.common_info_def
                        }`}
                      >
                        {el.status}
                      </span>
                    </Layout>
                  </Layout>
                </Layout>
              )
            })}
          </Layout>
        </Layout>
      </Layout>
      <Modal
        width={'40%'}
        style={{ padding: '20px' }}
        className="modalDeled"
        // title="Удаление аккаунта"
        open={isModalOrder}
        // onOk={handleOk}
        onCancel={handleCancelOrder}
        footer={null}
      >
        <Layout className={p.common_modal_form}>
          {/* <Layout className={p.common_modal_column}> */}
          <Layout
            className="fontSiz_32_500"
            style={{ textAlign: 'center', width: '100%' }}
          >
            Как вам товар?
          </Layout>
          <Layout className={p.common_modal_changeRate}>
            <Rate
              color={'rgba(141, 36, 248, 1)'}
              className={p.common_Rate2}
              style={{ fontSize: '30px' }}
            />
            <Layout className={p.common_chaild_divInput}>
              <span className="fontSiz_16_500">Достоинства</span>
              <Input
                className={p.common_chaild_Input}
                placeholder="Достоинства"
                type="pass"
                name="username"
                required
              />
            </Layout>
            <Layout className={p.common_chaild_divInput}>
              <span className="fontSiz_16_500">Недостатки</span>
              <Input
                className={p.common_chaild_Input}
                placeholder="Недостатки"
                type="pass"
                name="username"
                required
              />
            </Layout>
            <Layout className={p.common_chaild_divInput}>
              <span className="fontSiz_16_500">Коммуникация с поставщиком</span>
              <Input
                className={p.common_chaild_Input}
                placeholder="Коммуникация с поставщиком"
                type="pass"
                name="username"
                required
              />
            </Layout>
            <Layout className={p.common_chaild_divSwitch}>
              <Switch defaultChecked onChange={onChangeSwitch} />
              <span>Анонимный отзыв</span>
            </Layout>
            <Layout>
              <Checkbox checked={checked} onChange={onChangeChecked}>
                <p className="fontSiz_14_400">
                  Оставляя отзыв, я принимаю 
                  <Link style={{ color: 'rgb(141, 36, 248)' }}>
                    «Условия использования Платформы»
                  </Link>
                   
                </p>
              </Checkbox>
            </Layout>
            <Button
              type="primary"
              className={p.common_modal_column_btnGood}
              //   style={{ width: '100%' }}
              onClick={handleCancelOrder}
            >
              Отправить
            </Button>
          </Layout>
          <Layout style={{ width: '100%' }}></Layout>
          {/* </Layout> */}
        </Layout>
      </Modal>
    </Layout>
  )
}

export default MyOrders_Buyer
