import React from 'react'
import ca from '../../common__Admin.module.scss'

import gca from '../../../../Web/GeneralComponentAdmin/GeneralComponentAdmin.module.scss'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {Asterisk} from 'lucide-react'

import {useNavigate} from 'react-router'
import {Button, Checkbox, Input, Layout, Select} from 'antd'

const Delete_requestsAdmin = () => {
  const navigate = useNavigate()
  return (
    <Layout style={{ rowGap: '24px' }}>
      <Layout className={ca.common_Div}>
        <Layout className={ca.common_Revers}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            className={ca.common_ReverseNavigation}
            onClick={() => navigate(-1)}
          >
            Назад
          </Button>
          <span className="fontSiz_24_500">Запрос на удаление аккаунта</span>
        </Layout>
        <Layout className={ca.common_MainContent}>
          <Layout className={ca.common_Blocks}>
            <Layout className={ca.first_blocks}>
              <Layout>
                <span>Номер запроса</span>
                <span>1</span>
              </Layout>
              <Layout>
                <span>Дата поступления запроса</span>
                <span>12.01.2024</span>
              </Layout>
              <Layout>
                <span>Статус</span>
                <span>
                  <span className={ca.firstBlocks_active}>Активная</span>
                </span>
              </Layout>
              <Layout>
                <span>Срок </span>
                <span>до 15.01.2024</span>
              </Layout>
            </Layout>
            <Layout className={ca.second_bloks}>
              <span className="fontSiz_20_500">Чек-лист</span>
              <Layout className={ca.secondBlock_chaild}>
                <Checkbox>
                  Связаться с аккаунтом, оформившим запрос. Подтвердить запрос
                  на удаление аккаунта
                </Checkbox>
                <Checkbox>
                  По возможности узнать причину удаления аккаунта
                </Checkbox>
                <Layout>
                  <label htmlFor="" className={gca.modalContent__labFlex}>
                    <span className="fontSiz_16_400Blc">Причина удаления</span>
                  </label>
                  <Input
                    className={gca.modalContent__inptStaff}
                    placeholder="Причина удаления"
                    // value={newCompanyAd.name}
                    required
                    // onChange={(e) =>
                    //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                    // }
                  />
                </Layout>
                <Layout className={ca.secondBlock_chaild_btn}>
                  <Button>Удалить </Button>
                  <Button>Отменить</Button>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
          <Layout className={gca.modalContentGeneral} style={{ width: '100%' }}>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Роль</span>
              </label>
              <Select
                placeholder="Роль"
                className={gca.modalContent__selectStaff}
                defaultValue="Роль"
                // onChange={(val) =>
                //   setNewCompanyAd({ ...newCompanyAd, role: val })
                // }
                options={[
                  {
                    value: 'Производитель',
                    label: 'Производитель',
                  },
                  {
                    value: 'Покупатель',
                    label: 'Покупатель',
                  },
                  {
                    value: 'Торговая компания',
                    label: 'Торговая компания',
                  },
                ]}
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Название</span>
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="Название"
                // value={newCompanyAd.name}
                required
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Сайт</span>
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="Сайт"
                // value={newCompanyAd.website}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, website: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">ИНН</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="ИНН"
                // value={newCompanyAd.inn}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, inn: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Страна</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="Страна"
                // value={newCompanyAd.country}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, country: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">E-mail</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="E-mail"
                // value={newCompanyAd.country}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, country: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">ФИО</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="ФИО"
                // value={newCompanyAd.country}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, country: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Телефон</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="Телефон"
                // value={newCompanyAd.country}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, country: e.target.value })
                // }
              />
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Delete_requestsAdmin
