import React, { useState } from 'react'
import p from './profile.module.scss'
//! ANTD
import { Button, Menu, ConfigProvider, Layout } from 'antd'
import Account_Distributor from '../../components/for_Distributor_Prof/Account_Distributor/Account_Distributor'
import CompanyPage_Distributor from '../../components/for_Distributor_Prof/CompanyPage_Distributor/CompanyPage_Distributor'
import Staff_Distributor from '../../components/for_Distributor_Prof/Staff_Distributor/Staff_Distributor'
import SettingPrices_Distributor from '../../components/for_Distributor_Prof/Products_Distributor/SettingPrices_Distributor/SettingPrices_Distributor'
import ListProducts_Distributor from '../../components/for_Distributor_Prof/Products_Distributor/ListProducts_Distributor/ListProducts_Distributor'
import Transactions_Distributor from '../../components/for_Distributor_Prof/Sale_Distributor/Transactions_Distributor/Transactions_Distributor'
import Mail_distributor from '../../components/for_Distributor_Prof/Sale_Distributor/Mail_distributor/Mail_distributor'
import SalesAnalytics_Distributor from '../../components/for_Distributor_Prof/Sale_Distributor/SalesAnalytics_Distributor/SalesAnalytics_Distributor'
import ListClient_Distributor from '../../components/for_Distributor_Prof/Sale_Distributor/ListClient_distributor/ListClient_Distributor'
import Distributor_CreateProductManually
  from "../../components/for_Distributor_Prof/Products_Distributor/Distributor_CreateProductManually/Distributor_CreateProductManually";
const Distributor_Profile = () => {
  const [current, setCurrent] = useState('account_Distributor')
  const [fetchDistributorAccount, setFetcDistributorAccount] = useState()
  const onClick = (e) => {
    console.log('click ', e.key)
    setCurrent(e.key)
  }
  const items = [
    {
      label: 'Аккаунт',
      key: 'account_Distributor',
      // icon: <MailOutlined />,
    },
    {
      label: 'Страница компании',
      key: 'companyPage_Distributor',
    },
    {
      label: 'Товары',
      key: 'Products',
      children: [
        {
          label: 'Установка цен на товары',
          key: 'SettingPrices_Distributor',
        },
        {
          label: 'Список товаров ',
          key: 'ListProducts_Distributor',
        },
        {
          label: 'История добавления',
          key: 'HistoryAdding',
          //   disabled: true,
        },
        {
          label: 'Создание товара вручную',
          key: 'CreateProductManually'
        }
      ],
    },
    {
      label: 'Продажа',
      key: 'Sale',
      children: [
        {
          label: 'Сделки',
          key: 'Transactions_Distributor',
        },
        {
          label: 'Отзывы',
          key: 'Reviews',
          disabled: true,
        },
        {
          label: 'Почта',
          key: 'Mail_distributor',
        },
        {
          label: 'Списки клиентов',
          key: 'ListClient_Distributor',
        },
        {
          label: 'Аналитика',
          key: 'SalesAnalytics_Distributor',
        },
      ],
      //   disabled: true,
    },
    {
      label: 'Сотрудники',
      key: 'Staff_Distributor',
    },
    {
      label: 'Поддержка',
      key: 'Support',
      disabled: true,
    },
    {
      label: 'Уведомления',
      key: 'Notifications',
      disabled: true,
    },
    {
      label: 'Торговые компании',
      key: 'Trading companies',
    },
  ]
  return (
    <Layout className="mainDiv">
      <ConfigProvider
        theme={{
          token: {
            // HorizontalItemHoverColor: 'red',
            // colorText: 'rgb(123, 124, 125)',
          },
          components: {
            Menu: {
              colorBgElevated: 'rgb(255, 255, 255)',
              itemSelectedColor: 'rgb(141, 36, 248)',
              itemHoverColor: 'rgb(141, 36, 248)',
              itemColor: 'rgb(123, 124, 125)',
              horizontalItemSelectedColor: 'rgb(141, 36, 248)',

              // itemActiveBg: 'red',
              // itemSelectedBg: 'rgb(255, 255, 255)',
            },
            Layout: {
              bodyBg: 'white',
            },
            Input: {
              // activeBg: 'rgba(141, 36, 248, 0.2);',
              activeBorderColor: 'rgba(141, 36, 248, 1)',
              // activeShadow: 'red',
              hoverBorderColor: 'rgba(141, 36, 248, 1)',
              colorBorder: 'rgba(123, 124, 125, 1)',
              colorFillSecondary: 'red',
              borderRadius: 12,
              // inputFontSize: 30,
            },
            Table: {
              borderColor: 'none',
              headerBg: 'rgba(240, 243, 245, 1)',
              colorBgContainer: 'rgba(250, 250, 251, 1)',
              // borderRadius: 12,
              headerBorderRadius: 0,
            },
            Select: {
              colorBorder: 'rgba(123, 124, 125, 1)',
              colorPrimary: 'rgba(141, 36, 248, 1)',
              colorPrimaryHover: 'rgba(141, 36, 248, 1)',
              optionActiveBg: '#e8d3fe',
              // optionSelectedColor: '#8D24F8',
              optionSelectedBg: '#eee0fda8',
              borderRadius: 12,
            },
            Checkbox: {
              colorPrimary: 'rgba(141, 36, 248, 1)',
              colorPrimaryHover: 'rgba(141, 36, 248, 1)',
            },
            Radio: {
              // colorBgContainer: 'red',
              colorPrimary: 'rgba(141, 36, 248, 1)',
            },
          },
        }}
      >
        <Menu
          className={p.mainTabs}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
        <div className={p.mainChaild}>
          {current === 'account_Distributor' && (
            <Account_Distributor
              fetchDistributorAccount={fetchDistributorAccount}
              setFetcDistributorAccount={setFetcDistributorAccount}
            />
          )}
          {current === 'companyPage_Distributor' && <CompanyPage_Distributor />}
          {current === 'SettingPrices_Distributor' && (
            <SettingPrices_Distributor />
          )}
          {current === 'Staff_Distributor' && <Staff_Distributor />}
          {current === 'ListProducts_Distributor' && (
            <ListProducts_Distributor />
          )}
          {current === 'Transactions_Distributor' && (
            <Transactions_Distributor />
          )}
          {current === 'Mail_distributor' && <Mail_distributor />}
          {current === 'SalesAnalytics_Distributor' && (
            <SalesAnalytics_Distributor />
          )}
          {current === 'ListClient_Distributor' && <ListClient_Distributor />}

          {current === 'CreateProductManually' && <Distributor_CreateProductManually />}

          {/* {current === 'Company_page' && <Company_page />}
    {current === 'AddProducts' && <AddProducts />}
    {current === 'HistoryAdding' && <HistoryAdding />}
    {current === 'ListProducts' && <ListProducts />}
    {current === 'Transactions' && <Transactions />}
    {current === 'Mail' && <Mail />}
    {current === 'Analytics' && <SalesAnalytics />}
    {current === 'CustomerLists' && <ListClients />}

    {current === 'Trading companies' && <TradingCompanies />} */}
        </div>
      </ConfigProvider>
    </Layout>
  )
}

export default Distributor_Profile
