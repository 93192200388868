import React, {useEffect, useState} from 'react';
import {LoadStep} from './StepLoader';
import {useThree} from '@react-three/fiber';
import Size from "./Size"


function StepModel({ url, ...props }) {
    const [obj, setObj] = useState(null);
    const { scene } = useThree();
    useEffect(() => {
      async function load() {
        const mainObject = await LoadStep(url);
        setObj(mainObject);
        console.log(mainObject)
      }
      load();
      
    }, [url]);

    useEffect(() => {
        if (!!obj) {
            document.querySelector('canvas').width = 1
        }
    }, [obj]);

    if (!obj) {
      return null;
    }
   console.log(obj)
    return (
      <group name='ModelSTP' {...props}>
        <primitive object={obj} />
        {/* <Size obj={obj.children[0]}/> */}
        {/* <Size obj={obj.children[1]}/> */}
                <Size obj={obj}/>
      </group>
      
    );
  }
  export default StepModel