export const ListProdARR = [
    {
        key: 293103899,
        status: 'В наличии',
        name: `Сверло`,
        availability3D: true,
        Manufacturer: 'huizhu',
        dateAddition: `27.08.2024`,
        availability: 1000
    },
    {
        key: 293103900,
        status: 'Под заказ',
        name: `Ведро`,
        availability3D: false,
        Manufacturer: 'huizhu',
        dateAddition: `28.09.2024`,
        availability: 0
    },
    {
        key: 293103901,
        status: 'На доработку',
        name: `Болт`,
        availability3D: false,
        Manufacturer: 'huizhu',
        dateAddition: `30.09.2024`,
        availability: 0
    },
]