import React from 'react'
import aa from './Analytics_admin.module.css'
//! ANTD
import {DatePicker, Layout} from 'antd'

const { RangePicker } = DatePicker
const Analytics_admin = () => {
  return (
    <Layout className={aa.analyticMainDiv}>
      <Layout className={aa.rangeDiv}>
        <span>Период</span>
        <RangePicker />
      </Layout>
      <Layout className={aa.analyticBlocks}>
        <Layout className={`${aa.analyticBlock} `}>
          <span className="fontSiz_16_400Blc">
            Зарегистрированных пользователей
          </span>
          <span className="fontSiz_32_500">24</span>
        </Layout>
        <Layout className={`${aa.analyticBlock}`}>
          <span className="fontSiz_16_400Blc">Добавлено товаров</span>
          <span className="fontSiz_32_500">24</span>
        </Layout>
        <Layout className={`${aa.analyticBlock}`}>
          <span className="fontSiz_16_400Blc">Количество сессий</span>
          <span className="fontSiz_32_500">24</span>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Analytics_admin
