import React, { useState, useEffect, Children } from 'react'
import s from './Catalog.module.scss'
import { Link } from 'react-router-dom'

import {Button, Layout, Menu} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { RightOutlined } from '@ant-design/icons'
import CatalogCategory from "../../components/for_Catalog/CatalogCategory/CatalogCategory";
import useFetch from "../../hooks/useFetch";

const Catalog = ({setIsShowCatalog}) => {
    const [fetchCategories, setFetchCategories] = useState([])
    const [categories, setCategories] = useState();
    const [items, setItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryTitle, setSelectedCategoryTitle] = useState('');

    const [subCategories, setSubCategories] = useState([]);
    const catalogResponse = useFetch()

    useEffect(() => {
        catalogResponse.get(
            `${process.env.REACT_APP_DOMAIN_BFF}/catalog/api/nav`,
            setFetchCategories
        )
    }, [])

    useEffect(() => {
        setCategories(fetchCategories?.inner?.map((el, i) => {
            return (
                {
                    key: el?.id,
                    id: el?.id,
                    label: el?.alias?.toLowerCase(),
                    name: el?.name
                }
            )
        }))
        if (fetchCategories?.inner?.length > 0) setSelectedCategory(fetchCategories?.inner[0]?.id)
    }, [fetchCategories]);

    useEffect(() => {
        fetchData();
    }, [selectedCategory]);

    const fetchData = async () => {
        try {
            const tempSubCategories = [...subCategories];
            const category = fetchCategories?.inner?.find(f => f.id === selectedCategory);
            if (!category) {
                console.error('Категория не найдена');
                return;
            }
            const response = await fetch(`${process.env.REACT_APP_DOMAIN_BFF}/catalog/api/${category?.full_path?.join('/')}`);
            if (!response.ok) {
                console.error('Ошибка при запросе данных');
                return;
            }
            const data = await response.json();
            setItems(data);
            tempSubCategories.push({
                category_id: selectedCategory,
                category_title: category?.alias,
                category_childrens: data
            });
            setSubCategories(tempSubCategories);
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
        }
    };

    const dispatch = useDispatch()
    return (
        <Layout  className={s.container}>
            <Layout style={{overflowY: 'scroll', width: '340px', border: '1px solid #F0F3F5', background: '#FFFFFF'}}>
                {categories?.map((el, i) => {
                    return (
                        <Link
                            key={i}
                            to={`/catalog/${el.name}`}
                            className={`${s.menuItem} ${selectedCategory === el.id ? s.menuItemSelected : ''}`}
                            onMouseEnter={() => {
                                setItems([])
                                setSelectedCategory(el.id)
                            }}
                            onClick={() => {
                                setIsShowCatalog(false)
                            }}
                        >
                            {el.label}
                            {<RightOutlined />}
                        </Link>
                    )
                })}
            </Layout>
            <CatalogCategory
                setIsShowCatalog={setIsShowCatalog}
                categoryName={subCategories?.find(f => f.category_id === selectedCategory)?.category_title}
                subCategories={subCategories?.find(f => f.category_id === selectedCategory)?.category_childrens}
            />
        </Layout>
    )
}

export default Catalog
