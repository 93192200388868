import React, {useState} from 'react'
// import aca from './Add_company_admin.module.scss'
import ca from '../../common__Admin.module.scss'

import {ArrowLeftOutlined, InboxOutlined} from '@ant-design/icons'
import {Button, Checkbox, Input, Layout, Select, Upload} from 'antd'

import {useNavigate} from 'react-router'
import {Asterisk} from 'lucide-react'

const Add_company_admin = () => {
  const { TextArea } = Input
  const { Dragger } = Upload
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
  }
  //   const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const props = {
    name: 'file',
    // multiple: false,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    listType: 'picture-circle',
    // onChange(info) {
    //   const { status } = info.file
    //   if (status !== 'uploading') {
    //     console.log(info.file, info.fileList)
    //   }
    //   if (status === 'done') {
    //     message.success(`${info.file.name} file uploaded successfully.`)
    //   } else if (status === 'error') {
    //     message.error(`${info.file.name} file upload failed.`)
    //   }
    // },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }
  const navigate = useNavigate()
  return (
    <Layout style={{ rowGap: '24px' }}>
      <Layout className={ca.common_Div}>
        <Layout className={ca.common_Revers}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            className={ca.common_ReverseNavigation}
            onClick={() => navigate(-1)}
          >
            Назад
          </Button>
          <span className="fontSiz_24_500">Добавление новой компании</span>
        </Layout>
        <Layout className={ca.common_MainContent}>
          <Layout className={ca.common_FormLeft}>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Роль</span>
              </label>
              <Select
                placeholder="Роль"
                className={ca.modalContent__selectStaff}
                defaultValue="Роль"
                //   onChange={(val) =>
                //     setNewCompanyAd({ ...newCompanyAd, role: val })
                //   }
                options={[
                  {
                    value: 'Производитель',
                    label: 'Производитель',
                  },
                  {
                    value: 'Покупатель',
                    label: 'Покупатель',
                  },
                  {
                    value: 'Торговая компания',
                    label: 'Торговая компания',
                  },
                ]}
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Статус</span>
              </label>
              <Select
                placeholder="Статус"
                className={ca.modalContent__selectStaff}
                defaultValue="Активен"
                //   onChange={(val) =>
                //     setNewCompanyAd({ ...newCompanyAd, role: val })
                //   }
                options={[
                  {
                    value: 'Активен',
                    label: 'Активен',
                  },
                  {
                    value: 'Архив',
                    label: 'Архив',
                  },
                ]}
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Название компании</span>
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Название компании"
                //   value={'ГК Инструмент'}
                required
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Сайт</span>
              </label>
              <Input
                className={ca.modalContent__inptStaff}
                placeholder="Сайт"
                // value={newCompanyAd.name}
                required
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">ИНН</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={ca.modalContent__inptStaff}
                placeholder="ИНН"
                // value={newCompanyAd.inn}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, inn: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Страна</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={ca.modalContent__inptStaff}
                placeholder="Страна"
                //   value={newCompanyAd.country}
                //   onChange={(e) =>
                //     setNewCompanyAd({ ...newCompanyAd, country: e.target.value })
                //   }
              />
            </Layout>
            <Layout className={ca.common_uploadBlock}>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Логотип</span>
              </label>
              <Dragger
                className={ca.dragger}
                {...props}
                onPreview={handlePreview}
                //   onChange={handleChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Перетащите или выберите файлы в эту область
                </p>
                <p className="ant-upload-hint">
                  Формат — JPEG, PNG, WEBP. Размер не более 5 МБ.
                </p>
              </Dragger>
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Описание</span>
              </label>
              <TextArea
                showCount
                style={{
                  height: 164,
                  resize: 'none',
                }}
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Юридический адрес</span>
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Юридический адрес"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Фактический адрес</span>
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Фактический адрес"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">ОГРН</span>
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="ОГРН"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">КПП</span>
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="КПП"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <Checkbox>
                Готовы принимать заказы на изготовления инструмента и оснастки
              </Checkbox>
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">
                  Категории изготавления
                </span>
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Категории изготавления"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <Checkbox>
                Готовы оказывать услуги по ремонту инструмента и оснастки
              </Checkbox>
            </Layout>
            <Layout>
              <span style={{ fontWeight: '500' }}>Сотрудники</span>
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">ФИО</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="ФИО"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">E-mail</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="E-mail"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Телефон</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Телефон"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
          </Layout>
          <Layout className={ca.common_DeledBtn}>
            {/* <Button>Удалить аккаунт компании</Button> */}
          </Layout>
        </Layout>
      </Layout>
      <Button className={ca.common_AddBtnMore}>Добавить еще</Button>
      <Layout className={ca.common_BtnSave}>
        <Button style={{ width: '100%' }}>Сохранить </Button>
        <Button style={{ width: '100%' }}>Отменить изменения</Button>
      </Layout>
    </Layout>
  )
}

export default Add_company_admin
