import React, {useState} from 'react'
import ca from '../common__Admin.module.scss'
import gca from '../../../Web/GeneralComponentAdmin/GeneralComponentAdmin.module.scss'

import {ReactComponent as EdditSVG} from '../../../image/Edit_light.svg'

import {useNavigate} from 'react-router-dom'
import {Button, Input, Layout, Table, Tag} from 'antd'

const Staff_admin = () => {
  const navigate = useNavigate()
  const { Search } = Input
  const [dataStaffAd, setDatadataStaffAd] = useState([
    {
      key: '1',
      employeRole: 'Администратор',
      fullName: 'Краков Андрей Иванович',
      status: 'Активен',
      telephone: '+7 926 000 00 00',
      email: 'stroitex@example.com',
      name: 'ГК Стройтех',
      role: 'Производитель',
      status2: 'Активен',
      inn: '123435',
      country: 'РФ',
      website: 'GK.ru',
    },
    {
      key: '2',
      employeRole: 'Менеджер',
      fullName: 'Краков Андрей Иванович',
      status: 'Активен',
      telephone: '+7 926 000 00 00',
      email: 'stroitex@example.com',
      name: 'ГК Стройтех',
      role: 'Производитель',
      status2: 'Активен',
      inn: '123435',
      country: 'РФ',
      website: 'GK.ru',
    },
  ])
  const [newDataStaffAd, setNewDataStaffAd] = useState({
    key: dataStaffAd.length + 1,
    data: '',
    category: '',
    status: '',
    term: '',
    name: '',
    role: '',
    inn: '',
    country: 'РФ',
    website: '',
    fullName: '',
    telephone: '',
    email: '',
  })
  const columnsStaffAd = [
    {
      title: '',
      dataIndex: 'red',
      render: (text) => (
        <Button
          type="text"
          onClick={() => navigate('editingAccountStaffAdmin')}
        >
          <EdditSVG />
        </Button>
      ),
      width: '5%',
    },
    {
      title: 'Роль сотрудника',
      dataIndex: 'employeRole',
    },
    {
      title: 'ФИО',
      dataIndex: 'fullName',
    },

    {
      title: 'Статус',
      dataIndex: 'status2',
      render: (text) => {
        let color =
          text === 'Активная'
            ? 'rgba(115, 209, 61, 0.2)'
            : 'rgba(240, 243, 245, 1)'
        let textColor =
          text === 'Активная'
            ? 'rgba(115, 209, 61, 1)'
            : 'rgba(123, 124, 125, 1)'
        return (
          <Tag color={color}>
            <span style={{ color: textColor }}>{text}</span>
          </Tag>
        )
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'telephone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'tags',
      render: (text) => {
        let color =
          text.length > 15
            ? 'rgba(85, 102, 255, 0.32)'
            : 'rgba(85, 235, 255, 0.32)'
        let textColor =
          text.length > 15 ? 'rgba(57, 0, 178, 1)' : 'rgba(0, 157, 178, 1)'
        if (text === 'Покупатель') {
          color = 'rgba(255, 0, 245, 0.2)'
          textColor = 'rgba(158, 0, 255, 1)'
        }
        return (
          <Tag color={color} key={'tags'}>
            <span style={{ color: textColor }}>{text.toUpperCase()}</span>
          </Tag>
        )
      },
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'tags',
      render: (text) => {
        let color =
          text.length > 15
            ? 'rgba(85, 102, 255, 0.32)'
            : 'rgba(85, 235, 255, 0.32)'
        let textColor =
          text.length > 15 ? 'rgba(57, 0, 178, 1)' : 'rgba(0, 157, 178, 1)'
        if (text === 'Покупатель') {
          color = 'rgba(255, 0, 245, 0.2)'
          textColor = 'rgba(158, 0, 255, 1)'
        }
        return (
          <Tag color={color} key={'tags'}>
            <span style={{ color: textColor }}>{text.toUpperCase()}</span>
          </Tag>
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status2',
      render: (text) => {
        let color =
          text === 'Активная'
            ? 'rgba(115, 209, 61, 0.2)'
            : 'rgba(240, 243, 245, 1)'
        let textColor =
          text === 'Активная'
            ? 'rgba(115, 209, 61, 1)'
            : 'rgba(123, 124, 125, 1)'
        return (
          <Tag color={color}>
            <span style={{ color: textColor }}>{text}</span>
          </Tag>
        )
      },
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
    },
    {
      title: 'Страна',
      dataIndex: 'country',
    },
    {
      title: 'Сайт',
      dataIndex: 'website',
    },
  ]
  return (
    <Layout className={ca.common_MainDiv}>
      <Layout className={ca.common_InputGrup}>
        <Search
          suffix
          placeholder="input search text"
          style={{
            width: 200,
          }}
        />
      </Layout>
      <Layout className={ca.common_scrollWrapper}>
        <Table
          columns={columnsStaffAd}
          dataSource={dataStaffAd}
          className={gca.tableGeneral}
          scroll={{ x: 'max-content' }}
        />
      </Layout>
    </Layout>
  )
}

export default Staff_admin
