import React from 'react'
import {Link} from "react-router-dom";
import {Menu} from 'antd'

import icon_admin_basket from '../../../image/icon_admin_basket.svg'
import icon_admin_blocks from '../../../image/icon_admin_blocks.svg'
import icon_admin_branches from '../../../image/icon_admin_branches.svg'
import icon_admin_cube from '../../../image/icon_admin_cube.svg'
import icon_admin_docblank from '../../../image/icon_admin_docblank.svg'
import icon_admin_factory from '../../../image/icon_admin_factory.svg'
import icon_admin_histogram from '../../../image/icon_admin_histogram.svg'
import icon_admin_list from '../../../image/icon_admin_list.svg'
import icon_admin_openedbook from '../../../image/icon_admin_openedbook.svg'
import icon_admin_users from '../../../image/icon_admin_users.svg'
import {ReactSVG} from "react-svg";


const MenuAdmin = () => {
    const navigationMenu = [
        {
            key: 'analytics',
            label: <Link to={''}>Аналитика</Link>,
            icon: <ReactSVG src={icon_admin_histogram} />
        },
        {
            key: 'categories',
            label: <Link to={'categories'}>Категории</Link>,
            icon: <ReactSVG src={icon_admin_branches} />
        },
        {
            key: 'add_categories',
            label: <Link to={'addCategories'}>Добавление категорий</Link>,
            icon: <ReactSVG src={icon_admin_histogram} />
        },
        {
            key: 'add_products',
            label: <Link to={'addProducts'}>Товары на проверку</Link>,
            icon: <ReactSVG src={icon_admin_blocks} />
        },
        {
            key: 'products',
            label: <Link to={'productsAdmin'}>Одобренные товары</Link>,
            icon: <ReactSVG src={icon_admin_cube} />
        },
        {
            key: 'requests_all',
            label: <Link to={'requestsAdmin'}>Запросы</Link>,
            icon: <ReactSVG src={icon_admin_docblank} />
        },
        {
            key: 'companies',
            label: <Link to={'companyAdmin'}>Компании</Link>,
            icon: <ReactSVG src={icon_admin_factory} />
        },
        {
            key: 'persons',
            label: <Link to={'staffAdmin'}>Сотрудники</Link>,
            icon: <ReactSVG src={icon_admin_users} />
        },
        {
            key: 'requests_repair',
            label: <Link to={'repairRequestsAdmin'}>Запросы на ремонт</Link>,
            icon: <ReactSVG src={icon_admin_list} />
        },
        {
            key: 'requests_produce',
            label: <Link to={'requestsProductionAdmin'}>Запросы на изготовление</Link>,
            icon: <ReactSVG src={icon_admin_list} />
        },
        {
            key: 'orders',
            label: <Link to={'ordersAdmin'}>Заказы</Link>,
            icon: <ReactSVG src={icon_admin_basket} />
        },
        {
            key: 'history',
            label: <Link to={'historyActionsAdmin'}>История действий</Link>,
            icon: <ReactSVG src={icon_admin_openedbook} />
        },
        // {
        //     key: 'Analytics',
        //     label: <Link to="">Аналитика</Link>,
        //     icon: <ReactSVG src={icon_admin_histogram} />,
        // },
        // {
        //     key: 'requestsAdmin',
        //     label: <Link to="requestsAdmin">Запросы</Link>,
        //     icon: <ReactSVG src={icon_admin_branches} />,
        // },
        // {
        //     key: 'companyAdmin',
        //     label: <Link to="companyAdmin">Компании</Link>,
        //     icon: <SettingOutlined/>,
        // },
        // {
        //     key: 'staffAdmin',
        //     label: <Link to="staffAdmin">Сотрудники</Link>,
        //     icon: <SettingOutlined/>,
        // },
        // {
        //     key: 'productsAdmin',
        //     label: <Link to="productsAdmin">Товары</Link>,
        //     icon: <SettingOutlined/>,
        // },
        // {
        //     key: 'repairRequestsAdmin',
        //     label: <Link to="repairRequestsAdmin">Запросы на ремонт</Link>,
        //     icon: <SettingOutlined/>,
        // },
        // {
        //     key: 'requestsProductionAdmin',
        //     label: <Link to="requestsProductionAdmin">Запросы на изготовление</Link>,
        //     icon: <SettingOutlined/>,
        // },
        // {
        //     key: 'ordersAdmin',
        //     label: <Link to="ordersAdmin">Заказы</Link>,
        //     icon: <SettingOutlined/>,
        // },
        // {
        //     key: 'historyActionsAdmin',
        //     label: <Link to="historyActionsAdmin">История действий</Link>,
        //     icon: <SettingOutlined/>,
        // },
    ]

    return (
        <Menu
            style={{
                width: 256,
                padding: '16px 0',
                flex: 0,
            }}
            defaultSelectedKeys={[
                window.location.href.split('/').splice(2)[2] && 'Analytics',
            ]}
            mode="inline"
            items={navigationMenu}
        />
    )
}

export default MenuAdmin
