import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from 'antd'

export const Fetch_GET = createAsyncThunk('Fetch_GET_res/Fetch_GET', async (URL, thunkAPI) => {
    console.log(`${process.env.REACT_APP_DOMAIN_BFF}${URL.url}`)
    console.log(URL.set)
    const tokens = JSON.stringify(localStorage.accessToken)
    try {
        const res = await axios.get(`${process.env.REACT_APP_DOMAIN_BFF}${URL.url}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokens.replace(/"/g, '')}`,
            }
        })
        console.log(res.data)
        return res.data
    } catch (error) {
        console.log(error)
        message.error('Что-то пошло не так! Мы уже разбираемся в этой ошибке.')
        return thunkAPI.rejectWithValue(error)
    }
})

const Fetch_GET_Slice = createSlice({
    name: 'Fetch_GET_res',
    initialState: {
        card: [],
        category: [],
        // filtered: [],
        isLoading: false
    },
    extraReducers: (builder) => {
        // builder.addCase(Fetch_GET.pending, (state) => {
        //     state.isLoading = true
        // });
        builder.addCase(Fetch_GET.fulfilled, (state, { payload }) => {
            console.log(state)
            console.log(payload)
            state.card = payload
            state.isLoading = true
            const arr = []
            // payload.map((el, i) => {
            //     arr.push(el.category.name)
            // })
            // state.category = [...new Set(arr)]
        });
        // builder.addCase(Fetch_GET.rejected, (state) => {
        //     state.isLoading = false
        //     console.log('error - rejected')
        // })
    },
    reducers: {
        arrCategory(state, action) {
            state.category = action.payload
        }
    },
})

export const { arrCategory } = Fetch_GET_Slice.actions
export default Fetch_GET_Slice.reducer