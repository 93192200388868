import React, {useEffect, useState} from 'react'
import {Button, Flex, message, Table, Typography} from "antd";
import ga from '../../../Web/general_admin.module.scss'
import Search from "antd/lib/input/Search";
import {ArrowLeftOutlined} from "@ant-design/icons";
import aca from './AddProductsAdmin.module.scss'
import {Link, useSearchParams} from "react-router-dom";

const AddProductsAdmin = () => {
    const blocks = [
        {
            key: '0',
            title: 'Добавление новых позиций',
            value: '+250/500',
            dataIndex: 'new'
        },
        {
            key: '1',
            title: 'Отправлено после доработки',
            value: '+250/500',
            dataIndex: 'renew'
        },
        {
            key: '2',
            title: 'Редактирование параметров',
            value: '+250/500',
            dataIndex: 'edit'
        },
        {
            key: '3',
            title: 'Отказ',
            value: '200',
            dataIndex: 'denied'
        },
    ]
    // const columns = [
    //     {
    //         title: 'Артикул',
    //         dataIndex: 'article',
    //         key: 'article',
    //     },
    //     {
    //         title: 'Фото',
    //         dataIndex: 'image',
    //         key: 'image',
    //     },
    //     {
    //         title: 'Производитель',
    //         dataIndex: 'manufacture',
    //         key: 'manufacture',
    //     },
    //     {
    //         title: 'ТК',
    //         dataIndex: 'is_tk',
    //         key: 'is_tk',
    //     },
    //     {
    //         title: 'Наименование',
    //         dataIndex: 'name',
    //         key: 'name',
    //     },
    //     {
    //         title: 'Наличие 3D',
    //         dataIndex: 'is_3d',
    //         key: 'is_3d',
    //     },
    //     {
    //         title: 'Дата добавления',
    //         dataIndex: 'created_date',
    //         key: 'created_date',
    //     },
    // ]

    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState(null);
    const [typeTableData, setTypeTableData] = useState([])

    useEffect(() => {
        if (!!searchParams.get('type')) {
            setType(blocks.find(f => f.dataIndex === searchParams.get('type')))
        } else if (!!type) setType(null)
    }, [searchParams])

    const getStatusByType = (value) => {
        switch (value) {
            case 'new':
                return '/to_approve';
            case 'renew':
                return '';
            case 'edit':
                return '';
            case 'denied':
                return '';
            default:
                return '';
        }
    }

    useEffect(() => {
        if (!!type) {
            fetch(`${process.env.REACT_APP_DOMAIN_BFF}/admin/draft/list/status${getStatusByType(type.dataIndex)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status !== 200) {
                        message.error('Ошибка!', res.status)
                    } else return res
                })
                .then(res => res.json())
                .then(res => {
                    setTypeTableData(res)
                })
        }
    }, [type])

    const columns = [
        {
            key: 0,
            title: 'Идентификатор',
            dataIndex: 'id',
            render: (id) => {
                return <Link to={`view/${id}`}>{id}</Link>
            }
        },
        {
            key: 1,
            title: 'Пользователь',
            dataIndex: 'username'
        },
        {
            key: 2,
            title: 'Дата создания',
            dataIndex: 'created'
        },
        {
            key: 3,
            title: 'Дата обновления',
            dataIndex: 'updated'
        },
    ]

    return (
        <Flex className={ga.container}>
            {!!type &&
                <Flex vertical justify={'flex-start'} align={'flex-start'} gap={'8px'}>
                    <Button
                        className={aca.headerBackButton}
                        icon={<ArrowLeftOutlined />}
                        onClick={() => { setSearchParams(prevState => prevState.delete('type'))}}
                        type={'link'}
                    >
                        Назад
                    </Button>
                    <Typography.Text className={aca.headerTitle}>{type?.title}</Typography.Text>
                </Flex>
            }
            <Search className={ga.search} />
            <Flex>
                {!!!type ?
                    <Flex className={aca.blocksContainer}>
                        {blocks?.map((block) => (
                            <Flex vertical className={aca.block} onClick={() => { setSearchParams({...searchParams, type: block.dataIndex}) }}>
                                <Typography.Text className={aca.block__title}>{block.title}</Typography.Text>
                                <Typography.Text className={aca.block__value}>{block.value}</Typography.Text>
                            </Flex>
                        ))}
                    </Flex>
                    :
                    <Flex className={aca.typeContainer}>
                        <Table
                            className={ga.Table}
                            columns={columns}
                            dataSource={typeTableData}
                        />
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}

export default AddProductsAdmin