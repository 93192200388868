import React, {useEffect, useState} from 'react'
import s from './CatalogView.module.scss'
import {AppstoreOutlined, MenuOutlined} from '@ant-design/icons'
import {Checkbox, Layout, Menu, Pagination, Radio, Skeleton, message, Typography, Select} from 'antd'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import CatalogItem from '../../../components/for_Catalog/CatalogItem/CatalogItem'
import gc from '../../../Web/GeneralComponent/GeneralComponent.module.scss'
import cv from './CatalogView.module.scss'
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs'
import useFetch from '../../../hooks/useFetch'
import {useDispatch, useSelector} from "react-redux";

const CatalogView = ({switchClass}) => {
    const CheckboxItem = ({checked, title_category, title_item}) => {
        return (
            <Checkbox checked={checked} onChange={(e) => onFiltersChange(e, title_category, title_item)}>
                <div className={s.chealFldiv}>
                    <span className="fontSiz_16_400Blc">{title_item}</span>
                    <span
                        className="fontSiz_16_400Blc"
                        style={{color: '#ACAEB0'}}
                    >

                    </span>
                </div>
            </Checkbox>
        )
    }
    //const { catalogId } = useParams()

    const CATALOG_DISPLAY_TYPE_GRID = useSelector((state) => state.otherVariables.TOGGLE_CATALOG_DISPLAY_TYPE_GRID)
    const dispatch = useDispatch()

    const {'category': restOfPath} = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    const [catalogItems, setCatalogItems] = useState([])
    const catalogViewResponse = useFetch()

    const catalogId = restOfPath;//.split('/').filter(Boolean);

    const [filterParamList, setFilterParamList] = useState(null)
    const [filterList, setFilterList] = useState([])
    const [additions, setAdditions] = useState(null)

    const onFiltersChange = (e, filterName, filterValue) => {
        console.log(e.target.checked, filterName, filterValue)

        if (!filterParamList) { // IF NULL
            // message.error('IF NULL')
            setFilterParamList([{
                key: filterName,
                value: filterValue
            }])
        } else if (e.target.checked && !!!filterParamList.find(f => f.key === filterName && f.value === filterValue)) { // IF NOT EXISTS + toTrue
            // message.info('IF NOT EXISTS + toTrue')
            setFilterParamList([...filterParamList, {
                key: filterName,
                value: filterValue
            }])
        } else if (!e.target.checked && !!filterParamList.find(f => f.key === filterName && f.value === filterValue)) { // IF EXISTS + toFalse
            // message.info('IF EXISTS + toFalse')
            setFilterParamList(filterParamList.filter(f => f.key !== filterName || (f.key === filterName && f.value !== filterValue)))
            // let result = [...filterParamList]
            // result = result.filter(f => f.key !== filterName || (f.key === filterName && f.value !== filterValue))
            // setFilterParamList([...result])
        } else {
            // message.warning('ELSE')
        }
    }
    const getFiltersWithSelected = () => {
        if (items.length === 0) {
            const result = filterList.map(filter => {
                return ({
                    key: filter.name,
                    label: filter.alias,
                    children: filter?.values?.map((value, index) => {
                        return ({
                            key: value,
                            checked: false,
                            label: (<CheckboxItem checked={false} title_category={filter.name} title_item={value}/>),
                        })
                    })
                })
            }).map(filter => {
                if (filterParamList.map(param => param.key).includes(filter.key)) {
                    return {
                        ...filter, children: filter.children.map(category_value => {
                            if (filterParamList.filter(f => f.key === filter.key).filter((item, index, arr) => arr.indexOf(item) === index).map(filter => filter.value).includes(category_value.key)) {
                                // console.log(`Match value: ${category_value.key}`)
                                return {
                                    ...category_value,
                                    checked: true,
                                    label: (<CheckboxItem checked={true} title_category={filter.key}
                                                          title_item={category_value.key}/>)
                                }
                            }
                            return {
                                ...category_value,
                                checked: false,
                                label: (<CheckboxItem checked={false} title_category={filter.key}
                                                      title_item={category_value.key}/>)
                            }
                        })
                    }
                }
                return filter
            })
            return result
        } else {
            const result = items.map(filter => {
                if (filterParamList.map(param => param.key).includes(filter.key)) {
                    // console.log(`Match category: ${filter.key}`)
                    return {
                        ...filter, children: filter.children.map(category_value => {
                            if (filterParamList.filter(f => f.key === filter.key).filter((item, index, arr) => arr.indexOf(item) === index).map(filter => filter.value).includes(category_value.key)) {
                                // console.log(`Match value: ${category_value.key}`)
                                return {
                                    ...category_value,
                                    checked: true,
                                    label: (<CheckboxItem checked={true} title_category={filter.key}
                                                          title_item={category_value.key}/>)
                                }
                            }
                            return {
                                ...category_value,
                                checked: false,
                                label: (<CheckboxItem checked={false} title_category={filter.key}
                                                      title_item={category_value.key}/>)
                            }
                        })
                    }
                }
                return filter
            })
            return result
        }
    }

    useEffect(() => {
        let isFilters = false
        let isCatalogs = false

        let filtersArray = []
        let catalogsArray = []

        if (catalogId.split('*')[1]) {
            isFilters = true
            filtersArray = catalogId.split('*')[1].split('+').map(filter => {
                let key = filter.split('=')[0]
                let value = filter.split('=')[1]
                return {
                    key: key,
                    value: value
                }
            })
            setFilterParamList(filtersArray)
        } else setFilterParamList([])
        if (catalogId.split('*')[0].split('+')[0] !== '') {
            isCatalogs = true
            catalogsArray = catalogId.split('*')[0].split('+')
        }

        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/catalog/filter/nav/${isCatalogs ? catalogsArray.join('/') : ""}${isFilters ? `?${filtersArray.map(filter => `${filter.key}=${filter.value}`).join('&')}` : ""}`)
            .then(res => {
                return res
            })
            .then(res => res.json())
            .then(res => {
                setFilterList(res)
            })
    }, [])

    useEffect(() => {
        if (!filterParamList) return
        if (filterParamList.some(s => s.key === 'undefined' || s.value === 'undefined')) {
            setFilterParamList(filterParamList.filter(f => f.key !== 'undefined' || f.value !== 'undefined'))
            return
        }
        console.log('filterParamList => ', typeof filterParamList, filterParamList)

        let result_link = [`${catalogId.split('*')[0]}`]
        if (filterParamList.length > 0) {
            result_link.push(filterParamList.map(filter => `${filter.key}=${filter.value}`).join('+'))
        }
        result_link = result_link.join('*')

        // console.log('result_link = ', result_link)
        let isFilters = false
        let isCatalogs = false

        let filtersArray = []
        let catalogsArray = []

        if (result_link.split('*')[1]) {
            isFilters = true
            filtersArray = result_link.split('*')[1].split('+').map(filter => {
                let key = filter.split('=')[0]
                let value = filter.split('=')[1]
                return {
                    key: key,
                    value: value
                }
            })
        }
        if (result_link.split('*')[0].split('+')[0] !== '') {
            isCatalogs = true
            catalogsArray = result_link.split('*')[0].split('+')
        }

        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/catalog/filter/nav/${isCatalogs ? catalogsArray.join('/') : ""}${isFilters ? `?${filtersArray.map(filter => `${filter.key}=${filter.value}`).join('&')}` : ""}`)
            .then(res => {
                return res
            })
            .then(res => res.json())
            .then(res => {
                setFilterList(res)

                catalogViewResponse.get(
                    `${process.env.REACT_APP_DOMAIN_BFF}/catalog/find/nav/${isCatalogs ? catalogsArray.join('/') : ""}${isFilters ? `?${filtersArray.map(filter => `${filter.key}=${filter.value}`).join('&')}` : ""}`,
                    setCatalogItems,
                    navigate(`/catalog/${catalogId.split('*')[0]}${filterParamList.length > 0 ? `*${filterParamList.map((filter) => `${filter.key}=${filter.value}`).join('+')}` : ""}/content`)
                )
                catalogViewResponse.get(
                    `${process.env.REACT_APP_DOMAIN_BFF}/catalog/api/nav/${isCatalogs ? catalogsArray.join('/') : ""}`,
                    setAdditions
                )
                // navigate(`/catalog/${catalogId.split('*')[0]}${filterParamList.length > 0 ? `*${filterParamList.map((filter) => `${filter.filter_name}=${filter.filter_value}`).join('+')}` : ""}/content`)
            })
    }, [filterParamList]);

    const [items, setItems] = useState([])

    useEffect(() => {
        if (items && items.length === 0) {
            setItems(filterList.map(filter => {
                return ({
                    key: filter.name,
                    label: filter.alias,
                    children: filter?.values?.map((value, index) => {
                        return ({
                            key: value,
                            label: (
                                <CheckboxItem checked={false} title_category={filter.name} title_item={value}/>
                            ),
                            checked: false
                        })
                    })
                })
            }))
        }
    }, [filterList]);


    useEffect(() => {
        catalogViewResponse.get(
            `${process.env.REACT_APP_DOMAIN_BFF}/catalog/find/nav/${catalogId.replace('+', '/')}`,
            setCatalogItems
        )
        catalogViewResponse.get(
            `${process.env.REACT_APP_DOMAIN_BFF}/catalog/api/nav/${catalogId.replace('+', '/')}`,
            setAdditions
        )
    }, [])

    const paginationOnChange = (e) => {
        // console.log(e)
        fetch(
            `${process.env.REACT_APP_DOMAIN_BFF}/catalog/find/nav/${catalogId.replace('+', '/')}`,
            {
                method: 'GET',
            }
        )
            .then((res) => {
                return res
            })
            .then((res) => res.json())
            .then((res) => {
                setCatalogItems(res)
                console.log(res)
            })
    }

    const [orderType, setOrderType] = useState('desc popular')
    const orderTypes = [
        {
            key: 0,
            label: 'Сначала популярные',
            value: 'desc popular',
        },
        {
            key: 1,
            label: 'Сначала дешевые',
            value: 'asc price',
        },
        {
            key: 2,
            label: 'Сначала дорогие',
            value: 'desc price'
        },
        {
            key: 3,
            label: 'Сначала с высоким рейтингом',
            value: 'asc rate'
        },
        {
            key: 4,
            label: 'Сначала с низким рейтингом',
            value: 'desc rate'
        }
    ]

    return (
        <Layout className={cv.container}>
            <Layout className={gc.breadcrumbsBlock}>
                <Breadcrumbs breadcrumbs={additions}/>
            </Layout>
            <Layout className={cv.container__title}>
                <Typography.Text className="fontSiz_32_500">{switchClass}Категория</Typography.Text>
            </Layout>
            <Layout className={cv.container__body}>
                <Layout className={cv.body__sidebarContainer}>
                    {filterParamList && <Menu
                        className={cv.sidebar}
                        defaultOpenKeys={filterParamList
                            ?.map(el => `${el.key}`)
                            .filter((value, index, current_value) => current_value.indexOf(value) === index)
                        }
                        mode="inline"
                        items={filterParamList.length === 0
                            ? items
                            : getFiltersWithSelected()
                        }
                    />}
                </Layout>
                <Layout className={cv.body__dataContainer}>
                    <Layout className={cv.dataContainer__header}>
                        <Select
                            defaultValue={[orderType]}
                            className={cv.dataContainer__header_select}
                            variant={'borderless'}
                            options={orderTypes}
                        />
                        <Radio.Group
                            defaultValue="grid"
                            buttonStyle="solid"
                            onChange={(e) => {
                                dispatch({type: 'TOGGLE_CATALOG_DISPLAY_TYPE_GRID', payload: e.target.value === 'grid'})
                            }}
                            disabled={catalogItems?.resultSet?.length === 0}
                        >
                            <Radio.Button value="inline">
                                <MenuOutlined/>
                            </Radio.Button>
                            <Radio.Button value="grid">
                                <AppstoreOutlined size={'10px'}/>
                            </Radio.Button>
                        </Radio.Group>
                    </Layout>
                    <Layout
                        className={`${CATALOG_DISPLAY_TYPE_GRID ? cv.itemsList__grid : cv.itemsList__inline}`}
                    >
                        {catalogItems?.resultSet?.length > 0
                            ? catalogItems?.resultSet?.map((el) => {
                                return (
                                    <CatalogItem
                                        key={el.id}
                                        catalogId={catalogId}
                                        element={el}
                                    />
                                )
                            })
                            : Array(10)
                                .fill(null)
                                .map((a, i) => (
                                    <Skeleton.Node
                                        key={i}
                                        style={{width: '256px', height: '360px'}}
                                        active={true}
                                    />
                                ))}
                    </Layout>
                    <Pagination
                        align={'center '}
                        total={catalogItems.count}
                        defaultCurrent={1}
                        showSizeChanger={false}
                        onChange={paginationOnChange}
                    />
                </Layout>
            </Layout>
        </Layout>
    )
}

export default CatalogView
