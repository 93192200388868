import React, {useState} from 'react'
import s from '../CatalogView/CatalogView.module.scss'
import cv from '../CatalogView/CatalogView.module.scss'
import ci from './CatalogItem.module.scss'
import SVGstate from '../../../image/Stat.svg'
import SVGfavorite from '../../../image/Favorites.svg'
import Star_violet from '../../../image/Star_violet.svg'
import {Link} from 'react-router-dom'
import {Button, Layout, message, Spin, Skeleton, Typography, Flex} from 'antd'

import cicss from './CatalogItem.module.scss'
import {useSelector} from "react-redux";

const CatalogItem = ({element, itemPictures, catalogId}) => {
    const CATALOG_DISPLAY_TYPE_GRID = useSelector((state) => state.otherVariables.TOGGLE_CATALOG_DISPLAY_TYPE_GRID)

    return (
        <Link
            to={`/catalog/${catalogId}/content/${element.id}`}
            state={{id: element.id}}
            className={`${ci.item} ${CATALOG_DISPLAY_TYPE_GRID ? ci.column : ci.line}`}
            style={CATALOG_DISPLAY_TYPE_GRID ? {maxWidth: '256px', maxHeight: '360px'} : {}}
        >
            {CATALOG_DISPLAY_TYPE_GRID &&
                <Layout className={ci.item_article}>
                    <Typography.Text className="fontSiz_12_400">Артикул {element?.article}</Typography.Text>
                </Layout>
            }
            <Layout className={`${ci.item_imageBlock} ${!CATALOG_DISPLAY_TYPE_GRID && ci.item_imageBlock__inline}`}>
                {element?.preview !== undefined ? (
                    <img style={{height: !CATALOG_DISPLAY_TYPE_GRID ? '111px' : '162px', width: !CATALOG_DISPLAY_TYPE_GRID ? '111px' : ''}}
                         src={`${process.env.REACT_APP_DOMAIN_CDN}${element.preview}`} alt=""/>
                ) : (
                    <Skeleton.Image style={{height: '162px', width: '224px'}} active={true}/>
                )}
                {CATALOG_DISPLAY_TYPE_GRID &&
                    <Flex className={ci.item_imageBlock__controls}>
                        <img src={SVGfavorite} alt=""/>
                        <img src={SVGstate} alt=""/>
                    </Flex>
                }
            </Layout>
            <Layout className={ci.item_descriptionBlock}>
                {!CATALOG_DISPLAY_TYPE_GRID &&
                    <Layout style={{paddingBottom: 16, background: 'transparent'}}>
                        <Typography.Text className={`fontSiz_12_400 `}>
                            Артикул {element.article === 'null' ? '0000' : element.article}
                        </Typography.Text>
                    </Layout>
                }
                <Layout className={`${s.starFlex}`} style={!CATALOG_DISPLAY_TYPE_GRID ? {justifyContent: 'flex-start'} : {}}>
                    <div className={cicss.rating} style={{'--rate': '4.7'}}>
                        <div className={cicss.star}></div>
                        <div className={cicss.star}></div>
                        <div className={cicss.star}></div>
                        <div className={cicss.star}></div>
                        <div className={cicss.star}></div>
                    </div>
                    <div>
                        <span className="fontSiz_12_400">{element?.itemRateCount || 4.7}</span>
                    </div>
                </Layout>
                <Layout className={`${s.slTexLink}`} style={!CATALOG_DISPLAY_TYPE_GRID ? {alignItems: 'flex-start'} : {}}>
                    <Typography.Text style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        width: '100%'
                    }}>{element?.name}</Typography.Text>
                    <Link to={`/manufacturer/${element?.manufacture_name}`}
                          style={{fontSize: 14, color: '#8D24F8', fontWeight: 400}}>
                        {element?.manufacture_name || 'Производитель'}
                    </Link>
                </Layout>
            </Layout>
            <Layout className={`${ci.item_additionBlock} ${!CATALOG_DISPLAY_TYPE_GRID ? ci.additionBlockLine : ci.additionBlockColumn}`}>
                <Typography.Text className={ci.item_additionBlock__price} style={{fontWeight: 500}}>
                    {element.value ? `${element.value} ${element.currency}` : '0 ₽'}
                </Typography.Text>
                {!CATALOG_DISPLAY_TYPE_GRID &&
                    <Typography.Text
                        className="fontSiz_12_400"
                        style={{color: '#8D24F8'}}
                    >
                        +2 предложения от 20 000 ₽
                    </Typography.Text>
                }
                <Layout className={ci.item_additionBlock__controls} style={!CATALOG_DISPLAY_TYPE_GRID ? {flexDirection: 'row', flexGrow: '0'} : {}}>
                    <Button
                        className={cv.button__addBasket}
                        style={{width: !CATALOG_DISPLAY_TYPE_GRID ? '320px' : '100px'}}
                        onClick={() => message.success(`Добавлено в корзину!`)}>
                        В корзину
                    </Button>
                    {!CATALOG_DISPLAY_TYPE_GRID &&
                        <Flex style={{background: 'transparent'}} gap={'small'}>
                            <img src={SVGfavorite} alt="В избранное"/>
                            <img src={SVGstate} alt="Статистика"/>
                        </Flex>
                    }
                </Layout>
            </Layout>
        </Link>
    )
}

export default CatalogItem
