import React, {useRef, useState, useEffect, useCallback} from 'react';
import {LoadStep} from './StepLoader';
import {Canvas, useThree, useLoader} from '@react-three/fiber';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import Size from "../3D/Scene/Size";

function ObjLoader({url, ...props}) {
    const obj = useLoader(OBJLoader, url ?? "")
    console.log(obj)
    return (
        <group name='ModelOBJ' {...props}>
            <primitive object={obj}/>
            <Size obj={obj}/>
        </group>
    );
}

export default ObjLoader