import React, { useEffect, useState } from 'react'
import p from '../../../Web/profile/profile.module.scss'

import { Button, Input, Select, Layout } from 'antd'
import GrupLogoCompany from '../../../image/grupCompany.svg'
import useFetch_profile from '../../for_Seller_Prof/hooks/useFetch_profile'
import Modal_companyPage from '../../../Web/profile/components/Modal_companyPage'

const CompanyPage_Distributor = () => {
  const distributerCompanyResponse = useFetch_profile()
  const [fetcDistributerCompany, setFetcDistributerCompany] = useState()
  const [fileIMG, setFileIMG] = useState(GrupLogoCompany)

  const { TextArea } = Input
  const [openModalCompany, setOpenModalCompany] = useState(false)
  const showModal = () => {
    setOpenModalCompany(true)
  }
  function handleChange(e) {
    console.log(e.target.files)
    setFileIMG(URL.createObjectURL(e.target.files[0]))
  }

  useEffect(() => {
    distributerCompanyResponse.get(
      `${process.env.REACT_APP_DOMAIN_BFF}/trade/company/page`,
      setFetcDistributerCompany
    )
  }, [])
  console.log(fetcDistributerCompany)
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_company_topInfo}>
        <span className="fontSiz_24_500">Страница компании</span>
        <Button onClick={showModal}>Предварительный просмотр</Button>
        <Modal_companyPage
          openModalCompany={openModalCompany}
          setOpenModalCompany={setOpenModalCompany}
          fetchCompanyModal={fetcDistributerCompany}
        />
      </Layout>
      <Layout className={p.common_company_formInfo}>
        <span className="fontSiz_16_400Blc">
          Страница о Вашей компании доступна для поиска и просмотра всем
          посетителям маркетплейса. Данные платформа взяла с Вашего сайта. Вы
          можете редактировать информацию по Вашему усмотрению.
        </span>
        <span className="fontSiz_16_500">Логотип</span>
        <img src={fileIMG} className={p.common_company_imgLogo} />
        <label className={p.common_company_labelFile}>
          <span className="fontSiz_16_400Blc">Загрузить другой</span>
          <input type="file" onChange={handleChange} />
        </label>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Название компании</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="Название компании"
            allowClear
            type="text"
            name="companyname"
            value={fetcDistributerCompany?.company_name}
            onChange={(e) =>
              setFetcDistributerCompany({
                ...fetcDistributerCompany,
                company_name: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Описание</span>
          <TextArea
            className={p.common_chaild_inputArea}
            placeholder="Описание"
            value={fetcDistributerCompany?.company_description}
            onChange={(e) =>
              setFetcDistributerCompany({
                ...fetcDistributerCompany,
                company_description: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Юридический адрес</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="Юридический адрес"
            allowClear
            type="text"
            name="urAdress"
            value={fetcDistributerCompany?.company_address_legal}
            onChange={(e) =>
              setFetcDistributerCompany({
                ...fetcDistributerCompany,
                company_address_legal: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Фактический адрес</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="Фактический адрес"
            allowClear
            type="text"
            name="facAdress"
            value={fetcDistributerCompany?.company_address_actual}
            onChange={(e) =>
              setFetcDistributerCompany({
                ...fetcDistributerCompany,
                company_address_actual: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">ОГРН</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="ОГРН"
            allowClear
            type="text"
            name="ogrn"
            value={fetcDistributerCompany?.company_ogrn}
            onChange={(e) =>
              setFetcDistributerCompany({
                ...fetcDistributerCompany,
                company_ogrn: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">КПП</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="КПП"
            allowClear
            type="text"
            name="kpp"
            value={fetcDistributerCompany?.company_kpp}
            onChange={(e) =>
              setFetcDistributerCompany({
                ...fetcDistributerCompany,
                company_kpp: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divCheakBox}>
          <input
            type="checkbox"
            name="radio"
            id="rememb"
            value={fetcDistributerCompany?.company_isReady_create_orders}
            onChange={(e) =>
              setFetcDistributerCompany({
                ...fetcDistributerCompany,
                company_isReady_create_orders: e.target.value,
              })
            }
          />
          <label
            htmlFor="rememb"
            style={{ paddingLeft: '8px', color: '#000' }}
            className="fontSiz_16_400Blc"
          >
            Готовы принимать заказы на изготовления инструмента и оснастки
          </label>
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">
            Категории, которые Вы можете изготавливать
          </span>

          <Select
            placeholder={'Категории'}
            style={{
              width: '100%',
              height: 40,
            }}
            className="selectCompany"
            // onChange={handleChange}
            options={fetcDistributerCompany?.company_categories?.map(
              (val, i) => {
                return { value: i, label: val }
              }
            )}
          />
        </Layout>
        <Layout className={p.common_chaild_divCheakBox}>
          <input
            type="checkbox"
            name="radio"
            id="rememb"
            value={fetcDistributerCompany?.company_isReady_uslugi}
            onChange={(e) =>
              setFetcDistributerCompany({
                ...fetcDistributerCompany,
                company_isReady_uslugi: e.target.value,
              })
            }
          />
          <label
            htmlFor="rememb"
            style={{ paddingLeft: '8px', color: '#000' }}
            className="fontSiz_16_400Blc"
          >
            Готовы оказывать услуги по ремонту инструмента и оснастки
          </label>
        </Layout>
        <Button className={p.common_company_btnSave}>Сохранить</Button>
      </Layout>
    </Layout>
  )
}

export default CompanyPage_Distributor
