import React, { useEffect, useState } from 'react'
import p from '../../../Web/profile/profile.module.scss'
import { Button, Input, Select, Layout } from 'antd'
import GrupLogoCompany from '../../../image/grupCompany.svg'
import useFetch_profile from '../hooks/useFetch_profile'
import Modal_companyPage from '../../../Web/profile/components/Modal_companyPage'
const Company_page = ({ fetchSellerCompany, setFetcSellerCompany }) => {
  const sellerCompanyResponse = useFetch_profile()
  const [fileIMG, setFileIMG] = useState(GrupLogoCompany)
  const [openModalCompany, setOpenModalCompany] = useState(false)
  const showModal = () => {
    setOpenModalCompany(true)
  }

  const { TextArea } = Input
  function handleChange(e) {
    console.log(e.target.files)
    setFileIMG(URL.createObjectURL(e.target.files[0]))
  }

  useEffect(() => {
    sellerCompanyResponse.get(
      `${process.env.REACT_APP_DOMAIN_BFF}/trade/company/page`,
      setFetcSellerCompany
    )
  }, [])
  // console.log(fetchSellerCompany)

  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_company_topInfo}>
        <span className="fontSiz_24_500">Страница компании</span>
        <Button onClick={showModal}>Предварительный просмотр</Button>
        {/* <Modal
          // title="Basic Modal"
          className={p.common_modalCompany}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={'98%'}
          footer={null}
        >
          <Layout className={p.common_modalCompany_titl}>
            <img
              className={p.common_imgCompanyModal}
              src={require('../../../image/logoModalCompany.png')}
              alt="logo"
            />
            <span
              className="fontSiz_32_500"
              style={{ color: 'rgba(141, 36, 248, 1)' }}
            >
              ГК Инструмент: каталог и витрина производителя
            </span>
          </Layout>
          <Layout className={p.common_modalCompany_main}>
            <Layout className={p.common_modalCompany_infoTop}>
              <span className="fontSiz_24_500">О компании</span>
              <Layout>
                <span>
                  Комплексные поставки промышленного инструмента и техоснастки.
                  Производственный консалтинг.
                </span>
                <span>
                  Более 13 лет мы успешно осуществляем комплексные поставки
                  промышленного инструмента и техоснастки. На сегодняшний день
                  наша компания является одним из лидеров рынка по поставкам
                  инструмента в России
                </span>
                <span>
                  Наши клиенты — это предприятия, занятые производством в
                  различных отраслях промышленности на территории РФ.
                </span>
                <span>
                  Производитель оказывает услуги по изготовлению и ремонту
                  инструментов
                </span>
                <Button className={p.common_btn_allTOvar} type="tetx">
                  Все товары компании
                </Button>
                <Button className={p.common_btn_writeRepres}>
                  Написать представителю
                </Button>
              </Layout>
            </Layout>
            <Layout className={p.common_modalCompany_infoCentr}>
              <Layout>
                <span
                  className="fontSiz_24_500"
                  style={{ paddingBottom: '24px' }}
                >
                  Реквизиты
                </span>
                <Layout className={p.common_divForLabel}>
                  <Layout className={p.common_labelGrupe}>
                    <span>Название компании</span>
                    <span>sdsdsd</span>
                  </Layout>
                  <Layout className={p.common_labelGrupe}>
                    <span>Юридический адрес</span>
                    <span>Россия, 192236, Санкт-Петербург, Софийская, 12</span>
                  </Layout>
                  <Layout className={p.common_labelGrupe}>
                    <span>Фактический адрес</span>
                    <span>Россия, 192236, Санкт-Петербург, Софийская, 12</span>
                  </Layout>
                  <Layout className={p.common_labelGrupe}>
                    <span>ОГРН</span>
                    <span>1157847363750</span>
                  </Layout>
                  <Layout className={p.common_labelGrupe}>
                    <span>ИНН</span>
                    <span>1157847350</span>
                  </Layout>
                  <Layout className={p.common_labelGrupe}>
                    <span>КПП</span>
                    <span>11847350</span>
                  </Layout>
                </Layout>
              </Layout>
              <Layout>
                <img src={require('../../../image/mapYan.png')} alt="sd" />
              </Layout>
            </Layout>
            <Layout className={p.common_modalCompany_infoBottom}>
              <span className="fontSiz_24_500">Отзывы </span>
              <Layout>
                <span>Покупатели пока не оценили компанию</span>
              </Layout>
            </Layout>
          </Layout>
        </Modal> */}
        <Modal_companyPage
          openModalCompany={openModalCompany}
          setOpenModalCompany={setOpenModalCompany}
          fetchCompanyModal={fetchSellerCompany}
        />
      </Layout>
      <Layout className={p.common_company_formInfo}>
        <span className="fontSiz_16_400Blc">
          Страница о Вашей компании доступна для поиска и просмотра всем
          посетителям маркетплейса. Данные платформа взяла с Вашего сайта. Вы
          можете редактировать информацию по Вашему усмотрению.
        </span>
        <span className="fontSiz_16_500">Логотип</span>
        <img src={fileIMG} className={p.common_company_imgLogo} />
        <label className={p.common_company_labelFile}>
          <span className="fontSiz_16_400Blc">Загрузить другой</span>
          <input type="file" onChange={handleChange} />
        </label>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Название компании</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="Название компании"
            allowClear
            type="text"
            name="companyname"
            value={fetchSellerCompany?.company_name}
            onChange={(e) =>
              setFetcSellerCompany({
                ...fetchSellerCompany,
                company_name: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Описание</span>
          <TextArea
            className={p.common_chaild_inputArea}
            placeholder="Описание"
            value={fetchSellerCompany?.company_description}
            onChange={(e) =>
              setFetcSellerCompany({
                ...fetchSellerCompany,
                company_description: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Юридический адрес</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="Юридический адрес"
            allowClear
            type="text"
            name="urAdress"
            value={fetchSellerCompany?.company_address_legal}
            onChange={(e) =>
              setFetcSellerCompany({
                ...fetchSellerCompany,
                company_address_legal: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Фактический адрес</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="Фактический адрес"
            allowClear
            type="text"
            name="facAdress"
            value={fetchSellerCompany?.company_address_actual}
            onChange={(e) =>
              setFetcSellerCompany({
                ...fetchSellerCompany,
                company_address_actual: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">ОГРН</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="ОГРН"
            allowClear
            type="text"
            name="ogrn"
            value={fetchSellerCompany?.company_ogrn}
            onChange={(e) =>
              setFetcSellerCompany({
                ...fetchSellerCompany,
                company_ogrn: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">КПП</span>
          <Input
            className={p.common_chaild_Input}
            placeholder="КПП"
            allowClear
            type="text"
            name="kpp"
            value={fetchSellerCompany?.company_kpp}
            onChange={(e) =>
              setFetcSellerCompany({
                ...fetchSellerCompany,
                company_kpp: e.target.value,
              })
            }
          />
        </Layout>
        <Layout className={p.common_chaild_divCheakBox}>
          <input
            type="checkbox"
            name="radio"
            id="rememb"
            value={fetchSellerCompany?.company_isReady_create_orders}
            onChange={(e) =>
              setFetcSellerCompany({
                ...fetchSellerCompany,
                company_isReady_create_orders: e.target.value,
              })
            }
          />
          <label
            htmlFor="rememb"
            style={{ paddingLeft: '8px', color: '#000' }}
            className="fontSiz_16_400Blc"
          >
            Готовы принимать заказы на изготовления инструмента и оснастки
          </label>
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">
            Категории, которые Вы можете изготавливать
          </span>

          <Select
            placeholder={'Категории'}
            // defaultValue="Категории"
            style={{
              width: '100%',
              height: 40,
            }}
            className="selectCompany"
            // onChange={handleChange}
            options={fetchSellerCompany?.company_categories?.map((val, i) => {
              return { value: i, label: val }
            })}
          />
        </Layout>
        <Layout className={p.common_chaild_divCheakBox}>
          <input
            type="checkbox"
            name="radio"
            id="rememb"
            value={fetchSellerCompany?.company_isReady_uslugi}
            onChange={(e) =>
              setFetcSellerCompany({
                ...fetchSellerCompany,
                company_isReady_uslugi: e.target.value,
              })
            }
          />
          <label
            htmlFor="rememb"
            style={{ paddingLeft: '8px', color: '#000' }}
            className="fontSiz_16_400Blc"
          >
            Готовы оказывать услуги по ремонту инструмента и оснастки
          </label>
        </Layout>
        <Button className={p.common_company_btnSave}>Сохранить</Button>
      </Layout>
    </Layout>
  )
}

export default Company_page
