import React, { useState } from 'react'
import p from '../../../../Web/profile/profile.module.scss'
import { useSelector } from 'react-redux'

import { Button, Input, Radio, Layout } from 'antd'

import Kanban_Distributor from './Kanban_Distributor/Kanban_Distributor'
import List_distributor from './List_distributor/List_distributor'
import Archive_Distributor from './Archive_Distributor/Archive_Distributor'

const Transactions_Distributor = () => {
  const { Search } = Input
  const { cardTransaction } = useSelector(
    ({ newTransaction }) => newTransaction
  )
  const [choiceList, setChoosing] = useState('Kanban')
  console.log(cardTransaction)
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_staff_topInfo}>
        <span className="fontSiz_24_500">Сделки</span>
        <Radio.Group
          className="radioGrpBtn"
          defaultValue="Kanban"
          buttonStyle="solid"
          onChange={(e) => setChoosing(e.target.value)}
        >
          <Radio.Button className="radBtn" value="Kanban">
            Канбан
          </Radio.Button>
          <Radio.Button className="radBtn" value="List">
            Список
          </Radio.Button>
          <Radio.Button className="radBtn" value="Archive">
            Архив
          </Radio.Button>
        </Radio.Group>
        {/* <Search
          placeholder="Поиск"
          className={`${p.searchHead} searchHeadG`}
          //   prefix={<UserOutlined />}
        /> */}
      </Layout>
      <Layout className={p.common_wrapper}>
        {choiceList === 'Kanban' && (
          <Kanban_Distributor cardTransaction={cardTransaction} />
        )}
        {choiceList === 'List' && <List_distributor />}
        {choiceList === 'Archive' && <Archive_Distributor />}
      </Layout>
    </Layout>
  )
}

export default Transactions_Distributor
