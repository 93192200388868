import React, {useRef, useState, useEffect, useCallback} from 'react';
import {LoadStep} from './StepLoader';
import {Canvas, useThree, useLoader} from '@react-three/fiber';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {useGLTF} from '@react-three/drei';
import Size from "../3D/Scene/Size";


function GlbLoader({url, ...props}) {
    const model = useGLTF(url ?? "");
    console.log(model)
    return (
        <group name='ModelGLB' {...props}>
            <primitive object={model.scene}/>
            <Size obj={model.scene}/>
        </group>
    );
}

export default GlbLoader