

export const AuthService = {
    login: async (username, password, reload) => {
        try {
            const response = await fetch('http://localhost/portal/login/auth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "username": username,
                    "password": password,
                })
            })
            if (response.status === 200 || response.status === 202) {
                const data = await response.json()
                AuthService.setRoles(data)
                try {
                    const response = await fetch('http://localhost/portal/login', {
                        method: 'POST',
                        mode: 'no-cors',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        credentials: 'include',
                        body: new URLSearchParams({
                            "username": username,
                            "password": password,
                        })
                    })
                    if (reload === true) window.location.reload()
                    return true;
                } catch (e) {
                    console.log(e)
                }
            }
        } catch (e) {
            console.error(e)
            return false;
        }
    },
    logout: async()=>{
        try {
            const response = await fetch('http://localhost/portal/perform_logout', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                credentials: 'include',
            })
            AuthService.clearRoles()
            window.location.reload()
        } catch (e) {
            console.log(e)
        }
    },
    checkRoles: () => {
        return !!sessionStorage.getItem('roles')
    },
    getRoles: () => {
        return sessionStorage.getItem('roles')
    },
    setRoles: (dto) => {
        sessionStorage.setItem('roles', JSON.stringify(dto))
        return AuthService.checkRoles()
    },
    clearRoles: () => {
        sessionStorage.removeItem('roles')
        return AuthService.checkRoles()
    },
}