import React from 'react'
import ctc from '../common_TngCustomer.module.scss'
import { Avatar, Badge, Layout } from 'antd'
import { BellOutlined } from '@ant-design/icons'

const Header_TngCustomer = () => {
  return (
    <Layout className={ctc.headerMainDiv}>
      <span style={{ color: 'white' }}>LOGO</span>
      <Layout className={ctc.header__grupAvatar}>
        <Badge count={11}>
          <BellOutlined
            style={{ fontSize: '18px', color: 'white', paddingRight: '12px' }}
          />
        </Badge>
        <Avatar
          size={24}
          src={<img src={require('../../../image/Setting.jpg')} alt="avatar" />}
          style={{ background: 'white' }}
        />
        <Layout className={ctc.header__secondGrup}>
          <span></span>
          <span></span>
          <span></span>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Header_TngCustomer
