import React from 'react';
import {
    Breadcrumb,
    Button,
    Checkbox,
    ConfigProvider,
    Dropdown,
    Flex,
    Input,
    Layout,
    Select,
    Space,
    Tooltip
} from "antd";
import {THEME_TEST} from "../../Utils/Themes";
import {DownOutlined} from "@ant-design/icons";
import {ArrowRight} from "lucide-react";
import ruRU from 'antd/locale/ru_RU'
import Search from "antd/lib/input/Search";

const ColorPalettePage = () => {
    const dropdownItems = [
        {
            label: <a href="https://www.antgroup.com">1st menu item</a>,
            key: '0',
        },
        {
            label: <a href="https://www.aliyun.com">2nd menu item</a>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
    ]

    return (
        <ConfigProvider locale={ruRU} theme={THEME_TEST} wave={{disabled: true}}>
            <Flex vertical gap={'large'} justify={'center'} align={'center'} style={{maxWidth: '100vw', padding: '1em'}}>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>Buttons</h1>
                    <Button type={'primary'}>Button Primary</Button>
                    <Button>Button Secondary (default)</Button>
                    <Button ghost>Button Third (ghost)</Button>
                    <Button type={'link'}>Button Link</Button>

                    <Button type={'primary'} disabled>Button Primary</Button>
                    <Button disabled>Button Secondary (default)</Button>
                    <Button ghost disabled>Button Third (ghost)</Button>
                    <Button type={'link'} disabled>Button Link</Button>
                </Flex>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>Dropdown & Select</h1>
                    <Select
                        items={[
                            {
                                value: 'test 1',
                                label: 'test 1'
                            },
                            {
                                value: 'test 2',
                                label: 'test 2'
                            },
                        ]}
                    />
                    {/*<Dropdown*/}
                    {/*    menu={{*/}
                    {/*        dropdownItems,*/}
                    {/*    }}*/}
                    {/*    trigger={['click']}*/}
                    {/*>*/}
                    {/*    <a onClick={(e) => e.preventDefault()}>*/}
                    {/*        <Space>*/}
                    {/*            Click me*/}
                    {/*            <DownOutlined />*/}
                    {/*        </Space>*/}
                    {/*    </a>*/}
                    {/*</Dropdown>*/}
                </Flex>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>Breadcrumbs</h1>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: <a href="">Application Center</a>,
                            },
                            {
                                title: <a href="">Application List</a>,
                            },
                            {
                                title: 'An Application',
                            },
                        ]}
                    />
                </Flex>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>ButtonArrow</h1>
                    <Button style={{width: 44}} shape={'circle'}><ArrowRight/></Button>
                </Flex>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>Checkbox</h1>
                    <Checkbox />
                </Flex>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>Tab</h1>
                </Flex>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>Input</h1>
                    <Input />
                </Flex>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>Search</h1>
                    <Search />
                </Flex>
                <Flex vertical gap={'small'} style={{width: '512px'}}>
                    <h1>Tooltip</h1>
                    <Tooltip>
                        tooltip example
                    </Tooltip>
                </Flex>
            </Flex>
        </ConfigProvider>
    )
}

export default ColorPalettePage