import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    TOGGLE_CATALOG_DISPLAY_TYPE_GRID: true, // grid || inline
}

const otherVariablesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_CATALOG_DISPLAY_TYPE_GRID':
            return { ...state, TOGGLE_CATALOG_DISPLAY_TYPE_GRID: action.payload }
        default:
            return state
    }
}

export default otherVariablesReducer