import React, { useState } from 'react'
import { Layout, Table, Button, Select, Input } from 'antd'
import p from '../../../../../../Web/profile/profile.module.scss'
const DrawerTransactions = ({
  cardTransaction,
  idPrimaryContact,
  selectDrawer,
}) => {
  const { TextArea } = Input
  // console.log(selectDrawer)
  const [dataList, setDataList] = useState([
    {
      key: '1',
      position: 'Сверло ',
      article: '01292000',
      requiredQuantity: '1000 шт',
      availability: 'В наличии 200 шт',
      cost: '12 000 руб',
      deliveryTime: '30.08.2024',
    },
    {
      key: '2',
      position: 'Сверло ',
      article: '01292000',
      requiredQuantity: '1000 шт',
      availability: 'Только под заказ',
      cost: '12 000 руб',
      deliveryTime: '30.08.2024',
    },
  ])
  const columns = [
    {
      title: 'Позиция в каталоге',
      dataIndex: 'position',
    },
    {
      title: 'Артикул',
      dataIndex: 'article',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Требуемое кол-во',
      dataIndex: 'requiredQuantity',
      render: (text) => <span className={p.common_tableDrawer}>{text}</span>,
    },

    {
      title: 'Наличие',
      dataIndex: 'availability',
    },
    {
      title: 'Стоимость',
      dataIndex: 'cost',
    },
    {
      title: 'Сроки поставки',
      dataIndex: 'deliveryTime',
    },
  ]
  useState(() => {
    console.log(idPrimaryContact)
  }, [idPrimaryContact])
  return (
    <Layout>
      {cardTransaction
        .filter((el) => el.id === idPrimaryContact.idPrimary)
        .map((item, i) => (
          <Layout key={i} className={p.common_Drawer_main}>
            {selectDrawer === 'Создать' && (
              <>
                <Layout>
                  <span
                    className="fontSiz_24_500"
                    style={{ paddingBottom: '8px' }}
                  >
                    Заказ от{' '}
                    <span style={{ color: 'rgba(141, 36, 248, 1)' }}>
                      EXPO ZONE
                    </span>
                  </span>
                  <span className="fontSiz_14_400">{item.data}</span>
                </Layout>
                <Layout>
                  <span
                    className="fontSiz_14_500"
                    style={{ paddingBottom: '8px' }}
                  >
                    Контактное лицо
                  </span>
                  <span className="fontSiz_14_400">{item.user}</span>
                  <span
                    className="fontSiz_14_500"
                    style={{ color: 'rgba(141, 36, 248, 1)' }}
                  >
                    {item.telephone}
                  </span>
                </Layout>
              </>
            )}
            {selectDrawer === 'Offer' && (
              <Layout style={{ rowGap: '24px' }}>
                <Layout>
                  <span
                    className="fontSiz_24_500"
                    style={{ paddingBottom: '8px' }}
                  >
                    Создать предложение
                  </span>
                  <span className="fontSiz_14_400">{item.data}</span>
                </Layout>
                <Layout className={p.common_SelectDrawer_offer}>
                  <label className={p.common_Label_Offer}>
                    Контактное лицо
                    <Select className={p.common_Select_Offer}></Select>
                  </label>
                  <label className={p.common_Label_Offer}>
                    Организация
                    <Select className={p.common_Select_Offer}></Select>
                  </label>
                  <label className={p.common_Label_Offer}>
                    Ответственное лицо
                    <Select className={p.common_Select_Offer}></Select>
                  </label>
                  <label className={p.common_Label_Offer}>
                    Почта получателя
                    <Select className={p.common_Select_Offer}></Select>
                  </label>
                </Layout>
              </Layout>
            )}
            <Table
              className={p.common_table}
              rowSelection={{
                type: 'checkbox',
              }}
              columns={columns}
              dataSource={dataList}
              pagination={false}
            />
            {selectDrawer === 'Offer' && (
              <Layout style={{ rowGap: '24px' }}>
                <span>Итого 36 000 руб</span>
                <Layout>
                  <span style={{ paddingBottom: '8px' }}>Текстовая часть</span>
                  <TextArea rows={4} />
                </Layout>
              </Layout>
            )}
          </Layout>
        ))}
    </Layout>
  )
}

export default DrawerTransactions
