import React, {useEffect, useState} from 'react'

import {Link} from 'react-router-dom'
import {Flex, Table} from 'antd'
import ga from "../../../Web/general_admin.module.scss";
import Search from "antd/lib/input/Search";

const Products_admin = () => {
    // const navigate = useNavigate()
    // const {Search} = Input
    // const [dataProductsAd, setDataProductsAd] = useState([
    //     {
    //         key: '1',
    //         photo: <img src={require('../../../image/Setting.jpg')} alt="avatar"/>,
    //         article: '293103899',
    //         manufacturer: 'ГК Стройтех',
    //         tk: 'Есть',
    //         status: 'В наличии',
    //         name: 'Сверло',
    //         availability3D: 'Есть',
    //         dateAddition: '27.08.2024',
    //         pcs: 1000,
    //     },
    //     {
    //         key: '2',
    //         photo: <img src={require('../../../image/Setting.jpg')} alt="avatar"/>,
    //         article: '293103899',
    //         manufacturer: 'ГК Стройтех',
    //         tk: 'Есть',
    //         status: 'Под заказ',
    //         name: 'Сверло',
    //         availability3D: 'Нет',
    //         dateAddition: '27.08.2024',
    //         pcs: 0,
    //     },
    //     {
    //         key: '3',
    //         photo: <img src={require('../../../image/Setting.jpg')} alt="avatar"/>,
    //         article: '293103899',
    //         manufacturer: 'ГК Стройтех',
    //         tk: 'Есть',
    //         status: 'Снят с продажи',
    //         name: 'Сверло',
    //         availability3D: 'Нет',
    //         dateAddition: '27.08.2024',
    //         pcs: 0,
    //     },
    // ])
    // const [newDataProductsAd, setNewDataProductsAd] = useState({
    //     key: dataProductsAd.length + 1,
    //     photo: '',
    //     article: '',
    //     manufacturer: '',
    //     tk: '',
    //     status: '',
    //     name: '',
    //     availability3D: '',
    //     dateAddition: '',
    //     pcs: 0,
    // })
    // const columnsProducts = [
    //     {
    //         title: '',
    //         dataIndex: 'red',
    //         render: (text) => (
    //             <Button type="text" onClick={() => navigate('editingProductAdmin')}>
    //                 <EdditSVG/>
    //             </Button>
    //         ),
    //         width: '5%',
    //     },
    //     {
    //         title: 'Фото',
    //         dataIndex: 'photo',
    //         render: () => <span className={ca.tableCir}></span>,
    //     },
    //     {
    //         title: 'Артикул',
    //         dataIndex: 'article',
    //     },
    //     {
    //         title: 'Производитель',
    //         dataIndex: 'manufacturer',
    //     },
    //     {
    //         title: 'ТК',
    //         dataIndex: 'tk',
    //     },
    //     {
    //         title: 'Статус',
    //         dataIndex: 'status',
    //         render: (text) => {
    //             let textColor =
    //                 text.length > 13 ? 'rgba(250, 250, 251, 1)' : 'rgba(115, 209, 61, 1)'
    //
    //             if (text === 'Под заказ') {
    //                 textColor = 'rgba(255, 153, 0, 1)'
    //             }
    //             return <span style={{color: textColor}}>{text}</span>
    //         },
    //     },
    //     {
    //         title: 'Наименование',
    //         dataIndex: 'name',
    //     },
    //     {
    //         title: 'Наличие 3D',
    //         dataIndex: 'availability3D',
    //         render: (text) => {
    //             let textColor =
    //                 text === 'Есть' ? 'rgba(115, 209, 61, 1)' : 'rgba(242, 78, 30, 1)'
    //             return <span style={{color: textColor}}>{text}</span>
    //         },
    //     },
    //     {
    //         title: 'Дата добавления',
    //         dataIndex: 'dateAddition',
    //     },
    //     {
    //         title: 'Наличие, шт',
    //         dataIndex: 'pcs',
    //         render: (text) => {
    //             let textColor =
    //                 text > 0 ? 'rgba(115, 209, 61, 1)' : 'rgba(242, 78, 30, 1)'
    //             return <span style={{color: textColor}}>{`${text} шт.`}</span>
    //         },
    //     },
    // ]
    // return (
    //     <Layout className={ca.common_MainDiv}>
    //         <Layout className={ca.common_InputGrup}>
    //             <Search
    //                 suffix
    //                 placeholder="input search text"
    //                 style={{
    //                     width: 200,
    //                 }}
    //             />
    //         </Layout>
    //         <Layout className={ca.common_scrollWrapper}>
    //             <Table
    //                 columns={columnsProducts}
    //                 dataSource={dataProductsAd}
    //                 className={gca.tableGeneral}
    //                 scroll={{x: 'max-content'}}
    //             />
    //         </Layout>
    //     </Layout>
    // )

    const [tableData, setTableData] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/admin/draft/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error('Ошибка')
                } else return res
            })
            .then(res => res.json())
            .then(res => {
                setTableData(res.filter(f => f.status === 'approved'))
            })
    }, [])

    const columns = [
        {
            key: 0,
            title: 'Идентификатор',
            dataIndex: 'id',
            render: (id) => (
                <Link to={`view/${id}`}>{id}</Link>
            )
        },
        {
            key: 1,
            title: 'Пользователь',
            dataIndex: 'username'
        },
        {
            key: 2,
            title: 'Дата создания',
            dataIndex: 'created'
        },
        {
            key: 3,
            title: 'Дата обновления',
            dataIndex: 'updated'
        },
    ]

    return (
        <Flex className={ga.container}>
            <Search className={ga.search} />
            <Flex>
                <Table
                    className={ga.Table}
                    columns={columns}
                    dataSource={tableData}
                />
            </Flex>
        </Flex>
    )
}

export default Products_admin
