import React, {useState} from 'react'
// import ca from '../../common__Admin.module.scss'
import ca from '../../common__Admin.module.scss'
import {ArrowLeftOutlined, InboxOutlined} from '@ant-design/icons'
import {Button, Input, Layout, Radio, Upload,} from 'antd'

import {useNavigate} from 'react-router'
import {Asterisk} from 'lucide-react'

const EditingProduct_admin = () => {
  const { TextArea } = Input
  const { Dragger } = Upload
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [value, setValue] = useState(1)
  const onChangeRadioEditingProduct = (e) => {
    console.log('radio checked', e.target.value)
    setValue(e.target.value)
  }
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
  }
  //   const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const props = {
    name: 'file',
    // multiple: false,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    listType: 'picture-circle',
    // onChange(info) {
    //   const { status } = info.file
    //   if (status !== 'uploading') {
    //     console.log(info.file, info.fileList)
    //   }
    //   if (status === 'done') {
    //     message.success(`${info.file.name} file uploaded successfully.`)
    //   } else if (status === 'error') {
    //     message.error(`${info.file.name} file upload failed.`)
    //   }
    // },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }
  const navigate = useNavigate()
  return (
    <Layout style={{ rowGap: '24px' }}>
      <Layout className={ca.common_Div}>
        <Layout className={ca.common_Revers}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            className={ca.common_ReverseNavigation}
            onClick={() => navigate(-1)}
          >
            Назад
          </Button>
          <span className="fontSiz_24_500">Редактирование карточки товара</span>
        </Layout>
        <Layout className={ca.common_MainContent}>
          <Layout className={ca.common_FormLeft}>
            <Layout>
              <label className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Наименование</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>

              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Наименование"
                //   value={'ГК Инструмент'}
                required
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Категория</span>
              </label>

              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Категория"
                //   value={'ГК Инструмент'}
                required
              />
            </Layout>
            <Layout>
              <label className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Артикул</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>

              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Артикул"
                //   value={'ГК Инструмент'}
                required
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Описание</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <TextArea
                showCount
                style={{
                  height: 100,
                  resize: 'none',
                }}
              />
            </Layout>
            <Layout>
              <label className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Материал </span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Материал "
                //   value={'ГК Инструмент'}
                required
              />
            </Layout>
            <Layout>
              <label className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Характеристика </span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Характеристика "
                //   value={'ГК Инструмент'}
                required
              />
            </Layout>
            <Layout>
              <label className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Цена, ₽ </span>
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Цена, ₽ "
                //   value={'ГК Инструмент'}
                required
              />
            </Layout>

            <Layout>
              <label className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">
                  НДС (налог на добавочную стоимость){' '}
                </span>
              </label>
              <Radio.Group
                onChange={onChangeRadioEditingProduct}
                value={value}
                // style={{ width: 'auto' }}
              >
                <Radio value={1}>10%</Radio>
                <Radio value={2}>20%</Radio>
                <Radio value={3}>НДС не облагается</Radio>
              </Radio.Group>
            </Layout>
            <Layout className={ca.common_uploadBlock}>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Изображения</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Dragger
                className={ca.dragger}
                {...props}
                onPreview={handlePreview}
                //   onChange={handleChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Перетащите или выберите файлы в эту область
                </p>
                <p className="ant-upload-hint">
                  Формат — JPEG, PNG, WEBP. Размер не более 5 МБ.
                </p>
              </Dragger>
            </Layout>
            <Layout className={ca.common_uploadBlock}>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Лицензия</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>

              <Dragger
                className={ca.dragger}
                {...props}
                onPreview={handlePreview}
                //   onChange={handleChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Перетащите или выберите файлы в эту область
                </p>
                <p className="ant-upload-hint">
                  Формат — JPEG, PNG, WEBP. Размер не более 5 МБ.
                </p>
              </Dragger>
            </Layout>
            <Layout className={ca.common_uploadBlock}>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">2D-чертеж</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>

              <Dragger
                className={ca.dragger}
                {...props}
                onPreview={handlePreview}
                //   onChange={handleChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Перетащите или выберите файлы в эту область
                </p>
                <p className="ant-upload-hint">
                  Формат — Загружаемый чертеж должен быть выполнен в формате
                  «.DXF»; Размер чертежа на должен превышать 100 МБ.
                </p>
              </Dragger>
            </Layout>
            <Layout className={ca.common_uploadBlock}>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">3D-модель</span>
              </label>

              <Dragger
                className={ca.dragger}
                {...props}
                onPreview={handlePreview}
                //   onChange={handleChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Перетащите или выберите файлы в эту область
                </p>
                <p className="ant-upload-hint">
                  Формат — модель должна быть выполнена в формате «.STEP»,
                  «.PRT»; Размер 3D-модели не должен превышать 200 МБ.
                </p>
              </Dragger>
            </Layout>
          </Layout>
          {/* <Layout className={ca.common_DeledBtn}></Layout> */}
        </Layout>
      </Layout>
      <Layout className={ca.common_BtnSave}>
        <Button style={{ width: '100%' }}>Сохранить </Button>
        <Button style={{ width: '100%' }}>Отменить изменения</Button>
      </Layout>
    </Layout>
  )
}

export default EditingProduct_admin
