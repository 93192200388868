import React, { useState, useEffect } from 'react'
import p from '../../../Web/profile/profile.module.scss'
//! ANTD
import { Button, Input, Modal, Layout } from 'antd'
import { Asterisk } from 'lucide-react'

import useFetch_profile from '../../for_Seller_Prof/hooks/useFetch_profile'
const Account_Distributor = ({
  fetchDistributorAccount,
  setFetcDistributorAccount,
}) => {
  const distributorAccountResponse = useFetch_profile()
  const [isModalDeled, setIsModalDeled] = useState(false)
  const [isModalChangePass, setIsModalChangePass] = useState(false)
  const showModal = () => {
    setIsModalDeled(true)
  }
  const showModalChangePass = () => {
    setIsModalChangePass(true)
  }
  const handleOk = () => {
    setIsModalDeled(false)
  }
  const handleChangePass = () => {
    setIsModalChangePass(false)
  }
  const handleCancel = () => {
    setIsModalDeled(false)
  }
  const handleCancelPss = () => {
    setIsModalChangePass(false)
  }
  useEffect(() => {
    distributorAccountResponse.get(
      `${process.env.REACT_APP_DOMAIN_BFF}/trade/profile`,
      setFetcDistributorAccount
    )
  }, [])
  console.log(fetchDistributorAccount, 'acc distributer')
  return (
    <Layout className={p.mainDiv}>
      <Modal
        width={'80%'}
        style={{ padding: '20px' }}
        className="modalDeled"
        // title="Удаление аккаунта"
        open={isModalDeled}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Layout>
          <Layout
            className="fontSiz_32_500"
            style={{ textAlign: 'center', width: '100%' }}
          >
            Удаление аккаунта
          </Layout>
          <Layout className={p.common_modal_delet}>
            <p className="fontSiz_16_400Blc">
              Уважаемые производители! Для удаления аккаунта отправьте заявку. В
              течение двух рабочих дней Вам придет уведомление о статусе заявки
              на электронную почту.
            </p>
          </Layout>
          <Button
            type="primary"
            className={p.common_btnDelPost}
            onClick={handleOk}
          >
            Отправить заявку
          </Button>
        </Layout>
      </Modal>
      <Modal
        width={'80%'}
        style={{ padding: '20px' }}
        className="modalDeled"
        // title="Удаление аккаунта"
        open={isModalChangePass}
        // onOk={handleOk}
        onCancel={handleCancelPss}
        footer={null}
      >
        <Layout className={p.common_modal_form}>
          <Layout className={p.common_modal_column}>
            <Layout
              className="fontSiz_32_500"
              style={{ textAlign: 'center', width: '100%' }}
            >
              Изменение пароля
            </Layout>
            <Layout className={p.common_modal_changePassForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Старый пароль
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="pass"
                  name="username"
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Новый пароль
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="password"
                  name="username"
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Подтверждение пароля
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="text"
                  name="username"
                  required
                />
              </Layout>
            </Layout>
            <Button
              type="primary"
              className={p.common_modal_column_btnDelPost}
              style={{ width: '100%' }}
              onClick={handleChangePass}
            >
              Отправить заявку
            </Button>
          </Layout>
        </Layout>
      </Modal>
      <span className="fontSiz_24_500">Данные о компании</span>
      <Layout className={p.common_DivAccount}>
        <Layout className={p.common_DivAcc_left}>
          <Layout className={p.common_left_divForm}>
            <Layout className={p.common_chaild_divForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  E-mail <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="E-mail"
                  type="text"
                  name="E-mail"
                  required
                  value={fetchDistributorAccount?.firstName}
                  onChange={(e) =>
                    setFetcDistributorAccount({
                      ...fetchDistributorAccount,
                      firstName: e.target.value,
                    })
                  }
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Страна <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Страна"
                  type="text"
                  name="Страна"
                  value={fetchDistributorAccount?.job}
                  onChange={(e) =>
                    setFetcDistributorAccount({
                      ...fetchDistributorAccount,
                      job: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  ИНН <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="ИНН"
                  type="text"
                  name="ИНН"
                  value={fetchDistributorAccount?.middleName}
                  onChange={(e) =>
                    setFetcDistributorAccount({
                      ...fetchDistributorAccount,
                      middleName: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
            </Layout>
            <Layout className={p.common_chaild_divForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Сайт
                  <Asterisk color="red" style={{ width: '16px', opacity: 0 }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Сайт"
                  type="text"
                  name="Сайт"
                  value={fetchDistributorAccount?.id}
                  onChange={(e) =>
                    setFetcDistributorAccount({
                      ...fetchDistributorAccount,
                      id: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  ФИО <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="ФИО"
                  type="text"
                  name="ФИО"
                  value={fetchDistributorAccount?.secondName}
                  onChange={(e) =>
                    setFetcDistributorAccount({
                      ...fetchDistributorAccount,
                      secondName: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Телефон <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Телефон"
                  type="text"
                  name="Телефон"
                  value={fetchDistributorAccount?.username}
                  onChange={(e) =>
                    setFetcDistributorAccount({
                      ...fetchDistributorAccount,
                      username: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
            </Layout>
          </Layout>
          <Button className={p.common_DivAccount_saveBTN}>Сохранить</Button>
        </Layout>
        <Layout className={p.common_DivAcc_right}>
          <span className="fontSiz_16_400Blc">Управление аккаунтом</span>
          <span className="fontSiz_16_400Blc">
            Вы с нами с 21 Марта 2024 года
          </span>
          <span
            onClick={showModalChangePass}
            className="fontSiz_16_400Blc"
            style={{ color: 'rgb(141, 36, 248)' }}
            type="text"
          >
            Изменить пароль
          </span>
          <span
            className="fontSiz_16_400Blc"
            style={{ color: 'rgb(141, 36, 248)' }}
            type="text"
            onClick={() => localStorage.clear()}
          >
            Выйти из аккаунта
          </span>
          <span
            onClick={showModal}
            className="fontSiz_16_400Blc"
            style={{ color: 'rgb(141, 36, 248)' }}
            type="text"
          >
            Удалить аккаунт
          </span>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Account_Distributor
