import React from 'react';
import {Button, Drawer, Layout} from "antd";
import d from '../ItemDrawers.module.scss'
import {useTheme} from "antd-style";
import closeButton from "../../../image/closeButton.svg";

const DrawerItemSellers = ({callback_open, callback_cancel, tempPrice}) => {
    const token = useTheme();
    const drawerStyles = {
        content: {
            borderRadius: '12px 0 0 12px',
            backgroundColor: '#F0F3F5',
        },
    };

    return (
        <Drawer
            className={d.drawerBlock}
            width={656}
            title={'Предложения торговых компаний'}
            onClose={callback_cancel}
            open={callback_open}
            styles={drawerStyles}
        >
            <Layout className={d.container}>
                <Layout className={d.infoBlock}>
                    <Layout className={d.infoBlockItem}>
                        <span className={d.infoBlockItemHeader}>Предложения торговых компаний</span>
                    </Layout>
                    <Layout className={d.infoBlockItem}>
                        <Layout className={`${d.infoBlockItemBody}`}>
                            {Array(5).fill(null).map((_, i) => {
                                return (
                                    <Layout key={i} className={d.infoBlockItemBodySeller}>
                                        <Layout className={d.infoBlockItemBodySellerHead}>
                                            <span className={d.sellerPrice}>{tempPrice?.value} {tempPrice?.currency}</span>
                                            <Button className={d.sellerBracketButton} size={'small'}>В корзину</Button>
                                        </Layout>
                                        <Layout className={d.infoBlockItemBodySellerBody}>
                                            <Layout style={{flexDirection: 'row', gap: '8px', width: '100%'}}>
                                                <span className={d.sellerTitle}>Мастер-эксперт</span>
                                                <span className={d.sellerRate}>5 </span>
                                            </Layout>
                                            <span className={d.sellerDelivery}>Доставка: от 14 дней, от 3917 Р</span>
                                            <span className={d.sellerAddress}>ТК Корунд, Пенза</span>
                                        </Layout>
                                    </Layout>
                                )
                            })}
                        </Layout>
                    </Layout>
                </Layout>
                <Layout className={d.buttonBlock}>
                    <Button type={'link'} className={d.closeButton} onClick={() => {callback_cancel()}}>
                        <img src={closeButton}/>
                    </Button>
                </Layout>
            </Layout>
        </Drawer>
    )
}

export default DrawerItemSellers;