import React, { useState } from 'react'
import tc from './GeneralComponent_TngCustomer.module.scss'
//! ANTD
import { Button, Menu, ConfigProvider, Layout } from 'antd'
import { Asterisk, LockKeyhole, User } from 'lucide-react'
import { Link, Outlet } from 'react-router-dom'

import Header_TngCustomer from '../../components/for_TngCustomer/Header_TngCustomer/Header_TngCustomer'
import Version_KIP from '../../components/for_TngCustomer/Version_KIP/Version_KIP'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
const GeneralComponent_TngCustomer = () => {
  const [current, setCurrent] = useState('Version_KIP')
  const onClick = (e) => {
    console.log('click ', e.key)
    setCurrent(e.key)
  }
  const items = [
    {
      label: 'Актуальная версия КИП',
      key: 'Version_KIP',
      // icon: <MailOutlined />,
    },
    {
      label: 'Статус проекта изделия',
      key: 'companyPage_Distributor',
    },
  ]
  return (
    <Layout className={tc.common_mainDiv}>
      <Header_TngCustomer />

      <Layout className={tc.common_mainWrapper}>
        <ConfigProvider
          theme={{
            token: {
              // HorizontalItemHoverColor: 'red',
              // colorText: 'rgb(123, 124, 125)',
            },
            components: {
              Menu: {
                colorBgElevated: 'rgb(255, 255, 255)',
                itemSelectedColor: 'rgb(141, 36, 248)',
                itemHoverColor: 'rgb(141, 36, 248)',
                itemColor: 'rgb(123, 124, 125)',
                horizontalItemSelectedColor: 'rgb(141, 36, 248)',

                // itemActiveBg: 'red',
                // itemSelectedBg: 'rgb(255, 255, 255)',
              },
              Layout: {
                bodyBg: 'white',
              },
              Input: {
                // activeBg: 'rgba(141, 36, 248, 0.2);',
                activeBorderColor: 'rgba(141, 36, 248, 1)',
                // activeShadow: 'red',
                hoverBorderColor: 'rgba(141, 36, 248, 1)',
                colorBorder: 'rgba(123, 124, 125, 1)',
                colorFillSecondary: 'red',
                borderRadius: 12,
                // inputFontSize: 30,
              },
              Table: {
                borderColor: 'none',
                headerBg: 'rgba(240, 243, 245, 1)',
                colorBgContainer: 'rgba(250, 250, 251, 1)',
                // borderRadius: 12,
                headerBorderRadius: 0,
              },
              Select: {
                colorBorder: 'rgba(123, 124, 125, 1)',
                colorPrimary: 'rgba(141, 36, 248, 1)',
                colorPrimaryHover: 'rgba(141, 36, 248, 1)',
                optionActiveBg: '#e8d3fe',
                // optionSelectedColor: '#8D24F8',
                optionSelectedBg: '#eee0fda8',
                borderRadius: 12,
              },
              Checkbox: {
                colorPrimary: 'rgba(141, 36, 248, 1)',
                colorPrimaryHover: 'rgba(141, 36, 248, 1)',
              },
              Radio: {
                // colorBgContainer: 'red',
                colorPrimary: 'rgba(141, 36, 248, 1)',
              },
            },
          }}
        >
          <Layout className={tc.common_menuButton}>
            <Menu
              className={tc.mainTabs}
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
            <Button icon={<PlusOutlined />}>Загрузить проект изделия</Button>
          </Layout>
          <Layout className={tc.mainChaild}>
            {current === 'Version_KIP' && <Version_KIP />}
          </Layout>
        </ConfigProvider>
      </Layout>
    </Layout>
  )
}

export default GeneralComponent_TngCustomer
