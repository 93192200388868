import React from 'react'
import {Link} from 'react-router-dom'
import {Breadcrumb} from "antd";

const DynamicCatalogBreadcrumb = ({match}) => {
    switch (match.pattern.path) {
        case "/catalog/:catalogId":
            return `${match.params.catalogId.split('+')[0]}`;
        case "/catalog/:catalogId/content":
            return `${match.params.catalogId.split('+')[1]}`;
        case "/catalog/:catalogId/content/:itemId":
            return `${match.params.itemId}`
        case "/catalog/product/:itemId":
            return ""
        default:
            break;
    }
    return `${match.params.catalogId}`
}

const routes = [
    {path: '/', breadcrumb: 'Главная'},
    {path: '/catalog', breadcrumb: null},
    {path: '/catalog/:catalogId', breadcrumb: DynamicCatalogBreadcrumb},
    {path: '/catalog/:catalogId/content', breadcrumb: DynamicCatalogBreadcrumb},
    {path: '/catalog/:catalogId/content/:itemId', breadcrumb: DynamicCatalogBreadcrumb},
    {path: '/manufacturers', breadcrumb: "Производители"},
    {path: '/visitors', breadcrumb: "Посетители"},
    {path: '/requestProduction', breadcrumb: "Запросы на изготовление"},
    {path: '/repairRequests', breadcrumb: "Запрос на ремонт"},
    {path: '/help', breadcrumb: "Помощь"},
    {path: '/contacts', breadcrumb: "Контакты"},
]

const Breadcrumbs = ({breadcrumbs = []}) => {
    // const breadcrumbs = useBreadcrumbs(routes)
    console.log(breadcrumbs)

    return (
        <Breadcrumb
            // items={breadcrumbs.map(({match, breadcrumb}) => {
            //     return (
            //         {
            //             key: match.pathname,
            //             title: <Link to={match.pathname}>{breadcrumb}</Link>
            //         }
            //     )
            // })}
            items={breadcrumbs?.full_path?.map((crumb, index) => ({
                key: index,
                title: <Link to={index !== 0 ? `/catalog/${breadcrumbs?.full_path?.filter((f,i) => i < index+1).splice(1).join('+')}` : '/'}>{breadcrumbs?.alias_path[index]}</Link>
            }))}
        />
    )
}
export default Breadcrumbs
