import React, {useEffect, useState} from 'react'
import s from '../../../Web/Catalog/Catalog.module.scss'
import {Link} from 'react-router-dom'

const CatalogCategory = ({categoryName, subCategories, setIsShowCatalog}) => {
    useEffect(() => {
        // console.log(subCategories)
    }, [subCategories])
    return (
        <div className={s.mnDV}>
      <span className="fontSiz_32_500" style={{textTransform: 'capitalize'}}>
        {categoryName !== ''
            ? categoryName?.toLowerCase()
            : 'Выберите категорию'}
      </span>
            <div className={s.countCard}>
                {subCategories?.inner.map((el) => {
                    return (
                        <Link
                            key={el.alias}
                            to={`/catalog/${el.full_path.slice(1).join('+') ?? ''}/content`}
                            type="text"
                            className={s.cardName}
                            onClick={() => {
                                setIsShowCatalog(false)
                            }}
                        >
                              <span
                                  className="fontSiz_24_500"
                                  style={{textTransform: 'capitalize', fontSize: '16px'}}
                              >
                                {el.alias.toLowerCase()}
                              </span>
                            <span className="fontSiz_14_400">{el?.count}</span>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default CatalogCategory
