import React, {useEffect, useRef, useState} from 'react'
import {Button, Layout, message, Modal, Spin} from "antd";
import iv from './ItemView.module.scss'
import {LoadStep} from "../../components/Loaders/StepLoader";
import {OrbitControls} from "@react-three/drei";
import {Canvas, useFrame} from "@react-three/fiber";
import Component3D from "../../components/3D/Component3D";



export const ModalItemView3DModel = ({callback_close, callback_open, url=''}) => {
    //http://cdn.testforproject.ru/content/model/e6dac82b-ed67-420f-bd10-b75913ea7834
    const [model, setModel] = useState(null)

    return (
        <Modal
            width={1024}
            centered
            title={'Просмотр 3D модели'}
            open={callback_open}
            onCancel={callback_close}
            footer={null}
            // style={{width: '80vw'}}
        >
            <Layout className={iv.modalContainer}>
                <Component3D url={url} />
                {/*<Component3D url={`${process.env.REACT_APP_DOMAIN_CDN}/content/model/e6dac82b-ed67-420f-bd10-b75913ea7834`} />*/}
                {/*<Component3D url={"D:\\Users\\altgadzhiev.ISTOK\\Desktop\\SSI004884.stp"} />*/}
            </Layout>
        </Modal>
    )
}
