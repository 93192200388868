import {Button} from 'antd';
import {Ruler} from 'lucide-react';
import './3D.module.scss'
import {useEffect} from 'react';
import Scene from "./Scene/Scene";
import useContextViewer from "./ContextViewer";

export const Collection3D = ({url}) => {

  const { ActionRuler, setActionRuler, PointToPointRuler, setPointToPointRuler, PointToTwoPointsRuler, setPointToTwoPointsRuler, SpecifyTheLineRuler,
    setSpecifyTheLineRuler,
    RegionRuler,
    setRegionRuler,
    CornerRuler,
    setCornerRuler,
    AmountRuler,
    setAmountRuler } = useContextViewer();


  useEffect(() => {
    setPointToPointRuler(false)
    setPointToTwoPointsRuler(false)
    setSpecifyTheLineRuler(false)
    setRegionRuler(false)
    setCornerRuler(false)
    setAmountRuler(false)


  }, [ActionRuler])


  return (<>
    <Scene url={url} />
    <Button className='Button' icon={<Ruler />} onClick={() => {
      setActionRuler(!ActionRuler)
      setPointToPointRuler(false)
      setPointToTwoPointsRuler(false)
      setSpecifyTheLineRuler(false)
      setRegionRuler(false)
      setCornerRuler(false)
      setAmountRuler(false)
    }} />
    {ActionRuler && <div className="RulerButton">
      <div className="button-group">

        <Button icon={<Ruler onClick={() => {
          setPointToPointRuler(!PointToPointRuler)
          setPointToTwoPointsRuler(false)
          setSpecifyTheLineRuler(false)
          setRegionRuler(false)
          setCornerRuler(false)
          setAmountRuler(false)
        }} />} />
        <Button icon={<Ruler onClick={() => {
          setPointToPointRuler(false)
          setPointToTwoPointsRuler(!PointToTwoPointsRuler)
          setSpecifyTheLineRuler(false)
          setRegionRuler(false)
          setCornerRuler(false)
          setAmountRuler(false)
        }} />} />
        <Button icon={<Ruler onClick={() => {
          setPointToPointRuler(false)
          setPointToTwoPointsRuler(false)
          setSpecifyTheLineRuler(!SpecifyTheLineRuler)
          setRegionRuler(false)
          setCornerRuler(false)
          setAmountRuler(false)
        }} />} />
        {/* <Button icon={<Ruler onClick={() => {
              setPointToPointRuler(false)
              setPointToTwoPointsRuler(false)
              setSpecifyTheLineRuler(false)
              setRegionRuler(!RegionRuler)
              setCornerRuler(false)
              setAmountRuler(false)
            }} />} /> */}
        <Button icon={<Ruler onClick={() => {
          setPointToPointRuler(false)
          setPointToTwoPointsRuler(false)
          setSpecifyTheLineRuler(false)
          setRegionRuler(false)
          setCornerRuler(!CornerRuler)
          setAmountRuler(false)
        }} />} />
        <Button icon={<Ruler onClick={() => {
          setPointToPointRuler(false)
          setPointToTwoPointsRuler(false)
          setSpecifyTheLineRuler(false)
          setRegionRuler(false)
          setCornerRuler(false)
          setAmountRuler(!AmountRuler)
        }} />} />

      </div>

    </div>}
  </>

  );
}
