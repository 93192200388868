import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import useFetch_profile from '../../../hooks/useFetch_profile'
const List = () => {
  const [fetchSellerList, setSellerList] = useState()
  const sellerListResponse = useFetch_profile()
  useEffect(() => {
    sellerListResponse.get(
      `${process.env.REACT_APP_DOMAIN_BFF}/trade/company/transactions/active`,
      setSellerList
    )
  }, [])
  return <div>Список</div>
}

export default List
