import React, { useEffect, useState } from 'react'
import p from '../../../../../Web/profile/profile.module.scss'
import { Button, Layout, Modal, Drawer, Space, Select } from 'antd'
import DrawerTransactions from './DrawerTransactions/DrawerTransactions'
import useFetch_profile from '../../../hooks/useFetch_profile'
const Kanban = ({
  cardTransaction,
  setFetcSellerKanban,
  fetchSellerKanban,
}) => {
  const sellerKanbanResponse = useFetch_profile()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectDrawer, setSelectDrawer] = useState('Создать')
  const [selectDrawerStatus, setSelectDrawerStatus] =
    useState('Изменить статус')
  const onChangeSelectDrawer = (value) => {
    setSelectDrawer(value)
  }
  const onChangeSelectDrawerStatus = (value) => {
    setIdPrimaryContact({
      ...idPrimaryContact,
      idSpan: value,
    })
  }
  const [idPrimaryContact, setIdPrimaryContact] = useState({})
  const showDrawer = () => {
    setOpenDrawer(true)
  }
  const onCloseDrawer = () => {
    setOpenDrawer(false)
    setSelectDrawer('Создать')
  }
  useEffect(() => {
    sellerKanbanResponse.get(
      `${process.env.REACT_APP_DOMAIN_BFF}/trade/company/transactions/board`,
      setFetcSellerKanban
    )
  }, [])
  console.log(fetchSellerKanban)
  return (
    <Layout className={p.common_wrappMainChaild}>
      <Layout className={p.common_wrappChaild}>
        <Layout
          className={`${p.common_wrappChaild_tTitl} ${p.common_wrappChaild_bacgr1}`}
        >
          <span
            className={`${p.common_wrappChaild_tTitl_span} fontSiz_16_400Blc`}
          >
            Первичный контакт
            <span className="fontSiz_12_400">1 сделка </span>
          </span>
        </Layout>

        {cardTransaction.map((el, i) => {
          // console.log(el)
          if (el.type === 'conversation') {
            return (
              <Layout className={p.common_wrappChaild_card} key={i}>
                <Layout className={p.common_card_titl}>
                  <span className="fontSiz_16_600">EXPO ZONE</span>
                  <span className="fontSiz_14_400">{el.data}</span>
                </Layout>
                <span className="fontSiz_14_400">{el.user}</span>
                <ul className={p.common_card_Ul}>
                  {el.product.map((elem, i) => {
                    return <li key={i}>{elem}</li>
                  })}
                </ul>
                <Layout className={p.common_wrappChaild_btnBuy}>
                  <button
                    className={p.common_wrappChaild_card_btn}
                    id="PrimaryContact"
                    onClick={(e) => {
                      showDrawer()
                      setIdPrimaryContact({
                        ...idPrimaryContact,
                        idPrimary: el.id,
                        idSpan: e.target.id,
                      })
                    }}
                  >
                    Покупка
                  </button>
                </Layout>
              </Layout>
            )
          }
        })}
        <Drawer
          className={p.common_prof_Drawer}
          title={
            idPrimaryContact.idSpan === 'PrimaryContact' ? (
              <span className="common_Drawer_spanPrimaryContact">
                Первичный контакт
              </span>
            ) : idPrimaryContact.idSpan === 'Conversation' ? (
              <span className="common_Drawer_spanConversation">Переговоры</span>
            ) : (
              <span className="common_Drawer_spanAgreement">
                Согласование заказа
              </span>
            )
          }
          placement="right"
          width={'85%'}
          onClose={() => onCloseDrawer('PrimaryContactClose')}
          open={openDrawer}
          extra={
            idPrimaryContact.idSpan !== 'agreement' ? (
              selectDrawer === 'Создать' ? (
                <Space>
                  <Select
                    className={p.common_drawer_select}
                    // defaultValue="Создать"
                    onChange={onChangeSelectDrawer}
                    value={selectDrawer}
                    options={[
                      {
                        value: 'Account',
                        label: 'Счет',
                      },
                      {
                        value: 'Offer',
                        label: 'Предложение',
                      },
                    ]}
                  ></Select>
                  <Button onClick={(e) => onCloseDrawer('PrimaryContactClose')}>
                    Отказаться
                  </Button>
                  <Select
                    className={p.common_drawer_selectStatus}
                    defaultValue="Создать"
                    onChange={onChangeSelectDrawerStatus}
                    value={idPrimaryContact.idSpan}
                    options={[
                      {
                        value: 'PrimaryContact',
                        label: 'Первичный контакт',
                      },
                      {
                        value: 'Conversation',
                        label: 'Переговоры',
                      },
                      {
                        value: 'agreement',
                        label: 'Согласование заказа',
                      },
                      {
                        value: 'Completed_status',
                        label: 'Завершен',
                      },
                    ]}
                  ></Select>
                </Space>
              ) : (
                <>
                  <Button>Сохранить</Button>
                  <Button onClick={() => setSelectDrawer('Создать')}>
                    Отменить
                  </Button>
                </>
              )
            ) : (
              <Select
                className={p.common_drawer_selectStatus}
                defaultValue="Создать"
                onChange={onChangeSelectDrawerStatus}
                value={idPrimaryContact.idSpan}
                options={[
                  {
                    value: 'PrimaryContact',
                    label: 'Первичный контакт',
                  },
                  {
                    value: 'Conversation',
                    label: 'Переговоры',
                  },
                  {
                    value: 'agreement',
                    label: 'Согласование заказа',
                  },
                  {
                    value: 'Completed_status',
                    label: 'Завершен',
                  },
                ]}
              ></Select>
            )
          }
        >
          <DrawerTransactions
            cardTransaction={cardTransaction}
            idPrimaryContact={idPrimaryContact}
            selectDrawer={selectDrawer}
          />
        </Drawer>
      </Layout>
      <Layout className={p.common_wrappChaild}>
        <Layout
          className={`${p.common_wrappChaild_tTitl} ${p.common_wrappChaild_bacgr2}`}
        >
          <span
            className={`${p.common_wrappChaild_tTitl_span} fontSiz_16_400Blc`}
          >
            Переговоры
            <span className="fontSiz_12_400">1 сделка </span>
          </span>
        </Layout>
        {cardTransaction.map((el, i) => {
          // console.log(el)
          if (el.type === 'conversation') {
            return (
              <Layout className={p.common_wrappChaild_card} key={i}>
                <Layout className={p.common_card_titl}>
                  <span className="fontSiz_16_600">EXPO ZONE</span>
                  <span className="fontSiz_14_400">{el.data}</span>
                </Layout>
                <span className="fontSiz_14_400">{el.user}</span>
                <ul className={p.common_card_Ul}>
                  {el.product.map((elem, i) => {
                    return <li key={i}>{elem}</li>
                  })}
                </ul>
                <Layout className={p.common_wrappChaild_btnBuy}>
                  <button
                    id="Conversation"
                    className={p.common_wrappChaild_card_btn}
                    onClick={(e) => {
                      showDrawer()
                      setIdPrimaryContact({
                        ...idPrimaryContact,
                        idSpan: e.target.id,
                        idPrimary: el.id,
                      })
                    }}
                  >
                    Покупка
                  </button>
                </Layout>
              </Layout>
            )
          }
        })}
      </Layout>
      <Layout className={p.common_wrappChaild}>
        <Layout
          className={`${p.common_wrappChaild_tTitl} ${p.common_wrappChaild_bacgr3}`}
        >
          <span
            className={`${p.common_wrappChaild_tTitl_span} fontSiz_16_400Blc`}
          >
            Согласование заказа
            <span className="fontSiz_12_400">1 сделка </span>
          </span>
        </Layout>
        {cardTransaction.map((el, i) => {
          // console.log(el)
          if (el.type === 'agreement') {
            return (
              <Layout className={p.common_wrappChaild_card} key={i}>
                <Layout className={p.common_card_titl}>
                  <span className="fontSiz_16_600">EXPO ZONE</span>
                  <span className="fontSiz_14_400">{el.data}</span>
                </Layout>
                <span className="fontSiz_14_400">{el.user}</span>
                <ul className={p.common_card_Ul}>
                  {el.product.map((elem, i) => {
                    return <li key={i}>{elem}</li>
                  })}
                </ul>
                <Layout className={p.common_wrappChaild_btnBuy}>
                  <button
                    id="agreement"
                    className={p.common_wrappChaild_card_btn}
                    onClick={(e) => {
                      showDrawer()
                      setIdPrimaryContact({
                        ...idPrimaryContact,
                        idSpan: e.target.id,
                        idPrimary: el.id,
                      })
                    }}
                  >
                    Покупка
                  </button>
                </Layout>
              </Layout>
            )
          }
        })}
      </Layout>
    </Layout>
  )
}

export default Kanban
