import React, {useEffect, useState} from 'react';
import {Button, Layout, Spin} from "antd";
import iv from "../../../Web/ItemView/ItemView.module.scss";
import cicss from "../../for_Catalog/CatalogItem/CatalogItem.module.scss";

const ItemRecommendationsManufacturer = ({productId, setDrawerItemSellers, tempPrice}) => {
    const [recommendations, setRecommendations] = useState([]);

    useEffect(() => {
        setRecommendations(Array(2).fill(1))
    }, []);

    return (
        <>
            <span className={iv.itemBracketRecommendationsCount}>{recommendations.length} предложения торговых компаний</span>
            {recommendations.length > 0 ? recommendations.map((el, i) => {
                return (
                    <Layout key={i} className={iv.itemBracketSeller}>
                        <Layout className={iv.itemBracketSellerHeader}>
                            {/*{element.itemPriceValue} {element.itemPriceCurrency}*/}
                            <span>{tempPrice?.value} {tempPrice?.currency}</span>
                            <Button size={'small'} className={iv.itemButton}>В корзину</Button>
                        </Layout>
                        <Layout className={iv.itemBracketSellerBody}>
                            <Layout className={iv.itemBracketSellerDescription}>
                                <span className={iv.itemBracketSellerName}>Мастер-эксперт</span>
                                <div className={cicss.rating} style={{'--rate': '4.7'}}>
                                    <div className={cicss.star}></div>
                                    <div className={cicss.star}></div>
                                    <div className={cicss.star}></div>
                                    <div className={cicss.star}></div>
                                    <div className={cicss.star}></div>
                                </div>
                                <span className={iv.itemBracketSellerRateCount}>5 отзывов</span>
                            </Layout>
                            <Layout className={iv.itemBracketSellerShipping}>
                                <span>Доставка: от 14 дней, от 3917 ₽</span>
                            </Layout>
                            <Layout className={iv.itemBracketSellerPlace}>
                                <span>ТК Корунд, Пенза</span>
                            </Layout>
                        </Layout>
                    </Layout>
                )
            }) : <Spin/>}
            <Button
                style={{fontSize: '16px', fontWeight: '500', lineHeight: '20px', textAlign: 'start', padding: 0}}
                type={'link'}
                onClick={() => {
                    setDrawerItemSellers(true)
                }}
            >
                Показать +2 предложения
            </Button>
        </>
    )
}

export default ItemRecommendationsManufacturer;