import React, {useState} from 'react'
import p from '../../../Web/profile/profile.module.scss'
//! ANTD
import {Button, Checkbox, Layout, Modal, Tag} from 'antd'
import {CloseOutlined, MinusOutlined, PlusOutlined,} from '@ant-design/icons'
import Star_violet from '../../../image/Star_violet.svg'
import Stat from '../../../image/Stat.svg'
import Favorites from '../../../image/Favorites.svg'

const ButtonGroup = Button.Group
const Basket_Buyer = () => {
  const [checkbox, setCheckbox] = useState(false)
  const [checked, setChecked] = useState(false)
  const [count, setCount] = useState(1)
  const [isModalGood, setIsModalGood] = useState(false)
  const onChangeChecked = (e) => {
    console.log('checked = ', e.target.checked)
    setChecked(e.target.checked)
    setCheckbox(!checkbox)
  }
  const preventDefault = (e) => {
    e.preventDefault()
    console.log('Clicked! But prevent default.')
  }
  const decline = () => {
    let newCount = count - 1
    if (newCount < 0) {
      newCount = 0
    }
    setCount(newCount)
  }
  const increase = () => {
    setCount(count + 1)
  }
  const showModalGood = () => {
    setIsModalGood(true)
  }
  const handleCancelGood = () => {
    setIsModalGood(false)
  }
  return (
    <Layout className={p.mainDivBuyer}>
      <Layout className={p.common_buyer_Left} style={{ padding: '24px' }}>
        <Layout className={p.common_buyer_LeftHedr}>
          <span className="fontSiz_24_500">Корзина</span>
          <span className="fontSiz_14_400">5 товара</span>
        </Layout>
        <Layout className={p.common_buyer_LSelectAll}>
          <Checkbox checked={checked} onChange={onChangeChecked}>
            <span className="fontSiz_14_400">Выбрать все</span>
          </Checkbox>
          {checkbox && (
            <Tag
              className={p.common_tag}
              closeIcon={<CloseOutlined />}
              //   iconPosition="start"
              //   orientation="left"
              onClose={() => {
                setCheckbox(!checkbox)
                setChecked(!checked)
              }}
            >
              <span className="fontSiz_14_400">Удалить выбранные</span>
            </Tag>
          )}
        </Layout>
        <Layout className={p.common_buyer_cardDiv}>
          <Layout className={p.common_buyer_cardDivChaild}>
            <span
              className="fontSiz_20_400"
              style={{ color: 'rgba(141, 36, 248, 1)', paddingBottom: '16px' }}
            >
              Туламаш
            </span>
            <Layout className={p.common_buyer_card}>
              <Layout className={p.common_buyer_info}>
                <Checkbox></Checkbox>
                <img src={require('../../../image/freza.png')} alt="img" />
                <Layout className={p.common_info_chaild}>
                  <span className="fontSiz_12_400">Артикул 00001518353</span>
                  <Layout className={p.common_buyer_ratStar}>
                    <Layout className={p.common_buyer_rating}>
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    </Layout>
                    <span>5 отзывов</span>
                  </Layout>
                  <span className="fontSiz_16_400Blc">
                    Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                  </span>
                  <span
                    className={`fontSiz_12_400`}
                    style={{
                      color: 'rgba(141, 36, 248, 1)',
                      paddingBottom: '16px',
                    }}
                  >
                    Туламаш
                  </span>
                  <span className="fontSiz_16_500">10 000 ₽ </span>
                  <Layout className={p.common_stat}>
                    <img src={Favorites} alt="img" />
                    <img src={Stat} alt="img" />
                  </Layout>
                </Layout>
              </Layout>
              <ButtonGroup className={p.common_buyer_btnGroup}>
                <Button onClick={decline} icon={<MinusOutlined />} />
                {count}
                <Button onClick={increase} icon={<PlusOutlined />} />
                {/* <Button onClick={random} icon={<QuestionOutlined />} /> */}
              </ButtonGroup>
            </Layout>
            <Layout className={p.common_buyer_card}>
              <Layout className={p.common_buyer_info}>
                <Checkbox></Checkbox>
                <img src={require('../../../image/freza.png')} alt="img" />
                <Layout className={p.common_info_chaild}>
                  <span className="fontSiz_12_400">Артикул 00001518353</span>
                  <Layout className={p.common_buyer_ratStar}>
                    <Layout className={p.common_buyer_rating}>
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    </Layout>
                    <span>5 отзывов</span>
                  </Layout>
                  <span className="fontSiz_16_400Blc">
                    Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                  </span>
                  <span
                    className={`fontSiz_12_400`}
                    style={{
                      color: 'rgba(141, 36, 248, 1)',
                      paddingBottom: '16px',
                    }}
                  >
                    Туламаш
                  </span>
                  <span className="fontSiz_16_500">10 000 ₽ </span>
                  <Layout className={p.common_stat}>
                    <img src={Favorites} alt="img" />
                    <img src={Stat} alt="img" />
                  </Layout>
                </Layout>
              </Layout>
              <ButtonGroup className={p.common_buyer_btnGroup}>
                <Button onClick={decline} icon={<MinusOutlined />} />
                {count}
                <Button onClick={increase} icon={<PlusOutlined />} />
                {/* <Button onClick={random} icon={<QuestionOutlined />} /> */}
              </ButtonGroup>
            </Layout>
          </Layout>
          <Layout className={p.common_buyer_cardDivChaild}>
            <span
              className="fontSiz_20_400"
              style={{ color: 'rgba(141, 36, 248, 1)', paddingBottom: '16px' }}
            >
              ГК Инструмент
            </span>
            <Layout className={p.common_buyer_card}>
              <Layout className={p.common_buyer_info}>
                <Checkbox></Checkbox>
                <img src={require('../../../image/freza.png')} alt="img" />
                <Layout className={p.common_info_chaild}>
                  <span className="fontSiz_12_400">Артикул 00001518353</span>
                  <Layout className={p.common_buyer_ratStar}>
                    <Layout className={p.common_buyer_rating}>
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    </Layout>
                    <span>5 отзывов</span>
                  </Layout>
                  <span className="fontSiz_16_400Blc">
                    Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                  </span>
                  <span
                    className={`fontSiz_12_400`}
                    style={{
                      color: 'rgba(141, 36, 248, 1)',
                      paddingBottom: '16px',
                    }}
                  >
                    ГК Инструмент
                  </span>
                  <span className="fontSiz_16_500">10 000 ₽ </span>
                  <Layout className={p.common_stat}>
                    <img src={Favorites} alt="img" />
                    <img src={Stat} alt="img" />
                  </Layout>
                </Layout>
              </Layout>
              <ButtonGroup className={p.common_buyer_btnGroup}>
                <Button onClick={decline} icon={<MinusOutlined />} />
                {count}
                <Button onClick={increase} icon={<PlusOutlined />} />
                {/* <Button onClick={random} icon={<QuestionOutlined />} /> */}
              </ButtonGroup>
            </Layout>
            <Layout className={p.common_buyer_card}>
              <Layout className={p.common_buyer_info}>
                <Checkbox></Checkbox>
                <img src={require('../../../image/freza.png')} alt="img" />
                <Layout className={p.common_info_chaild}>
                  <span className="fontSiz_12_400">Артикул 00001518353</span>
                  <Layout className={p.common_buyer_ratStar}>
                    <Layout className={p.common_buyer_rating}>
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    </Layout>
                    <span>5 отзывов</span>
                  </Layout>
                  <span className="fontSiz_16_400Blc">
                    Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                  </span>
                  <span
                    className={`fontSiz_12_400`}
                    style={{
                      color: 'rgba(141, 36, 248, 1)',
                      paddingBottom: '16px',
                    }}
                  >
                    ГК Инструмент
                  </span>
                  <span className="fontSiz_16_500">10 000 ₽ </span>
                  <Layout className={p.common_stat}>
                    <img src={Favorites} alt="img" />
                    <img src={Stat} alt="img" />
                  </Layout>
                </Layout>
              </Layout>
              <ButtonGroup className={p.common_buyer_btnGroup}>
                <Button onClick={decline} icon={<MinusOutlined />} />
                {count}
                <Button onClick={increase} icon={<PlusOutlined />} />
                {/* <Button onClick={random} icon={<QuestionOutlined />} /> */}
              </ButtonGroup>
            </Layout>
            <Layout className={p.common_buyer_card}>
              <Layout className={p.common_buyer_info}>
                <Checkbox></Checkbox>
                <img src={require('../../../image/freza.png')} alt="img" />
                <Layout className={p.common_info_chaild}>
                  <span className="fontSiz_12_400">Артикул 00001518353</span>
                  <Layout className={p.common_buyer_ratStar}>
                    <Layout className={p.common_buyer_rating}>
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                      <img
                        style={{ width: '16px', height: '16px' }}
                        src={Star_violet}
                        alt="s"
                      />
                    </Layout>
                    <span>5 отзывов</span>
                  </Layout>
                  <span className="fontSiz_16_400Blc">
                    Фреза концевая цх ф 6.0х 13х 50 4-п Р6М5 хв. 6мм
                  </span>
                  <span
                    className={`fontSiz_12_400`}
                    style={{
                      color: 'rgba(141, 36, 248, 1)',
                      paddingBottom: '16px',
                    }}
                  >
                    ГК Инструмент
                  </span>
                  <span className="fontSiz_16_500">10 000 ₽ </span>
                  <Layout className={p.common_stat}>
                    <img src={Favorites} alt="img" />
                    <img src={Stat} alt="img" />
                  </Layout>
                </Layout>
              </Layout>
              <ButtonGroup className={p.common_buyer_btnGroup}>
                <Button onClick={decline} icon={<MinusOutlined />} />
                {count}
                <Button onClick={increase} icon={<PlusOutlined />} />
                {/* <Button onClick={random} icon={<QuestionOutlined />} /> */}
              </ButtonGroup>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <Layout className={p.common_buyer_right}>
        <Layout className={p.common_buyer_price}>
          <span className="fontSiz_16_400Blc">Стоимость</span>
          <span className="fontSiz_16_600">22 000 ₽ </span>
        </Layout>
        <Button className={p.common_buyer_btnReq} onClick={showModalGood}>
          Оформить заказ
        </Button>
        <Modal
          width={'80%'}
          style={{ padding: '20px' }}
          className="modalDeled"
          // title="Удаление аккаунта"
          open={isModalGood}
          // onOk={handleOk}
          onCancel={handleCancelGood}
          footer={null}
        >
          <Layout className={p.common_modal_form}>
            {/* <Layout className={p.common_modal_column}> */}
            <Layout
              className="fontSiz_32_500"
              style={{ textAlign: 'center', width: '100%' }}
            >
              Заказ оформлен
            </Layout>
            <Layout className={p.common_modal_changeInfo}>
              <p className="fontSiz_16_400Blc">
                Уважаемые покупатели! В течение двух рабочих дней Вам придет
                уведомление о статусе заказа на электронную почту.
              </p>
              {/* <Layout className={p.common_chaild_divInput}>
                  <span className="fontSiz_16_500">
                    Старый пароль
                    <Asterisk color="red" style={{ width: '16px' }} />
                  </span>
                  <Input
                    className={p.common_chaild_Input}
                    placeholder="Имя пользователя"
                    type="pass"
                    name="username"
                    required
                  />
                </Layout> */}
            </Layout>
            <Layout style={{ width: '100%' }}>
              <Button
                type="primary"
                className={p.common_modal_column_btnGood}
                //   style={{ width: '100%' }}
                onClick={handleCancelGood}
              >
                Отлично
              </Button>
            </Layout>
            {/* </Layout> */}
          </Layout>
        </Modal>
      </Layout>
    </Layout>
  )
}

export default Basket_Buyer
