import React, {useEffect, useMemo, useState, useCallback, useRef} from 'react'
import s from './Header.module.scss'
import LOGO from '../../image/market.svg'
import {ReactComponent as Basket} from '../../image/Basket.svg'
import {ReactComponent as UserICON} from '../../image/User.svg'
import {ReactComponent as StarICON} from '../../image/shabl.svg'
import {ReactComponent as VectICON} from '../../image/Vector.svg'
import {ReactComponent as SignOutICON} from '../../image/Sign_out_squre.svg'

import {Link, useNavigate, NavLink, useLocation} from 'react-router-dom'
// import { Outlet, useNavigate } from 'react-router-dom'
import {
    AliwangwangOutlined,
    AppstoreOutlined,
    CloseOutlined,
    EnvironmentOutlined,
    LogoutOutlined,
    ProductOutlined,
    SearchOutlined,
    ShoppingCartOutlined,
    UserAddOutlined,
    UserOutlined,
} from '@ant-design/icons'

import {
    Button,
    Input,
    Dropdown,
    ConfigProvider,
    Layout,
    Form,
    Divider, Typography, Spin,
} from 'antd'
import {CircleIcon, FactoryIcon} from 'lucide-react'
import Catalog from '../../Web/Catalog/Catalog'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import {debounceTimeout} from '../../Utils/Miscellaneous'
import debounce from 'lodash/debounce'

import logo from '../../image/logo_full.svg'
import {useDispatch} from "react-redux";

const {Search} = Input
const Header = ({
                    setOpenLogin,
                    setOpenRegistration,
                    setToggleClass,
                    toggleClass,
                    userName,
                    // role,
                    setOpenDrawerLogin,
                }) => {
    const [isShowCatalog, setIsShowCatalog] = useState(false)
    const [katalogBTN, setKatalogBTN] = useState(false) //Кнопка для Каталога
    const [searchPrompt, setSearchPrompt] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const navigation = useNavigate()
    const [searchResult, setSearchResult] = useState(null)
    const [isShowSearchResult, setIsShowSearchResult] = useState(false)
    const location = useLocation()

    const searchRef = useRef(null)
    const resultRef = useRef(null)

    const dispatch = useDispatch()
    const [isSearching, setIsSearching] = useState(false)
    // console.log('localStorage', localStorage)
    // console.log('role', role)

    //dispatch({type: 'SET_EDITING', payload: true})

    useEffect(() => {
        setSearchValue('')
    }, [location]);


    const items = [
        {
            key: '0',
            label: (
                <Link
                    to={`/profile/${localStorage.role}`}
                    onClick={() => {
                        setIsShowCatalog(false)
                    }}
                >
                    {/* <Link to={`/profile/${localStorage.access}`}></Link> */}
                    <div className={s.iconLink}>
                        <UserICON/> <span>Мой профиль</span>
                    </div>
                </Link>
            ),
        },
        {
            key: '1',
            label: (
                <Link
                    to={`#`}
                    onClick={() => {
                        setIsShowCatalog(false)
                    }}
                >
                    {/* <Link to={`/profile/${localStorage.access}`}></Link> */}
                    <div className={s.iconLink}>
                        <Basket/> <span>Корзина</span>
                    </div>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link
                    to={`#`}
                    onClick={() => {
                        setIsShowCatalog(false)
                    }}
                >
                    {/* <Link to={`/profile/${localStorage.access}`}></Link> */}
                    <div className={s.iconLink}>
                        <StarICON/> <span>Шаблон сравнения </span>
                    </div>
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <Link
                    to={`#`}
                    onClick={() => {
                        setIsShowCatalog(false)
                    }}
                >
                    {/* <Link to={`/profile/${localStorage.access}`}></Link> */}
                    <div className={s.iconLink}>
                        <VectICON/> <span>Избранное</span>
                    </div>
                </Link>
            ),
        },
        {
            key: '4',
            label: (
                <Button
                    className={s.btnExit}
                    type="text"
                    onClick={() => {
                        localStorage.clear()
                        localStorage.setItem('accessToken', 'undefined')
                        navigation('/')
                        setIsShowCatalog(false)
                    }}
                >
                    <div className={s.iconLink}>
                        <SignOutICON/> <span>Выйти</span>
                    </div>
                </Button>
            ),
        },
    ]

    useEffect(() => {
        if (searchPrompt !== '') {
            try {
                console.log('searchPrompt ->', searchPrompt)
                let url = `${
                    process.env.REACT_APP_DOMAIN_BFF
                }/search/?alias=${encodeURIComponent(searchPrompt)}`
                console.log(url)

                fetch(url, {
                    method: 'GET',
                })
                    .then((res) => {
                        console.info(`HTTP Status: ${res.status}`)
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`)
                        }
                        return res.json()
                    })
                    .then((res) => {
                        console.log('SEARCH ->', res)
                        setSearchResult(res)
                        setIsSearching(false)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            } catch (error) {
                console.error('An unexpected error occurred:', error)
            }
        }
    }, [searchPrompt])

    const handleSearch = (value) => {
        setSearchPrompt(value)
        setIsShowSearchResult(true)
    }

    const handleSearch_realTime = useCallback(debounce(handleSearch, 500), [])

    return (
        <header className={s.mainHeader}>
            {/*<ConfigProvider*/}
            {/*  theme={{*/}
            {/*    components: {*/}
            {/*      Input: {*/}
            {/*        colorBgContainer: 'transparent',*/}
            {/*        colorBorder: '#DCFF00',*/}
            {/*        colorPrimaryHover: '#DCFF00',*/}
            {/*        colorText: '#fff',*/}
            {/*        borderRadius: '8px',*/}
            {/*        fontSize: '16px',*/}
            {/*        paddingBlock: 6,*/}
            {/*      },*/}
            {/*      Dropdown: {*/}
            {/*        colorBgElevated: '#0B0B0C',*/}
            {/*        colorText: '#fff',*/}
            {/*      },*/}
            {/*    },*/}
            {/*  }}*/}
            {/*>*/}
            <div className={s.divHead1}>
                <div className={s.chldDivH1}>
                    {/* <span style={{ color: 'white', paddingRight: '40px' }}>LOGO</span> */}
                    <Link
                        to={process.env.REACT_APP_ROUTES_MAIN}
                        onClick={() => {
                            setIsShowCatalog(false)
                        }}
                    >
                        <img height={40} src={logo} alt="Stuff"/>
                    </Link>
                    <Button
                        type={!isShowCatalog ? 'primary' : ""}
                        style={isShowCatalog ? {backgroundColor: '#8D24F8', color: '#FFFFFF'} : {}}
                        icon={!isShowCatalog ? <ProductOutlined/> : <CloseOutlined/>}
                        className={s.btnKatalog}
                        to="catalog"
                        onClick={() => {
                            setKatalogBTN(!katalogBTN)

                            setIsShowCatalog(!isShowCatalog)
                        }}
                    >
                        Каталог
                    </Button>
                    <Layout className={s.searchBlock}>
                        <Layout ref={searchRef} style={{background: 'transparent'}}>
                            <Search
                                className={`${s.searchHead} ${s.headerSearchButton}`}
                                placeholder="Введите запрос... "
                                allowClear
                                icon={<SearchOutlined/>}
                                size="large"
                                onSearch={() => {
                                    debounce(handleSearch, 300)
                                }}
                                value={searchValue}
                                onFocus={() => {
                                    setIsShowSearchResult(true)
                                }}
                                onBlur={() => {
                                    console.log(searchRef.current, resultRef.current)
                                    setTimeout(() => {
                                        setIsShowSearchResult(false)
                                    }, 100)
                                }}
                                onChange={(e) => {
                                    if (e.target.value === '') setIsSearching(false)
                                    else setIsSearching(true)
                                    setSearchValue(e.target.value)
                                    handleSearch_realTime(e.target.value)
                                }}
                            />
                        </Layout>
                        <Layout
                            ref={resultRef}
                            className={s.searchResult}
                            style={{
                                display: isShowSearchResult === true ? 'flex' : 'none',
                            }}
                        >
                            {!isSearching ? searchValue !== '' ? <>
                                {searchResult?.categories?.length > 0 && <>
                                    <Divider className={s.searchDivider}>Категории</Divider>
                                    <Layout className={s.searchPiece}>
                                        {searchResult?.categories.map((el, i) => {
                                            return (
                                                <Link
                                                    key={i}
                                                    to={el.url}
                                                    className={s.searchElement}
                                                    onClick={() => {
                                                        setIsShowSearchResult(false)
                                                    }}
                                                >
                                                    <img
                                                        src={`${process.env.REACT_APP_DOMAIN_CDN}${el.image}`}
                                                    />
                                                    <span>{el.alias}</span>
                                                </Link>
                                            )
                                        })}
                                    </Layout>
                                </>}
                                {searchResult?.manufactures?.length > 0 && <>
                                    <Divider className={s.searchDivider}>Бренды</Divider>
                                    <Layout className={s.searchPiece}>
                                        {/*isSearching ? <Spin/>*/}
                                        {searchResult?.manufactures.map((el, i) => {
                                            return (
                                                <Link
                                                    key={i}
                                                    to={`/manufacturer/${el.name}`}
                                                    className={s.searchElement}
                                                    onClick={() => {
                                                        setIsShowSearchResult(false)
                                                    }}
                                                >
                                                    <img
                                                        src={`${process.env.REACT_APP_DOMAIN_CDN}${el.image}`}
                                                    />
                                                    <span>{el.alias}</span>
                                                </Link>
                                            )
                                        })}
                                    </Layout>
                                </>}
                                {searchResult?.products?.length > 0 && <>
                                    <Divider className={s.searchDivider}>Продукты</Divider>
                                    <Layout className={s.searchPiece}>
                                        {searchResult?.products.map((el, i) => {
                                            return (
                                                <Link
                                                    key={i}
                                                    to={el.url}
                                                    className={s.searchElement}
                                                    onClick={() => {
                                                        setIsShowSearchResult(false)
                                                    }}
                                                >
                                                    <img
                                                        src={`${process.env.REACT_APP_DOMAIN_CDN}${el.image}`}
                                                    />
                                                    <span>{el.name}</span>
                                                </Link>
                                            )
                                        })}
                                    </Layout>
                                </> }
                            </> : <Layout className={s.searchSpin}>Введите свой запрос</Layout> : <Layout className={s.searchSpin}><Spin /></Layout>}
                        </Layout>
                    </Layout>
                </div>
                <div className={s.chldDivH2}>
                    <Button
                        className={`${s.btnMoscow} ${s.btnStyle}`}
                        type="text"
                        icon={<EnvironmentOutlined/>}
                        onClick={() => {
                            setIsShowCatalog(false)
                        }}
                        disabled={true}
                    >
                        Москва
                    </Button>
                    {!!!localStorage.accessToken || localStorage.accessToken === 'undefined' ? (
                        <Button
                            className={` ${s.btnStyle}`}
                            type="text"
                            icon={<UserOutlined/>}
                            onClick={() => {
                                dispatch({type: 'TOGGLE_LOGIN_FORM', payload: true})
                                setIsShowCatalog(false)
                            }}
                        >
                            Войти
                        </Button>
                    ) : (
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                            className={` ${s.btnStyle}`}
                        >
                            <Button
                                // className={s.btnExit}
                                type="text"
                                icon={<AliwangwangOutlined />}
                                style={{color: 'white'}}
                            >
                                {localStorage.userName ? localStorage.userName : 'Имя отсутствует'}
                            </Button>
                        </Dropdown>
                    )}
                    <NavLink
                        to={process.env.REACT_APP_ROUTES_HELP}
                        // className={` ${s.btnStyle}`}
                        className={({isActive}) =>
                            `${s.btnStyle} ${isActive ? s.activLink : ''}`
                        }
                        type="text"
                    >
                        Помощь
                    </NavLink>

                    <NavLink to="contacts">
                        <Button type="text" className={`${s.btnStyle}`} disabled={true}>
                            Контакты
                        </Button>
                    </NavLink>
                </div>
            </div>
            <div className={s.divHead2}>
                <div className={s.grpBtnHed2}>
                    <Link
                        to="manufacturers"
                        onClick={() => {
                            setIsShowCatalog(false)
                        }}
                    >
                        <Button
                            type="text"
                            icon={<FactoryIcon size={19}/>}
                            className={`${s.factoryBTN} ${s.btnStyle}`}
                        >
                            Производители
                        </Button>
                    </Link>
                    {/*<Link*/}
                    {/*  to="visitors"*/}
                    {/*  onClick={() => {*/}
                    {/*    setIsShowCatalog(false)*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <Button*/}
                    {/*    type="text"*/}
                    {/*    icon={<UserAddOutlined />}*/}
                    {/*    className={`${s.btnStyle}`}*/}
                    {/*  >*/}
                    {/*    Посетители*/}
                    {/*  </Button>*/}
                    {/*</Link>*/}
                    <Link
                        to="requestProduction"
                        onClick={() => {
                            setIsShowCatalog(false)
                        }}
                    >
                        <Button type="text" className={`${s.btnStyle}`}>
                            Запрос на изготовление
                        </Button>
                    </Link>
                    <Link
                        to="repairRequests"
                        onClick={() => {
                            setIsShowCatalog(false)
                        }}
                    >
                        <Button type="text" className={`${s.btnStyle}`}>
                            Запрос на ремонт
                        </Button>
                    </Link>
                    {/*<Link*/}
                    {/*    to="#"*/}
                    {/*    onClick={() => {*/}
                    {/*        setIsShowCatalog(false)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Button type="text" className={`${s.btnStyle}`} disabled={true}>*/}
                    {/*        Услуги*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                </div>
                <div>
                    <Button onClick={() => {
                        // setOpenRegistration(true)
                        dispatch({type: 'TOGGLE_REGISTER_FORM', payload: true})
                    }} className={s.postavshik}>
                        Стать поставщиком
                    </Button>
                </div>
            </div>
            {/*</ConfigProvider>*/}
            <Layout
                style={{display: isShowCatalog === false ? 'none' : 'block'}}
                className={s.catalogWindow}
            >
                <Catalog setIsShowCatalog={setIsShowCatalog}/>
            </Layout>
        </header>
    )
}

export default Header
