import React, {useState} from 'react'
import ca from '../common__Admin.module.scss'
import gca from '../../../Web/GeneralComponentAdmin/GeneralComponentAdmin.module.scss'

import {ReactComponent as EdditSVG} from '../../../image/Edit_light.svg'

import {useNavigate} from 'react-router-dom'
import {Button, Input, Layout, Table} from 'antd'

const RepairRequests_admin = () => {
  const navigate = useNavigate()
  const { Search } = Input
  const [dataRepairRequestsAd, setDataRepairRequestsAd] = useState([
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '1',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '2',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
    {
      key: '3',
      issued: 'ГК Инструмент',
      category: 'Текст',
      name: 'Сверло',
      price: '1200',
      description: 'Есть',
      plan: 'Есть',
    },
  ])
  const [newDataRepairRequestsAd, setNewDataRepairRequestsAd] = useState({
    key: dataRepairRequestsAd.length + 1,
    issued: 'ГК Инструмент',
    category: 'Текст',
    name: 'Сверло',
    price: '1200',
    description: 'Есть',
    plan: 'Есть',
  })
  const columnsRepairRequests = [
    {
      title: '',
      dataIndex: 'red',
      render: (text) => (
        <Button type="text" onClick={() => navigate('editingProductAdmin')}>
          <EdditSVG />
        </Button>
      ),
      width: '5%',
    },
    {
      title: 'Оформил',
      dataIndex: 'issued',
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      render: (text) => (
        <span style={{ color: 'rgba(115, 209, 61, 1)' }}>text</span>
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'Чертеж',
      dataIndex: 'plan',
      render: (text) => (
        <span style={{ color: 'rgba(115, 209, 61, 1)' }}>text</span>
      ),
    },
  ]
  return (
    <Layout className={ca.common_MainDiv}>
      <Layout className={ca.common_InputGrup}>
        <Search
          suffix
          placeholder="input search text"
          style={{
            width: 200,
          }}
        />
      </Layout>
      <Layout className={ca.common_scrollWrapper}>
        <Table
          columns={columnsRepairRequests}
          dataSource={dataRepairRequestsAd}
          className={gca.tableGeneral}
          pagination={{
            pageSize: 40,
          }}
          scroll={{ x: 'max-content', y: 650 }}
        />
      </Layout>
    </Layout>
  )
}

export default RepairRequests_admin
