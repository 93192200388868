import * as THREE from 'three'
import {useThree} from '@react-three/fiber'
import Line from '../Ruler/Line'
import Sphere from '../Ruler/Sphere'
import SpriteLine from '../Ruler/SpriteLine'

const Size = (obj) => {

    console.log('Size')
    console.log(obj.obj)

    const { gl, camera, scene } = useThree();

    // useEffect(() => {
    console.log(scene)

    // Compute the bounding box
    const bbox = new THREE.Box3().setFromObject(obj.obj)
    // Init your size variable
    const bboxSize = new THREE.Vector3(bbox)
    // Get the size
    bbox.getSize(bboxSize)
    // Now use bboxSize (x,y,z)
    console.log(bboxSize)
    console.log(bbox)

    const color = '#0B0B0C' // TODO: цвет измерений меняется здесь

    // },[scene])
    const axisDeviationZ=5
    const axisDeviationY=-5
    const axisDeviationX=-5
    const leng1=Math.sqrt(Math.pow(bbox.max.x - bbox.min.x, 2) + Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2))
    const leng2=Math.sqrt(Math.pow(0 - 0, 2) + Math.pow(bbox.max.y - bbox.min.y, 2) + Math.pow(0 - 0, 2))
    const leng3=Math.sqrt(Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2) + Math.pow(bbox.max.z - bbox.min.z, 2))
    const maxleng=Math.max(leng3, Math.max(leng1, leng2))
    var adaptate= Math.round(maxleng / 50)
    if (adaptate===0)
    {
        adaptate=1
    }
    adaptate=adaptate+1
    return (
        <>
            <Line start={[bbox.max.x , 0, axisDeviationZ + bbox.max.z]} end={[bbox.min.x, 0,axisDeviationZ + bbox.max.z]} color={color} />

            <Line start={[bbox.max.x , 0, 0]} end={[bbox.max.x , 0, axisDeviationZ + bbox.max.z]} color={color} />

            <Line start={[bbox.min.x , 0, 0]} end={[bbox.min.x, 0,axisDeviationZ + bbox.max.z]} color={color} />




            <Line start={[bbox.max.x , 0, axisDeviationZ + bbox.max.z]} end={[bbox.max.x-1 , 0, axisDeviationZ + bbox.max.z-0.5]} color={color} />
            <Line start={[bbox.max.x , 0, axisDeviationZ + bbox.max.z]} end={[bbox.max.x-1, 0,axisDeviationZ + bbox.max.z+0.5]} color={color} />


            <Line start={[bbox.min.x, 0,axisDeviationZ + bbox.max.z]} end={[bbox.min.x+1 , 0, axisDeviationZ + bbox.max.z-0.5]} color={color} />
            <Line start={[bbox.min.x, 0,axisDeviationZ + bbox.max.z]} end={[bbox.min.x+1, 0,axisDeviationZ + bbox.max.z+0.5]} color={color} />

            <Sphere position={[bbox.max.x, 0, 0]} color={color} opacity={1} />
            <Sphere position={[bbox.min.x, 0, 0]} color={color} opacity={1} />
            {/* <SpriteSpheres point={[bbox.max.x, 0, 0]} reclamationX={3} colorback={color} colorTexst='black' />
            <SpriteSpheres point={[bbox.min.x, 0, 0]} reclamationX={-3} colorback={color} colorTexst='black' /> */}

            <SpriteLine point={[(bbox.max.x + bbox.min.x) / 2 , 0, axisDeviationZ + bbox.max.z]} lengthLine={Math.sqrt(Math.pow(bbox.max.x - bbox.min.x, 2) + Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2))} a={0} color={color} colorback={color} colorTexst='black' adaptate={adaptate} size={true}/>


            <Line start={[bbox.min.x + axisDeviationY, bbox.max.y, 0]} end={[bbox.min.x + axisDeviationY, bbox.min.y, 0]} color={color} />

            <Line start={[bbox.min.x + axisDeviationY, bbox.max.y, 0]} end={[0,bbox.max.y,0]} color={color} />

            <Line start={[bbox.min.x + axisDeviationY, bbox.min.y, 0]} end={[0,bbox.min.y,0]} color={color} />



            <Line start={[bbox.min.x + axisDeviationY, bbox.max.y, 0]} end={[bbox.min.x + axisDeviationY+0.5, bbox.max.y-1, 0]} color={color} />
            <Line start={[bbox.min.x + axisDeviationY, bbox.max.y, 0]} end={[bbox.min.x + axisDeviationY-0.5, bbox.max.y-1, 0]} color={color} />


            <Line start={[bbox.min.x + axisDeviationY, bbox.min.y, 0]} end={[bbox.min.x + axisDeviationY+0.5, bbox.min.y+1, 0]} color={color} />
            <Line start={[bbox.min.x + axisDeviationY, bbox.min.y, 0]} end={[bbox.min.x + axisDeviationY-0.5, bbox.min.y+1, 0]} color={color} />



            <Sphere position={[0, bbox.max.y, 0]} color={color} opacity={1} />
            <Sphere position={[0, bbox.min.y, 0]} color={color} opacity={1} />
            {/* <SpriteSpheres point={[0, bbox.max.y, 0]} reclamationY={3} colorback={color} colorTexst='black' />
            <SpriteSpheres point={[0, bbox.min.y, 0]} reclamationY={-3} colorback={color} colorTexst='black' /> */}
            
            <SpriteLine point={[bbox.min.x + axisDeviationY, ((bbox.max.y + bbox.min.y) / 2) ,0 ]} lengthLine={Math.sqrt(Math.pow(0 - 0, 2) + Math.pow(bbox.max.y - bbox.min.y, 2) + Math.pow(0 - 0, 2))} a={0} color={color} colorback={color} colorTexst='black'  adaptate={adaptate} size={true}/>


            <Line start={[0, bbox.min.y + axisDeviationX, bbox.max.z]} end={[0, bbox.min.y + axisDeviationX, bbox.min.z]} color={color} />

            <Line start={[0, bbox.min.y + axisDeviationX, bbox.max.z]} end={[0,0,bbox.max.z]} color={color} />

            <Line start={[0, bbox.min.y + axisDeviationX, bbox.min.z]} end={[0,0,bbox.min.z]} color={color} />






            <Line start={[0, bbox.min.y + axisDeviationX, bbox.max.z]} end={[0, bbox.min.y + axisDeviationX+0.5, bbox.max.z-1]} color={color} />
            <Line start={[0, bbox.min.y + axisDeviationX, bbox.max.z]} end={[0, bbox.min.y + axisDeviationX-0.5, bbox.max.z-1]} color={color} />


            <Line start={[0, bbox.min.y + axisDeviationX, bbox.min.z]} end={[0, bbox.min.y + axisDeviationX+0.5, bbox.min.z+1]} color={color} />
            <Line start={[0, bbox.min.y + axisDeviationX, bbox.min.z]} end={[0, bbox.min.y + axisDeviationX-0.5, bbox.min.z+1]} color={color} />





            <Sphere position={[0, 0, bbox.max.z]} color={color} opacity={1} />
            <Sphere position={[0, 0, bbox.min.z]} color={color} opacity={1} />
            {/* <SpriteSpheres point={[0, 0, bbox.max.z]} reclamationZ={3} colorback={color} colorTexst='black' />
            <SpriteSpheres point={[0, 0, bbox.min.z]} reclamationZ={-3} colorback={color} colorTexst='black' /> */}
            <SpriteLine point={[0,  bbox.min.y + axisDeviationX, ((bbox.max.z + bbox.min.z) / 2) ]} lengthLine={Math.sqrt(Math.pow(0 - 0, 2) + Math.pow(0 - 0, 2) + Math.pow(bbox.max.z - bbox.min.z, 2))} a={0} color={color} colorback={color} colorTexst='black'  adaptate={adaptate} size={true}/>

            {/* <SpriteSpheres point={[0, 0, 0]} reclamationX={0} reclamationY={0} reclamationZ={0} colorback={color} colorTexst='black' /> */}
        </>
    )


}

export default Size