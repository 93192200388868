import {Button, Flex, Form, Modal} from "antd";
import {useForm} from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";


const ModalViewNewProductAdminSendToRenew = ({callback_open, callback_close, callback_submit, fetchProcess}) => {
    const [form] = useForm()

    return (
        <Modal
            open={callback_open}
            onCancel={callback_close}
            title={'Отправление на доработку'}
            centered
            okButtonProps={{
                style: {display: 'none'}
            }}
            cancelButtonProps={{
                style: {display: 'none'}
            }}
        >
            <Form
                form={form}
                name={'sendToRenewForm'}
                onFinish={callback_submit}
                layout={'vertical'}
                clearOnDestroy
            >
                <Form.Item
                    label={'Описание'}
                    name={'message'}
                    rules={[{required: true}]}
                >
                    <TextArea disabled={fetchProcess} style={{minHeight: '64px', maxHeight: '50vh'}} />
                </Form.Item>
                <Flex justify={'flex-end'}>
                    <Button disabled={fetchProcess} type={'primary'} ghost htmlType={'submit'}>Отправить на доработку</Button>
                </Flex>
            </Form>
        </Modal>
    )
}

export default ModalViewNewProductAdminSendToRenew