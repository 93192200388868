import React from 'react'
import p from '../../../../Web/profile/profile.module.scss'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Statistic, Layout } from 'antd'
const SalesAnalytics = () => {
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_staff_topInfo}>
        <span className="fontSiz_24_500">Аналитика продаж</span>
        <span className="fontSiz_20_500">
          Ваши показатели по этапам воронки
        </span>
      </Layout>
      <Layout className={p.common_mainCard}>
        <Layout className={p.common_mainCard_chaild}>
          <span className="fontSiz_16_500">Первый этап: поиск товара </span>
          <Layout className={p.common_mainCard_chaildflex}>
            <Layout className={p.common_mainCard_chaild_cardStatic}>
              <span className="fontSiz_16_500" style={{ paddingBottom: '6px' }}>
                Показы в поиске и каталоге
              </span>
              <Statistic
                className="staticSale"
                title="3 540 678"
                value={4}
                precision={2}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
              <span className="fontSiz_16_400Blc" style={{ color: '#7B7C7D' }}>
                Неделя к неделе
              </span>
            </Layout>
            <Layout className={p.common_mainCard_chaild_cardStatic}>
              <span className="fontSiz_16_500" style={{ paddingBottom: '6px' }}>
                Средняя позиция в каталоге
              </span>
              <Statistic
                className="staticSale"
                title="126"
                value={4}
                precision={2}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
              <span className="fontSiz_16_400Blc" style={{ color: '#7B7C7D' }}>
                Неделя к неделе
              </span>
            </Layout>
          </Layout>
        </Layout>
        <Layout className={p.common_mainCard_chaild}>
          <span className="fontSiz_16_500">Второй этап: выбор товара </span>
          <Layout className={p.common_mainCard_chaildflex}>
            <Layout className={p.common_mainCard_chaild_cardStatic}>
              <span className="fontSiz_16_500" style={{ paddingBottom: '6px' }}>
                Переходы на ваши карточки
              </span>
              <Statistic
                className="staticSale"
                title="230 765"
                value={4}
                precision={2}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
              <span className="fontSiz_16_400Blc" style={{ color: '#7B7C7D' }}>
                Неделя к неделе
              </span>
            </Layout>
          </Layout>
        </Layout>
        <Layout className={p.common_mainCard_chaild}>
          <span className="fontSiz_16_500">Третий этап: заказ товара </span>
          <Layout className={p.common_mainCard_chaildflex}>
            <Layout className={p.common_mainCard_chaild_cardStatic}>
              <span className="fontSiz_16_500" style={{ paddingBottom: '6px' }}>
                Заказано товаров
              </span>
              <Statistic
                className="staticSale"
                title="250"
                value={4}
                precision={2}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
              <span className="fontSiz_16_400Blc" style={{ color: '#7B7C7D' }}>
                Неделя к неделе
              </span>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <Layout style={{ paddingTop: '16px' }}>
        <span className="fontSiz_20_500">Ваши лидеры по продажам</span>
      </Layout>
    </Layout>
  )
}

export default SalesAnalytics
