import React, {useState} from 'react'
import ca from '../common__Admin.module.scss'
import gca from '../../../Web/GeneralComponentAdmin/GeneralComponentAdmin.module.scss'

import {useNavigate} from 'react-router-dom'
import {Button, Input, Layout, Table, Tag} from 'antd'

const Requests_admin = () => {
  const navigate = useNavigate()
  const { Search } = Input
  const [dataRequestsAd, setDataRequestsAd] = useState([
    {
      key: '1',
      data: 'Дата заявки',
      category: 'Регистрация',
      status: 'Активен',
      term: 'до 09.02.2025',
      name: 'ГК Стройтех',
      role: 'Производитель',
      inn: '123435',
      country: 'РФ',
      website: 'GK.ru',
      fullName: 'Краков Андрей Иванович',
      telephone: '+7 926 000 00 00',
      email: 'stroitex@example.com',
    },
    {
      key: '2',
      data: 'Дата заявки',
      category: 'Регистрация',
      status: 'Активен',
      term: 'до 10.02.2025',
      name: 'ГК Стройтех',
      role: 'Производитель',
      inn: '123435',
      country: 'РФ',
      website: 'GK.ru',
      fullName: 'Краков Андрей Иванович',
      telephone: '+7 926 000 00 00',
      email: 'stroitex@example.com',
    },
    {
      key: '3',
      data: 'Дата заявки',
      category: 'Удаление аккаунта',
      status: 'Активен',
      term: 'до 09.02.2025',
      name: 'ГК Стройтех',
      role: 'Производитель',
      inn: '123435',
      country: 'РФ',
      website: 'GK.ru',
      fullName: 'Краков Андрей Иванович',
      telephone: '+7 926 000 00 00',
      email: 'stroitex@example.com',
    },
  ])
  const [newDataRequestsAd, setNewDataRequestsAd] = useState({
    key: dataRequestsAd.length + 1,
    data: '',
    category: '',
    status: '',
    term: '',
    name: '',
    role: '',
    inn: '',
    country: 'РФ',
    website: '',
    fullName: '',
    telephone: '',
    email: '',
  })
  const columnsRequestsAd = [
    {
      title: 'Дата заявки',
      dataIndex: 'data',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      render: (text) => {
        let color =
          text === 'Регистрация'
            ? 'rgba(61, 138, 209, 0.2)'
            : 'rgba(209, 61, 194, 0.2)'
        let textColor =
          text === 'Регистрация'
            ? 'rgba(61, 138, 209, 1)'
            : 'rgba(209, 61, 194, 1)'
        return (
          <Tag color={color}>
            <span style={{ color: textColor }}>{text}</span>
          </Tag>
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (text) => {
        let color =
          text === 'Активная'
            ? 'rgba(115, 209, 61, 0.2)'
            : 'rgba(240, 243, 245, 1)'
        let textColor =
          text === 'Активная'
            ? 'rgba(115, 209, 61, 1)'
            : 'rgba(123, 124, 125, 1)'
        return (
          <Tag color={color}>
            <span style={{ color: textColor }}>{text}</span>
          </Tag>
        )
      },
    },
    {
      title: 'Срок ',
      dataIndex: 'term',
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'tags',
      render: (text) => {
        let color =
          text.length > 15
            ? 'rgba(85, 102, 255, 0.32)'
            : 'rgba(85, 235, 255, 0.32)'
        let textColor =
          text.length > 15 ? 'rgba(57, 0, 178, 1)' : 'rgba(0, 157, 178, 1)'
        if (text === 'Покупатель') {
          color = 'rgba(255, 0, 245, 0.2)'
          textColor = 'rgba(158, 0, 255, 1)'
        }
        return (
          <Tag color={color} key={'tags'}>
            <span style={{ color: textColor }}>{text.toUpperCase()}</span>
          </Tag>
        )
      },
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
    },
    {
      title: 'Страна',
      dataIndex: 'country',
    },
    {
      title: 'Сайт',
      dataIndex: 'website',
    },
    {
      title: 'ФИО',
      dataIndex: 'fullName',
    },
    {
      title: 'Телефон',
      dataIndex: 'telephone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      fixed: 'right',
    },
  ]
  return (
    <Layout className={ca.common_MainDiv}>
      <Layout className={ca.common_InputGrup}>
        <Search
          suffix
          placeholder="input search text"
          style={{
            width: 200,
          }}
        />
        <Button
          onClick={() => navigate('registrationRequestsAdmin')}
          className={ca.inputGrup__btnAdd}
        >
          Запрос на регистрацию
        </Button>
        <Button
          onClick={() => navigate('deleteRequestsAdmin')}
          className={ca.inputGrup__btnAdd}
        >
          Запрос на удаление аккаунта
        </Button>
      </Layout>
      <Layout className={ca.common_scrollWrapper}>
        <Table
          columns={columnsRequestsAd}
          dataSource={dataRequestsAd}
          className={gca.tableGeneral}
          scroll={{ x: 'max-content' }}
        />
      </Layout>
    </Layout>
  )
}

export default Requests_admin
