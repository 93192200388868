import React, { useState } from 'react'
import p from '../profile.module.scss'
import { Layout, Modal, Button } from 'antd'
const Modal_companyPage = ({
  fetchCompanyModal,
  setOpenModalCompany,
  openModalCompany,
}) => {
  const handleOk = () => {
    setOpenModalCompany(false)
  }
  const handleCancel = () => {
    setOpenModalCompany(false)
  }
  const reviews = [1, 2, 3]
  console.log(fetchCompanyModal, 'fetchModalCompany')
  return (
    <Modal
      // title="Basic Modal"
      className={p.common_modalCompany}
      open={openModalCompany}
      onOk={handleOk}
      onCancel={handleCancel}
      width={'98%'}
      footer={null}
    >
      <Layout className={p.common_modalCompany_titl}>
        <img
          className={p.common_imgCompanyModal}
          src={require('../../../image/logoModalCompany.png')}
          alt="logo"
        />
        <span
          className="fontSiz_32_500"
          style={{ color: 'rgba(141, 36, 248, 1)' }}
        >
          ГК Инструмент: каталог и витрина производителя
        </span>
      </Layout>
      <Layout className={p.common_modalCompany_main}>
        <Layout className={p.common_modalCompany_infoTop}>
          <span className="fontSiz_24_500">О компании</span>
          <Layout>
            <span>
              Комплексные поставки промышленного инструмента и техоснастки.
              Производственный консалтинг.
            </span>
            <span>
              Более 13 лет мы успешно осуществляем комплексные поставки
              промышленного инструмента и техоснастки. На сегодняшний день наша
              компания является одним из лидеров рынка по поставкам инструмента
              в России
            </span>
            <span>
              Наши клиенты — это предприятия, занятые производством в различных
              отраслях промышленности на территории РФ.
            </span>
            <span>
              Производитель оказывает услуги по изготовлению и ремонту
              инструментов
            </span>
            <Button className={p.common_btn_allTOvar} type="tetx">
              Все товары компании
            </Button>
            <Button className={p.common_btn_writeRepres}>
              Написать представителю
            </Button>
          </Layout>
        </Layout>
        <Layout className={p.common_modalCompany_infoCentr}>
          <Layout>
            <span className="fontSiz_24_500" style={{ paddingBottom: '24px' }}>
              Реквизиты
            </span>
            <Layout className={p.common_divForLabel}>
              <Layout className={p.common_labelGrupe}>
                <span>Название компании</span>
                <span>{fetchCompanyModal?.company_name}</span>
              </Layout>
              <Layout className={p.common_labelGrupe}>
                <span>Юридический адрес</span>
                <span>{fetchCompanyModal?.company_address_legal}</span>
              </Layout>
              <Layout className={p.common_labelGrupe}>
                <span>Фактический адрес</span>
                <span>{fetchCompanyModal?.company_address_actual}</span>
              </Layout>
              <Layout className={p.common_labelGrupe}>
                <span>ОГРН</span>
                <span>{fetchCompanyModal?.company_ogrn}</span>
              </Layout>
              <Layout className={p.common_labelGrupe}>
                <span>ИНН</span>
                <span>1157847350</span>
              </Layout>
              <Layout className={p.common_labelGrupe}>
                <span>КПП</span>
                <span>{fetchCompanyModal?.company_kpp}</span>
              </Layout>
            </Layout>
          </Layout>
          <Layout>
            <img src={require('../../../image/mapYan.png')} alt="sd" />
          </Layout>
        </Layout>
        <Layout className={p.common_modalCompany_infoBottom}>
          <span className="fontSiz_24_500">Отзывы </span>
          <Layout className={p.common_reviewsGrupe}>
            {reviews ? (
              reviews.map((el) => {
                return (
                  <Layout className={p.common_reviewsDiv}>
                    <Layout>
                      <span className="fontSiz_16_500">Покупатель</span>
                      <span className="fontSiz_14_400">22.05.2024</span>
                    </Layout>
                    <span className="fontSiz_16_400Blc">
                      Хороший выбор, быстрый ответ.
                    </span>
                  </Layout>
                )
              })
            ) : (
              <span>Покупатели пока не оценили компанию</span>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Modal>
  )
}

export default Modal_companyPage
