import React, { useState } from 'react'
import p from '../../../../Web/profile/profile.module.scss'
import { Button, Input, Radio, Table, Layout } from 'antd'
const Mail = () => {
  const { Search } = Input
  const [choiceMail, setChoiceMail] = useState('Incoming')
  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
    },
    {
      title: 'Тема',
      dataIndex: 'topic',
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      render: (text) => (
        <div>
          <span>Тема письма</span>
          <p>Недавно Вы......</p>
        </div>
      ),
    },
    {
      title: 'Вложения',
      dataIndex: 'Attachments',
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
    {
      title: 'Дата',
      dataIndex: 'data',
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
  ]
  const data = [
    {
      key: '1',
      name: 'EXPOLAND',
      topic: 98,
      Attachments: 60,
      data: 70,
    },
  ]
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra)
  }
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_staff_topInfo}>
        <span className="fontSiz_24_500">Сделки</span>
        <Layout className={p.common_mail_grupBtn}>
          <Radio.Group
            className="radioGrpBtn"
            defaultValue="Incoming"
            buttonStyle="solid"
            onChange={(e) => setChoiceMail(e.target.value)}
          >
            <Radio.Button className="radBtn" value="Incoming">
              Входящие
            </Radio.Button>
            <Radio.Button className="radBtn" value="WithAttachment">
              С вложением
            </Radio.Button>
            <Radio.Button className="radBtn" value="Outgoing">
              Исходящие
            </Radio.Button>
            <Radio.Button className="radBtn" value="Favourites">
              Избранное
            </Radio.Button>
          </Radio.Group>
          <Layout className={p.common_mail_chaildBTN}>
            <Search
              placeholder="Поиск"
              className={`${p.common_searchHead} searchHeadG`}
              //   prefix={<UserOutlined />}
            />
            <Button className={`${p.common_btnCreatMess}`}>
              Создать сообщение
            </Button>
          </Layout>
        </Layout>
      </Layout>
      <Layout className={p.common_boxTableMail}>
        <Table
          className={p.common_table}
          columns={columns}
          dataSource={data}
          onChange={onChange}
        />
      </Layout>
    </Layout>
  )
}

export default Mail
