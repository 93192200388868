import React, {useState, useEffect} from 'react'

import AppRouter from '../../AppRouter/AppRouter'

const App = () => {
    return (
        <AppRouter/>
    )
}

export default App
