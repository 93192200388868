import React, {useState} from 'react'

import ca from '../common__Admin.module.scss'
import gca from '../../../Web/GeneralComponentAdmin/GeneralComponentAdmin.module.scss'

import {ReactComponent as EdditSVG} from '../../../image/Edit_light.svg'

import {useNavigate} from 'react-router-dom'
import {Button, Input, Layout, Table} from 'antd'

const HistoryActions_admin = () => {
  const navigate = useNavigate()
  const { Search } = Input
  const [dataHistoryActionsAd, setDataHistoryActionsAd] = useState([
    {
      key: '1',
      id: '123555',
      data: '23.05.2025',
      account: 'Петров А.В.',
      OldValue: 'Текст',
      NewValue: 'Текст',
    },
  ])
  const [newDataHistoryActionsAd, setNewDataHistoryActionsAd] = useState({
    key: dataHistoryActionsAd.length + 1,
    id: '123555',
    data: '23.05.2025',
    account: 'Петров А.В.',
    OldValue: 'Текст',
    NewValue: 'Текст',
  })
  const columnsHistoryActionsAd = [
    {
      title: '',
      dataIndex: 'red',
      render: (text) => (
        <Button type="text" onClick={() => navigate('#')}>
          <EdditSVG />
        </Button>
      ),
      width: '5%',
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Дата',
      dataIndex: 'data',
    },
    {
      title: 'Аккаунт',
      dataIndex: 'account',
    },
    {
      title: 'Старое значение',
      dataIndex: 'OldValue',
    },
    {
      title: 'Новое значение',
      dataIndex: 'NewValue',
    },
  ]
  return (
    <Layout className={ca.common_MainDiv}>
      <Layout className={ca.common_InputGrup}>
        <Search
          suffix
          placeholder="input search text"
          style={{
            width: 200,
          }}
        />
      </Layout>
      <Layout className={ca.common_scrollWrapper}>
        <Table
          columns={columnsHistoryActionsAd}
          dataSource={dataHistoryActionsAd}
          className={gca.tableGeneral}
          pagination={{
            pageSize: 40,
          }}
          scroll={{ x: 'max-content' }}
        />
      </Layout>
    </Layout>
  )
}

export default HistoryActions_admin
