import React, {useEffect, useState} from 'react'
import {Flex, Typography, Table, Button} from "antd";
import {Link, useSearchParams} from 'react-router-dom'
import d from './Drafts.module.scss'


const Drafts = ({setCurrent}) => {
    const [drafts, setDrafts] = useState([])
    const [searchParams,setSearchParams] = useSearchParams()

    useEffect(() => {
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/trade/draft/get`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error('Ошибка: ',res)
                } else return res
            })
            .then(res => res.json())
            .then(res => {
                setDrafts(res)
            })
    }, []);

    return (
        <Flex vertical gap={'small'}>
            <Typography.Text>Список черновиков</Typography.Text>
            <Table
                className={d.table}
                columns={[
                    {
                        key: '0',
                        title: 'Идентификатор',
                        dataIndex: 'id',
                    },
                    {
                        key: '1',
                        title: 'Тип',
                        dataIndex: 'type'
                    },
                    {
                        key: '2',
                        title: 'Ресурс',
                        dataIndex: 'resource',
                        render: (value) => <Button type={'link'} onClick={() => {
                            setSearchParams({page: 'AddProducts', createType: 'manual', indexId: value });
                        }}>{value}</Button>
                    },
                    {
                        key: '3',
                        title: 'Дата создания',
                        dataIndex: 'created'
                    },
                ]}
                dataSource={drafts}
            />
        </Flex>
    )
}

export default Drafts