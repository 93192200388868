import React from 'react'
import ad from './GeneralComponentAdmin.module.scss'
import HeaderAdmin from '../../components/for_Admin/HeaderAdmin/HeaderAdmin'
import Admin_panel from '../../components/for_Admin/Admin_panel/Admin_panel'
import {Outlet} from 'react-router'
import {ConfigProvider, Flex, Layout} from 'antd'
import {THEME_ADMIN} from "../../Utils/Themes";
import MenuAdmin from "../../components/for_Admin/MenuAdmin/MenuAdmin";
import gca from './GeneralComponentAdmin.module.scss'
import ruRU from 'antd/locale/ru_RU'
import {NotificationProvider} from "../../context/NotificationProvider";

const GeneralComponentAdmin = () => {
    return (
        <ConfigProvider
            locale={ruRU}
            theme={THEME_ADMIN}
        >
            <NotificationProvider>
                <Flex vertical className={gca.container}>
                    <HeaderAdmin/>
                    <Flex className={gca.body}>
                        <MenuAdmin/>
                        <Flex className={gca.outlet}>
                            <Outlet/>
                        </Flex>
                    </Flex>
                </Flex>
            </NotificationProvider>
        </ConfigProvider>
    )
}

export default GeneralComponentAdmin
