import React, { useState, useEffect } from 'react'
import p from '../../../Web/profile/profile.module.scss'
//! ANTD
import { Button, Input, Modal, Layout, message } from 'antd'
import { Asterisk } from 'lucide-react'

import { useDispatch } from 'react-redux'
import { Fetch_GET } from '../../../store/Fetch_GET/Fetch_GET_Slice'
import useFetch_profile from '../hooks/useFetch_profile'
const SellerAccount = ({ fetchSellerAccount, setFetcSellerAccount }) => {
  const sellerAccountResponse = useFetch_profile()
  const dispatch = useDispatch()

  const [isModalDeled, setIsModalDeled] = useState(false)
  const [isModalChangePass, setIsModalChangePass] = useState(false)
  const showModal = () => {
    setIsModalDeled(true)
  }
  const showModalChangePass = () => {
    setIsModalChangePass(true)
  }
  const handleOk = () => {
    setIsModalDeled(false)
  }
  const handleChangePass = () => {
    setIsModalChangePass(false)
  }
  const handleCancel = () => {
    setIsModalDeled(false)
  }
  const handleCancelPss = () => {
    setIsModalChangePass(false)
  }

  const putProf = async () => {
    const tokens = JSON.stringify(localStorage.accessToken)
    const putNew = {
      username: 'a',
      secondName: null,
      firstName: 'a',
      middleName: null,
      job: null,
      icon: null,
    }
    console.log(JSON.stringify(fetchSellerAccount))
    try {
      await fetch(`${process.env.REACT_APP_DOMAIN_BFF}/trade/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokens.replace(/"/g, '')}`,
        },
        body: {
          username: 'a',
          secondName: null,
          firstName: 'a',
          middleName: null,
          job: null,
          icon: null,
        },
      })
        .then((res) => {
          switch (res.status) {
            case 200:
            case 202:
              return res.json()
            case 401:
              message.error('401. Неавторизовано')
              break
            case 403:
              message.warning(`Отказано в доступе: 403`)
              setTimeout(() => navigate('/'), 1000)
              break
            case 404:
              message.error('Ошибка: Not Found')
              break
            default:
              break
          }
        })
        .then((res) => {
          console.log(res)
          setFetcSellerAccount(res)
        })
    } catch (e) {
      message.error('Что-то пошло не так! Мы уже разбираемся в этой ошибке.')
      console.error(e)
    }
  }
  useEffect(() => {
    sellerAccountResponse.get(
      `${process.env.REACT_APP_DOMAIN_BFF}/trade/profile`,
      setFetcSellerAccount
    )
  }, [])
  // useEffect(() => {
  //   dispatch(Fetch_GET({ url: `/trade/profile`, set: 'seller' }))
  // }, [dispatch])
  console.log(fetchSellerAccount)
  return (
    <Layout className={p.mainDiv}>
      <Modal
        width={'80%'}
        style={{ padding: '20px' }}
        className="modalDeled"
        // title="Удаление аккаунта"
        open={isModalDeled}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Layout>
          <Layout
            className="fontSiz_32_500"
            style={{ textAlign: 'center', width: '100%' }}
          >
            Удаление аккаунта
          </Layout>
          <Layout className={p.common_modal_delet}>
            <p className="fontSiz_16_400Blc">
              Уважаемые производители! Для удаления аккаунта отправьте заявку. В
              течение двух рабочих дней Вам придет уведомление о статусе заявки
              на электронную почту.
            </p>
          </Layout>
          <Button
            type="primary"
            className={p.common_btnDelPost}
            onClick={handleOk}
          >
            Отправить заявку
          </Button>
        </Layout>
      </Modal>
      <Modal
        width={'80%'}
        style={{ padding: '20px' }}
        className="modalDeled"
        // title="Удаление аккаунта"
        open={isModalChangePass}
        // onOk={handleOk}
        onCancel={handleCancelPss}
        footer={null}
      >
        <Layout className={p.common_modal_form}>
          <Layout className={p.common_modal_column}>
            <Layout
              className="fontSiz_32_500"
              style={{ textAlign: 'center', width: '100%' }}
            >
              Изменение пароля
            </Layout>
            <Layout className={p.common_modal_changePassForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Старый пароль
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="pass"
                  name="username"
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Новый пароль
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="password"
                  name="username"
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Подтверждение пароля
                  <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="text"
                  name="username"
                  required
                />
              </Layout>
            </Layout>
            <Button
              type="primary"
              className={p.common_modal_column_btnDelPost}
              style={{ width: '100%' }}
              onClick={handleChangePass}
            >
              Отправить заявку
            </Button>
          </Layout>
        </Layout>
      </Modal>
      <span className="fontSiz_24_500">Данные о компании</span>
      <Layout className={p.common_DivAccount}>
        <Layout className={p.common_DivAcc_left}>
          <Layout className={p.common_left_divForm}>
            <Layout className={p.common_chaild_divForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  E-mail <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="E-mail"
                  type="text"
                  name="username"
                  required
                  value={fetchSellerAccount?.firstName}
                  onChange={(e) =>
                    setFetcSellerAccount({
                      ...fetchSellerAccount,
                      firstName: e.target.value,
                    })
                  }
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Страна <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="text"
                  name="username"
                  value={fetchSellerAccount?.job}
                  onChange={(e) =>
                    setFetcSellerAccount({
                      ...fetchSellerAccount,
                      job: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  ИНН <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Имя пользователя"
                  type="text"
                  name="username"
                  value={fetchSellerAccount?.middleName}
                  onChange={(e) =>
                    setFetcSellerAccount({
                      ...fetchSellerAccount,
                      middleName: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
            </Layout>
            <Layout className={p.common_chaild_divForm}>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Сайт
                  <Asterisk color="red" style={{ width: '16px', opacity: 0 }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Сайт"
                  type="text"
                  name="Сайт"
                  value={fetchSellerAccount?.id}
                  onChange={(e) =>
                    setFetcSellerAccount({
                      ...fetchSellerAccount,
                      id: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  ФИО <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="ФИО"
                  type="text"
                  name="ФИО"
                  value={fetchSellerAccount?.secondName}
                  onChange={(e) =>
                    setFetcSellerAccount({
                      ...fetchSellerAccount,
                      secondName: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
              <Layout className={p.common_chaild_divInput}>
                <span className="fontSiz_16_500">
                  Телефон <Asterisk color="red" style={{ width: '16px' }} />
                </span>
                <Input
                  className={p.common_chaild_Input}
                  placeholder="Телефон"
                  type="text"
                  name="Телефон"
                  value={fetchSellerAccount?.username}
                  onChange={(e) =>
                    setFetcSellerAccount({
                      ...fetchSellerAccount,
                      username: e.target.value,
                    })
                  }
                  required
                />
              </Layout>
            </Layout>
          </Layout>
          <Button className={p.common_DivAccount_saveBTN} onClick={putProf}>
            Сохранить
          </Button>
        </Layout>
        <Layout className={p.common_DivAcc_right}>
          <span className="fontSiz_16_400Blc">Управление аккаунтом</span>
          <span className="fontSiz_16_400Blc">
            Вы с нами с 21 Марта 2024 года
          </span>
          <span
            onClick={showModalChangePass}
            className="fontSiz_16_400Blc"
            style={{ color: 'rgb(141, 36, 248)' }}
            type="text"
          >
            Изменить пароль
          </span>
          <span
            className="fontSiz_16_400Blc"
            style={{ color: 'rgb(141, 36, 248)' }}
            type="text"
            onClick={() => localStorage.clear()}
          >
            Выйти из аккаунта
          </span>
          <span
            onClick={showModal}
            className="fontSiz_16_400Blc"
            style={{ color: 'rgb(141, 36, 248)' }}
            type="text"
          >
            Удалить аккаунт
          </span>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default SellerAccount
