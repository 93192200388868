import React from 'react'
import tc from '../GeneralComponent_TngCustomer.module.scss'
import { Layout, Button, Input } from 'antd'
import { Asterisk, LockKeyhole, User } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
const Authorization_tng = () => {
  const navigation = useNavigate()
  return (
    <Layout className={tc.mainDivExit}>
      <Layout className={tc.formDiv}>
        <span className="fontSiz_24_500">Вход </span>
        <form className={tc.form}>
          <Layout className={tc.inputeDiv}>
            <Layout className={tc.inputeGrupe}>
              <span className="fontSiz_16_500">
                E-mail <Asterisk color="red" style={{ width: '16px' }} />
              </span>
              <Input
                className={`${tc.input} inpGlob`}
                placeholder="Имя пользователя"
                type="text"
                name="username"
                // value={user}
                // onChange={(e) => setUser(e.target.value)}
                prefix={<User color="#1890FF" size={16} />}
                required
              />
            </Layout>
            <Layout className={tc.inputeGrupe}>
              <span className="fontSiz_16_500">
                Пароль <Asterisk color="red" style={{ width: '16px' }} />
              </span>
              <Input.Password
                className={`${tc.input} inpGlob`}
                placeholder="Пароль: PASS1234"
                // onChange={(e) => setPass(e.target.value)}
                prefix={<LockKeyhole color="#1890FF" size={16} required />}
              />
            </Layout>
          </Layout>
          <Layout className={tc.rememberPass}>
            <Layout className={tc.chekBoxRemember}>
              <input type="checkbox" name="radio" id="rememb" />
              <label
                htmlFor="rememb"
                style={{ paddingLeft: '8px', color: '#000' }}
                className="fontSiz_16_500"
              >
                Запомнить меня
              </label>
            </Layout>
            <Link
              to="#"
              className="fontSiz_16_500"
              style={{ color: '#8A24F8' }}
            >
              Забыли пароль?
            </Link>
          </Layout>
          <Button
            type="submit"
            className={`${tc.btnExit}`}
            onClick={() => navigation('/tngCustomer')}
          >
            Войти
          </Button>
        </form>
      </Layout>
    </Layout>
  )
}

export default Authorization_tng
