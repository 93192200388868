import React, { useEffect, useState } from 'react'
import s from './profile.module.scss'

//! ANTD
import {Button, Menu, ConfigProvider, message, Layout} from 'antd'
//! COMPONENTS
import SellerAccount from '../../components/for_Seller_Prof/SellerAccount/SellerAccount'
import Company_page from '../../components/for_Seller_Prof/Company_page/Company_page'
import AddProducts from '../../components/for_Seller_Prof/Products/AddProducts/AddProducts'
import HistoryAdding from '../../components/for_Seller_Prof/Products/HistoryAdding/HistoryAdding'
import ListProducts from '../../components/for_Seller_Prof/Products/ListProducts/ListProducts'
import Transactions from '../../components/for_Seller_Prof/Sale/Transactions/Transactions'
import Mail from '../../components/for_Seller_Prof/Sale/mail_sale/Mail'
import SalesAnalytics from '../../components/for_Seller_Prof/Sale/SalesAnalytics/SalesAnalytics'
import ListClients from '../../components/for_Seller_Prof/Sale/List of clients/ListClients'
import Staff from '../../components/for_Seller_Prof/Staff_Seller/Staff_Seller'
import TradingCompanies from '../../components/for_Seller_Prof/TradingCompanies/TradingCompanies'
import useFetch from '../../hooks/useFetch'
import {useSearchParams} from "react-router-dom";
import Drafts from "../../components/for_Seller_Prof/Drafts/Drafts";
const Seller_Profile = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const profileResponse = useFetch()
  const [fetchSellerAccount, setFetcSellerAccount] = useState()
  const [fetchSellerCompany, setFetcSellerCompany] = useState()
  const [fetchSellerStaff, setFetcSellerStaff] = useState()
  const [fetchSellerKanban, setFetcSellerKanban] = useState()
  const onClick = (e) => {
    console.log('click ', e.key)
    setCurrent(e.key)
    setSearchParams({page: e.key})
  }


  const [current, setCurrent] = useState(searchParams.get('page') ?? 'Account')
  const [currentType, setCurrentType] = useState('')
  const [currentDraftId, setCurrentDraftId] = useState('')
  useEffect(() => {
    if (searchParams.get('page') !== current) setCurrent(searchParams.get('page'))
    if (searchParams.get('createType') !== currentType) setCurrentType(searchParams.get('createType'))
    if (searchParams.get('draftId') !== currentDraftId) setCurrentDraftId(searchParams.get('draftId'))
  }, [searchParams])


  const items = [
    {
      label: 'Аккаунт',
      key: 'Account',
      // icon: <MailOutlined />,
    },
    {
      label: 'Страница компании',
      key: 'Company_page',
    },
    {
      label: 'Товары',
      key: 'Products',
      children: [
        {
          label: 'Добавить товары',
          key: 'AddProducts',
        },
        {
          label: 'Список товаров',
          key: 'ListProducts',
        },
        {
          label: 'Список черновиков',
          key: 'Drafts'
        },
        {
          label: 'История добавления',
          key: 'HistoryAdding',
          //   disabled: true,
        },
      ],
    },
    {
      label: 'Продажа',
      key: 'Sale',
      children: [
        {
          label: 'Сделки',
          key: 'Transactions',
        },
        {
          label: 'Отзывы',
          key: 'Reviews',
          disabled: true,
        },
        {
          label: 'Почта',
          key: 'Mail',
        },
        {
          label: 'Списки клиентов',
          key: 'CustomerLists',
        },
        {
          label: 'Аналитика',
          key: 'Analytics',
        },
      ],
      //   disabled: true,
    },
    {
      label: 'Сотрудники',
      key: 'Staff',
    },
    {
      label: 'Поддержка',
      key: 'Support',
      disabled: true,
    },
    {
      label: 'Уведомления',
      key: 'Notifications',
      disabled: true,
      s,
    },
    {
      label: 'Торговые компании',
      key: 'Trading companies',
    },
  ]

  return (
    <Layout className="mainDiv">
      <ConfigProvider
        theme={{
          token: {
            // HorizontalItemHoverColor: 'red',
            // colorText: 'rgb(123, 124, 125)',
          },
          components: {
            Menu: {
              colorBgElevated: 'rgb(255, 255, 255)',
              itemSelectedColor: 'rgb(141, 36, 248)',
              itemHoverColor: 'rgb(141, 36, 248)',
              itemColor: 'rgb(123, 124, 125)',
              horizontalItemSelectedColor: 'rgb(141, 36, 248)',

              // itemActiveBg: 'red',
              // itemSelectedBg: 'rgb(255, 255, 255)',
            },
            Layout: {
              bodyBg: 'white',
            },
          },
        }}
      >
        <Menu
          className={s.mainTabs}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
        <Layout className={s.mainChaild}>
          {current === 'Account' && (
            <SellerAccount
              fetchSellerAccount={fetchSellerAccount}
              setFetcSellerAccount={setFetcSellerAccount}
            />
          )}
          {current === 'Company_page' && (
            <Company_page
              fetchSellerCompany={fetchSellerCompany}
              setFetcSellerCompany={setFetcSellerCompany}
            />
          )}
          {current === 'AddProducts' && <AddProducts currentType={currentType} currentDraftId={currentDraftId}  />}
          {current === 'HistoryAdding' && <HistoryAdding />}
          {current === 'ListProducts' && <ListProducts />}
          {current === 'Transactions' && (
            <Transactions
              fetchSellerKanban={fetchSellerKanban}
              setFetcSellerKanban={setFetcSellerKanban}
            />
          )}
          {current === 'Mail' && <Mail />}
          {current === 'Analytics' && <SalesAnalytics />}
          {current === 'CustomerLists' && <ListClients />}
          {current === 'Staff' && (
            <Staff
              fetchSellerStaff={fetchSellerStaff}
              setFetcSellerStaff={setFetcSellerStaff}
            />
          )}
          {current === 'Trading companies' && <TradingCompanies />}
          {current === 'Drafts' && <Drafts setCurrent={setCurrent} />}
        </Layout>
      </ConfigProvider>
    </Layout>
  )
}

export default Seller_Profile
