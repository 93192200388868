import { configureStore } from "@reduxjs/toolkit";
import categoriesSlice from "./categories/categoriesSlice";
import newProductsSlice from "./newProducts/newProductsSlice";
import transactionsSlice from "./Transactions/transactionsSlice";
import Fetch_GET_Slice from "./Fetch_GET/Fetch_GET_Slice";
import modalReducer from "./modals/modals";
import otherVariablesReducer from "./otherVariables/otherVariables";
export default configureStore({
    reducer: {
        categories: categoriesSlice,
        newProducts: newProductsSlice,
        newTransaction: transactionsSlice,
        Fetch_GET_Slice: Fetch_GET_Slice,
        modals: modalReducer,
        otherVariables: otherVariablesReducer,
    },
});