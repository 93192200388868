import React, {useEffect, useState} from 'react'
import ca from '../common__Admin.module.scss'
import gca from '../../../Web/GeneralComponentAdmin/GeneralComponentAdmin.module.scss'
import {ReactComponent as EdditSVG} from '../../../image/Edit_light.svg'
import {Asterisk} from 'lucide-react'

import {useNavigate} from 'react-router-dom'
import {Button, Input, Layout, Modal, Select, Table, Tag} from 'antd'

const Company_admin = () => {
  const navigate = useNavigate()
  const { Search } = Input
  const [sbrosForm, setSbrosForm] = useState(false)
  const [dataCompanyAd, setDataCompanyAd] = useState([
    {
      key: '1',
      name: 'ГК Стройтех',
      role: 'Производитель',
      status: 'Активен',
      inn: '123435',
      country: 'РФ',
      website: 'GK.ru',
    },
    {
      key: '2',
      name: 'ГК Стройтех',
      role: 'Покупатель',
      status: 'Архив',
      inn: '123435',
      country: 'РФ',
      website: 'GK.ru',
    },
    {
      key: '3',
      name: 'ГК Стройтех',
      role: 'Торговая компания',
      status: 'Активен',
      inn: '123435',
      country: 'РФ',
      website: 'GK.ru',
    },
  ])
  const [newCompanyAd, setNewCompanyAd] = useState({
    key: dataCompanyAd.length + 1,
    name: '',
    role: '',
    status: '',
    inn: '',
    country: '',
    website: '',
  })
  const columnsCompanyAd = [
    {
      title: '',
      dataIndex: 'red',
      render: (text) => (
        <Button type="text" onClick={() => navigate('editingCompanyAdmin')}>
          <EdditSVG />
        </Button>
      ),
      width: '5%',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'tags',
      render: (text) => {
        let color =
          text.length > 15
            ? 'rgba(85, 102, 255, 0.32)'
            : 'rgba(85, 235, 255, 0.32)'
        let textColor =
          text.length > 15 ? 'rgba(57, 0, 178, 1)' : 'rgba(0, 157, 178, 1)'
        if (text === 'Покупатель') {
          color = 'rgba(255, 0, 245, 0.2)'
          textColor = 'rgba(158, 0, 255, 1)'
        }
        return (
          <Tag color={color} key={'tags'}>
            <span style={{ color: textColor }}>{text.toUpperCase()}</span>
          </Tag>
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (text) => {
        let color =
          text === 'Активен'
            ? 'rgba(115, 209, 61, 0.2)'
            : 'rgba(240, 243, 245, 1)'
        let textColor =
          text === 'Активен'
            ? 'rgba(115, 209, 61, 1)'
            : 'rgba(123, 124, 125, 1)'
        return (
          <Tag color={color}>
            <span style={{ color: textColor }}>{text}</span>
          </Tag>
        )
      },
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
    },
    {
      title: 'Страна',
      dataIndex: 'country',
    },
    {
      title: 'Сайт',
      dataIndex: 'website',
    },
  ]
  //!---MODAL start
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    // console.log(newClient)
    setDataCompanyAd([...dataCompanyAd, newCompanyAd])
    setSbrosForm(!sbrosForm)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setSbrosForm(!sbrosForm)
  }
  //!---MODAL end
  useEffect(() => {
    setNewCompanyAd({
      key: dataCompanyAd.length + 1,
      name: '',
      role: '',
      status: '',
      inn: '',
      country: '',
      website: '',
    })
  }, [sbrosForm])
  return (
    <Layout className={ca.common_Div}>
      <Layout className={ca.common_InputGrup}>
        <Search
          suffix
          placeholder="input search text"
          style={{
            width: 200,
          }}
        />
        <Button
          onClick={() => navigate('addCompanyAdmin')}
          className={ca.common__btnAdd}
        >
          Добавить компанию
        </Button>
      </Layout>
      <Layout>
        <Table
          columns={columnsCompanyAd}
          dataSource={dataCompanyAd}
          className={gca.tableGeneral}
        />
        <Modal
          title="Добавить сотрудника"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          // width={'80%'}
        >
          <Layout className={gca.modalContentGeneral}>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Название</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="Название"
                value={newCompanyAd.name}
                required
                onChange={(e) =>
                  setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Роль</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Select
                placeholder="Роль"
                className={gca.modalContent__selectStaff}
                defaultValue="Роль"
                onChange={(val) =>
                  setNewCompanyAd({ ...newCompanyAd, role: val })
                }
                options={[
                  {
                    value: 'Производитель',
                    label: 'Производитель',
                  },
                  {
                    value: 'Покупатель',
                    label: 'Покупатель',
                  },
                  {
                    value: 'Торговая компания',
                    label: 'Торговая компания',
                  },
                ]}
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Статус</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Select
                placeholder="Статус"
                className={gca.modalContent__selectStaff}
                defaultValue="Статус"
                onChange={(val) =>
                  setNewCompanyAd({ ...newCompanyAd, status: val })
                }
                options={[
                  {
                    value: 'Активен',
                    label: 'Активен',
                  },
                  {
                    value: 'Архив',
                    label: 'Архив',
                  },
                ]}
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">ИНН</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="ИНН"
                value={newCompanyAd.inn}
                onChange={(e) =>
                  setNewCompanyAd({ ...newCompanyAd, inn: e.target.value })
                }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Страна</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="Страна"
                value={newCompanyAd.country}
                onChange={(e) =>
                  setNewCompanyAd({ ...newCompanyAd, country: e.target.value })
                }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={gca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Сайт</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                className={gca.modalContent__inptStaff}
                placeholder="Сайт"
                value={newCompanyAd.website}
                onChange={(e) =>
                  setNewCompanyAd({ ...newCompanyAd, website: e.target.value })
                }
              />
            </Layout>
          </Layout>
        </Modal>
      </Layout>
    </Layout>
  )
}

export default Company_admin
