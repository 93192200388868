import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Main from '../Web/Main_page/Main'
import Catalog from '../Web/Catalog/Catalog'
import Help from '../Web/help/Help'
import Seller_Profile from '../Web/profile/Seller_Profile'
import Manually from '../components/for_Seller_Prof/Products/AddProducts/Add/Manually'
import ThroughTemplate from '../components/for_Seller_Prof/Products/AddProducts/Add/ThroughTemplate'
import DownloadingPdfCatalog from '../components/for_Seller_Prof/Products/AddProducts/Add/DownloadingPdfCatalog'
import ViaAPI from '../components/for_Seller_Prof/Products/AddProducts/Add/ViaAPI'
import Manufacturers from '../Web/Manufacturers/Manufacturers'
import Visitors from '../Web/Visitors/Visitors'
import Contacts from '../Web/Contacts/Contacts'
import RequestProduction from '../Web/RequestProduction/RequestProduction'
import RepairRequests from '../Web/App/RepairRequests/RepairRequests'
import CatalogView from '../components/for_Catalog/CatalogView/CatalogView'
import ItemView from '../Web/ItemView/ItemView'
import GeneralComponent from '../Web/GeneralComponent/GeneralComponent'
import CatalogExplorer from '../Web/CatalogExplorer/CatalogExplorer'
import GeneralComponentAdmin from '../Web/GeneralComponentAdmin/GeneralComponentAdmin'
import Analytics_admin from '../components/for_Admin/Analytics_admin/Analytics_admin'
import Requests_admin from '../components/for_Admin/Requests_admin/Requests_admin'
import Company_admin from '../components/for_Admin/Company_admin/Company_admin'
import Staff_admin from '../components/for_Admin/Staff_admin/Staff_admin'
import RepairRequests_admin from '../components/for_Admin/RepairRequests_admin/RepairRequests_admin'
import RequestsProduction_admin from '../components/for_Admin/RequestsProduction_admin/RequestsProduction_admin'
import Orders_admin from '../components/for_Admin/Orders_admin/Orders_admin'
import HistoryActions_admin from '../components/for_Admin/HistoryActions_admin/HistoryActions_admin'
import Add_company_admin from '../components/for_Admin/Company_admin/Add_company_admin/Add_company_admin'
import Editing_company_admin from '../components/for_Admin/Company_admin/Editing_company_admin/Editing_company_admin'
import Registration_requestsAdmin
    from '../components/for_Admin/Requests_admin/Registration_requestsAdmin/Registration_requestsAdmin'
import Delete_requestsAdmin from '../components/for_Admin/Requests_admin/Delete_requestsAdmin/Delete_requestsAdmin'
import EditingAccount_staffAdmin
    from '../components/for_Admin/Staff_admin/EditingAccount_staffAdmin/EditingAccount_staffAdmin'
import Products_admin from '../components/for_Admin/Products_admin/Products_admin'
import EditingProduct_admin from '../components/for_Admin/Products_admin/EditingProduct_admin/EditingProduct_admin'
import Distributor_Profile from '../Web/profile/Distributor_Profile'
import Buyer_Profile from '../Web/profile/Buyer_Profile'
import GeneralComponent_TngCustomer from '../Web/GeneralComponent_TngCustomer/GeneralComponent_TngCustomer'
import Authorization_tng from '../Web/GeneralComponent_TngCustomer/Authorization_tng/Authorization_tng'
import ColorPalettePage from "../Web/TestPages/ColorPalettePage";
import ManufacturerPage from "../Web/ManufacturerPage/ManufacturerPage";
import CategoriesAdmin from "../components/for_Admin/CategoriesAdmin/CategoriesAdmin";
import AddCategoriesAdmin from "../components/for_Admin/AddCategoriesAdmin/AddCategoriesAdmin";
import AddProductsAdmin from "../components/for_Admin/AddProductsAdmin/AddProductsAdmin";
import ViewNewProductAdmin from "../components/for_Admin/ViewNewProductAdmin/ViewNewProductAdmin";

const AppRouter = () => (
    <Routes>
        <Route path={'/'} element={<GeneralComponent/>}>
            <Route index element={<Main/>}/>
            <Route path={'catalog'} element={<Catalog/>}/>
            <Route path={'catalog/:category'} element={<CatalogExplorer/>}/>
            <Route path={'catalog/:category/content'} element={<CatalogView/>}/>
            <Route path={'catalog/:catalogId/content/:itemId'} element={<ItemView/>}/>
            <Route path={'catalog/product/:itemId'} element={<ItemView/>}/>
            <Route path={'help'} element={<Help/>}/>
            <Route path={'profile'} element={<Seller_Profile/>}/>
            //profile/seller
            <Route path={'profile/seller'} element={<Seller_Profile/>}/>
            <Route path={'profile/seller/manually'} element={<Manually/>}/>
            //Distributor_Profile
            <Route path={'/profile/distributer'} element={<Distributor_Profile/>}/>
            //Buyer_Profile
            <Route path={'profile/buyer'} element={<Buyer_Profile/>}/>
            <Route path={'throughTemplate'} element={<ThroughTemplate/>}/>
            <Route path={'viaAPI'} element={<ViaAPI/>}/>
            <Route
                path={'downloadingPdfCatalog'}
                element={<DownloadingPdfCatalog/>}
            />
            {/*toglClass*/}
            <Route path={'manufacturers'} element={<Manufacturers/>}/>
            <Route path={'manufacturer/:manufacturer_id'} element={<ManufacturerPage/>}/>
            <Route path={'manufacturer/:manufacturer_id/goods'} element={<ManufacturerPage page={'goods'} />}/>
            <Route path={'visitors'} element={<Visitors/>}/>
            <Route path={'contacts'} element={<Contacts/>}/>
            <Route path={'requestProduction'} element={<RequestProduction/>}/>
            <Route path={'repairRequests'} element={<RepairRequests/>}/>
            {/*toglClass*/}

            <Route path={'test/color_palette'} element={<ColorPalettePage/>}/>
        </Route>
        <Route path={'profile/admin'} element={<GeneralComponentAdmin/>}>
            <Route index element={<Analytics_admin/>}/>

            <Route path={'categories'} element={<CategoriesAdmin />} />
            <Route path={'addCategories'} element={<AddCategoriesAdmin />} />
            <Route path={'addProducts'} element={<AddProductsAdmin />} />
            <Route path={'addProducts/view/:product_id'} element={<ViewNewProductAdmin />} />

            <Route path={'requestsAdmin'} element={<Requests_admin/>}/>
            <Route path={'requestsAdmin/registrationRequestsAdmin'} element={<Registration_requestsAdmin/>}/>
            <Route path={'requestsAdmin/deleteRequestsAdmin'} element={<Delete_requestsAdmin/>}/>
            <Route path={'companyAdmin'} element={<Company_admin/>}/>
            <Route path={'companyAdmin/addCompanyAdmin'} element={<Add_company_admin/>}/>
            <Route path={'companyAdmin/editingCompanyAdmin'} element={<Editing_company_admin/>}/>
            <Route path={'staffAdmin'} element={<Staff_admin/>}/>
            <Route path={'staffAdmin/editingAccountStaffAdmin'} element={<EditingAccount_staffAdmin/>}/>
            <Route path={'productsAdmin'} element={<Products_admin/>}/>
            <Route path={'productsAdmin/editingProductAdmin'} element={<EditingProduct_admin/>}/>

            <Route path={'repairRequestsAdmin'} element={<RepairRequests_admin/>}/>
            <Route path={'requestsProductionAdmin'} element={<RequestsProduction_admin/>}/>
            <Route path={'ordersAdmin'} element={<Orders_admin/>}/>
            <Route path={'historyActionsAdmin'} element={<HistoryActions_admin/>}/>
        </Route>
        <Route path={'profile/Authorization_tng'} element={<Authorization_tng/>}>GeneralComponent_TngCustomer</Route>
        <Route path={'tngCustomer'} element={<GeneralComponent_TngCustomer/>}/>
    </Routes>
)
export default AppRouter
