import {theme} from "antd";

export const THEME_GENERAL = {
    algorithm: theme.defaultAlgorithm,
    cssVar: true,
    token: {
        // colorTextBase: 'black',
        // colorBgBody: '#f5f5f5',
        // colorBgContainer: '#ffffff',
    },
    components: {
        Statistic: {
            titleFontSize: 24,
            contentFontSize: 16,
            algorithm: true,
        },
        Select: {
            colorBorder: '#7B7C7D',
            algorithm: true,
        },
        Table: {
            headerBg: '#F0F3F5',
            rowHoverBg: '#F0F3F5',
            lineWidth: 0,
            // borderColor: '#FAFAFB',

            // headerBorderRadius: '10px',
            // colorBorderSecondary: 'red',
            algorithm: true,
        },
        Input: {
            colorBgContainer: 'transparent',
            colorBorder: '#7B7C7D',
            colorPrimaryHover: '#7B7C7D',
            // colorText: '#fff',
            borderRadius: '8px',
            fontSize: '16px',
            paddingBlock: 6,
            algorithm: true,
        },
        Button: {
            // colorLink: '#8D24F8',
            defaultHoverColor: '#8D24F8',
            defaultHoverBg: '#8D24F8',
            colorPrimary: '#8D24F8',
            colorPrimaryHover: '#DCFF00',
            primaryColor: '#FFFFFF',
            colorPrimaryTextHover: '#0B0B0C',

            algorithm: true,
        },
    },
}

export const THEME_TEST = {
    algorithm: theme.defaultAlgorithm,
    cssVar: true,
    token: {

    },
    components: {
        Checkbox: {
            colorPrimary: '#8D24F8',
            colorPrimaryHover: '#8D24F8'
        },
        Input: {
            colorBgContainer: 'transparent',
            colorBorder: '#7B7C7D',
            colorPrimaryHover: '#8D24F8',

            // colorText: '#fff',
            borderRadius: '8px',
            fontSize: '16px',
            paddingBlock: 6,
        },
        Button: {
            // defaultHoverColor: '#8D24F8',
            // //primary
            // primaryColor: '',
            // colorPrimary: '',
            //
            // //secondary (default)
            // defaultColor: '#FFFFFF',
            // defaultBg: '#8D24F8',
            // defaultHoverBg: '#DCFF00',
            // defaultHoverColor: '',
            // defaultHoverBorderColor: '#8D24F8',
            // // defaultHoverColor: '#8D24F8',
            // // defaultHoverBg: '#8D24F8',
            // //third (ghost)
            // defaultGhostColor: "#0B0B0C",
            // defaultGhostBorderColor: "#0B0B0C",
            // //link
            //
            //
            // // colorPrimary: '#8D24F8',
            // // colorPrimaryHover: '#DCFF00',
            // // primaryColor: '#FFFFFF',
            // // colorPrimaryTextHover: '#0B0B0C',

            algorithm: true,
        }
    }
}

export const THEME_ADMIN = {
    components: {
        Menu: {
            itemActiveBg: '#8D24F8',
            itemBg: 'white',
            itemColor: 'black',
            itemHoverBg: 'rgba(141, 36, 248, 0.2)',
            itemSelectedBg: 'rgba(141, 36, 248, 0.2)',
            itemSelectedColor: '#8D24F8',
            controlHeightLG: '48px',
        },
        Input: {
            activeBorderColor: 'rgba(141, 36, 248, 1)',
            hoverBorderColor: 'rgba(141, 36, 248, 1)',
            colorBorder: 'rgba(123, 124, 125, 1)',
            colorFillSecondary: 'red',
            borderRadius: 12,
        },
        Table: {
            borderColor: 'none',
            headerBg: 'rgba(240, 243, 245, 1)',
            colorBgContainer: 'rgba(250, 250, 251, 1)',
            headerBorderRadius: 0,
        },
        Select: {
            colorBorder: 'rgba(123, 124, 125, 1)',
            colorPrimary: 'rgba(141, 36, 248, 1)',
            colorPrimaryHover: 'rgba(141, 36, 248, 1)',
            optionActiveBg: '#e8d3fe',
            optionSelectedBg: '#eee0fda8',
            borderRadius: 12,
        },
        Checkbox: {
            colorPrimary: 'rgba(141, 36, 248, 1)',
            colorPrimaryHover: 'rgba(141, 36, 248, 1)',
        },
        Radio: {
            colorPrimary: 'rgba(141, 36, 248, 1)',
        },
        Badge: {
            textFontSize: '12px',
            indicatorHeight: 20,
        },
    }
}

