import React, { useState } from 'react'
import p from '../../../../Web/profile/profile.module.scss'
import { useSelector } from 'react-redux'

import { Button, Input, Radio, Layout, Select, ConfigProvider } from 'antd'

import Kanban from './Kanban/Kanban'
import List from './List/List'
import Archive from './Archiv/Archive'
const Transactions = ({ setFetcSellerKanban, fetchSellerKanban }) => {
  const { Search } = Input
  const { cardTransaction } = useSelector(
    ({ newTransaction }) => newTransaction
  )
  const [choiceList, setChoosing] = useState('Kanban')
  console.log(cardTransaction)
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_staff_topInfo}>
        <span className="fontSiz_24_500">Сделки</span>
        <Radio.Group
          className="radioGrpBtn"
          defaultValue="Kanban"
          buttonStyle="solid"
          onChange={(e) => setChoosing(e.target.value)}
        >
          <Radio.Button className="radBtn" value="Kanban">
            Канбан
          </Radio.Button>
          <Radio.Button className="radBtn" value="List">
            Список
          </Radio.Button>
          <Radio.Button className="radBtn" value="Archive">
            Архив
          </Radio.Button>
        </Radio.Group>
        {/* <Search
          placeholder="Поиск"
          className={`${s.searchHead} searchHeadG`}
          //   prefix={<UserOutlined />}
        /> */}
      </Layout>
      <Layout className={p.common_wrapper}>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                colorBorder: 'rgba(123, 124, 125, 1)',
                colorPrimary: 'rgba(141, 36, 248, 1)',
                colorPrimaryHover: 'rgba(141, 36, 248, 1)',
                optionActiveBg: '#e8d3fe',
                optionSelectedBg: '#eee0fda8',
                borderRadius: 8,
              },
            },
          }}
        >
          {choiceList === 'Kanban' && (
            <Kanban
              cardTransaction={cardTransaction}
              fetchSellerKanban={fetchSellerKanban}
              setFetcSellerKanban={setFetcSellerKanban}
            />
          )}
          {choiceList === 'List' && <List />}
          {choiceList === 'Archive' && <Archive />}
        </ConfigProvider>
      </Layout>
    </Layout>
  )
}

export default Transactions
