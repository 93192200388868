import React from 'react'
import CatalogExplorer from '../../../Web/CatalogExplorer/CatalogExplorer'
import { Layout, Skeleton } from 'antd'
import cei from './CatalogExplorerItem.module.scss'
import { Link } from 'react-router-dom'

const CatalogExplorerItem = ({ imageUrl, element }) => {
  console.log(element)
  return (
    <>
      {element?.count === 0 && element?.inner.length === 0 ? (
        ''
      ) : (
        <Link
          to={`/catalog/${element?.full_path?.slice(1).join('+')}${
            element?.count > 0 && element?.inner.length === 0 ? '/content' : ''
          }`}
        >
          <Layout className={cei.gridItem}>
            <Layout className={cei.gridItemPictureBlock}>
              {element.img_url === undefined ? (
                <Skeleton.Image
                  active
                  style={{ height: '100%' }}
                  className={cei.gridItemPicture}
                ></Skeleton.Image>
              ) : (
                <img
                  src={`${process.env.REACT_APP_DOMAIN_CDN}${element.img_url}`}
                  className={cei.gridItemPicture}
                />
              )}
            </Layout>
            <Layout className={cei.gridItemDescription}>
              <span className={cei.gridItemName}>
                {element?.alias?.toLowerCase()}
              </span>
              <span className={cei.gridItemCount}>
                {element?.count} товаров
              </span>
            </Layout>
          </Layout>
        </Link>
      )}
    </>
  )
}

export default CatalogExplorerItem
