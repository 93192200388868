import {useEffect} from 'react'
import * as THREE from 'three'
import {useThree} from '@react-three/fiber'
import {directionalLight} from '@react-three/drei';

const SettingsScene = () => {
    
    console.log('SettingsScene')
    const { gl, camera, scene } = useThree();
    useEffect(() => {
        camera.up.set(0,0,1)
        console.log(camera)
    camera.position.set(165.2,-156.2,87.2)
        scene.background = new THREE.Color(0xFFFFFF); //TODO: цвет меняется здесь!! background
    },[])
	return  (
        <>
    <directionalLight position={[-165.2,-156.2,87.2]} intensity={0.5}  />
    <directionalLight position={[165.2,156.2,-87.2]} intensity={0.5}  />
    <directionalLight position={[165.2,-156.2,-87.2]} intensity={0.5}  />
    <directionalLight position={[165.2,156.2,87.2]} intensity={0.5}  />
        </>

    )
}

export default SettingsScene