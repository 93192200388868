import React, { useState } from 'react'
import p from '../../../Web/profile/profile.module.scss'

import { Button, Input, Select, Layout } from 'antd'
import GrupLogoCompany from '../../../image/grupCompany.svg'
const CompanyPage_Buyer = () => {
  const [fileIMG, setFileIMG] = useState(GrupLogoCompany)
  const [textDescription, setTextDescription] =
    useState(`Более 13 лет мы успешно осуществляем комплексные поставки промышленного инструмента и техоснастки. На сегодняшний день наша компания является одним из лидеров рынка по поставкам инструмента в России.
  Наши клиенты — это предприятия, занятые производством в различных отраслях промышленности на территории РФ.`)
  const { TextArea } = Input
  function handleChange(e) {
    console.log(e.target.files)
    setFileIMG(URL.createObjectURL(e.target.files[0]))
  }
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_company_topInfo}>
        <span className="fontSiz_24_500">Страница компании</span>
        <Button>Предварительный просмотр</Button>
      </Layout>
      <Layout className={p.common_company_formInfo}>
        <span className="fontSiz_16_400Blc">
          Страница о Вашей компании доступна для поиска и просмотра всем
          посетителям маркетплейса. Данные платформа взяла с Вашего сайта. Вы
          можете редактировать информацию по Вашему усмотрению.
        </span>
        <span className="fontSiz_16_500">Логотип</span>
        <img src={fileIMG} className={p.common_company_imgLogo} />
        <label className={p.common_company_labelFile}>
          <span className="fontSiz_16_400Blc">Загрузить другой</span>
          <input type="file" onChange={handleChange} />
        </label>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Название компании</span>
          <Input
            className={p.common_chaild_Input}
            // placeholder="Имя пользователя"
            allowClear
            type="text"
            name="companyname"
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Описание</span>
          <TextArea
            className={p.common_chaild_inputArea}
            placeholder="textarea with clear icon"
            value={textDescription}
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Юридический адрес</span>
          <Input
            className={p.common_chaild_Input}
            // placeholder="Имя пользователя"
            allowClear
            type="text"
            name="urAdress"
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">Фактический адрес</span>
          <Input
            className={p.common_chaild_Input}
            // placeholder="Имя пользователя"
            allowClear
            type="text"
            name="facAdress"
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">ОГРН</span>
          <Input
            className={p.common_chaild_Input}
            // placeholder="Имя пользователя"
            allowClear
            type="text"
            name="ogrn"
          />
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">КПП</span>
          <Input
            className={p.common_chaild_Input}
            // placeholder="Имя пользователя"
            allowClear
            type="text"
            name="kpp"
          />
        </Layout>
        <Layout className={p.common_chaild_divCheakBox}>
          <input type="checkbox" name="radio" id="rememb" />
          <label
            htmlFor="rememb"
            style={{ paddingLeft: '8px', color: '#000' }}
            className="fontSiz_16_400Blc"
          >
            Готовы принимать заказы на изготовления инструмента и оснастки
          </label>
        </Layout>
        <Layout className={p.common_chaild_divInput}>
          <span className="fontSiz_16_400Blc">
            Категории, которые Вы можете изготавливать
          </span>

          <Select
            defaultValue="lucy"
            style={{
              width: '100%',
              height: 40,
            }}
            className="selectCompany"
            // onChange={handleChange}
            options={[
              {
                value: 'jack',
                label: 'Jack',
              },
              {
                value: 'lucy',
                label: 'Lucy',
              },
              {
                value: 'Yiminghe',
                label: 'yiminghe',
              },
              {
                value: 'disabled',
                label: 'Disabled',
                disabled: true,
              },
            ]}
          />
        </Layout>
        <Layout className={p.common_chaild_divCheakBox}>
          <input type="checkbox" name="radio" id="rememb" />
          <label
            htmlFor="rememb"
            style={{ paddingLeft: '8px', color: '#000' }}
            className="fontSiz_16_400Blc"
          >
            Готовы оказывать услуги по ремонту инструмента и оснастки
          </label>
        </Layout>
        <Button className={p.common_company_btnSave}>Сохранить</Button>
      </Layout>
    </Layout>
  )
}

export default CompanyPage_Buyer
