import React, {useRef, useState, useEffect, useCallback} from 'react';
import {LoadStep} from './StepLoader';
import {Canvas, useThree, useLoader} from '@react-three/fiber';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import {message} from 'antd'


function StlLoader({url, setIsLoaded}) {
    const geom = useLoader(STLLoader, url ?? "");
    const ref = useRef();

    setIsLoaded(true)

    return (
        <mesh name='StlObject' ref={ref}>
            <primitive object={geom} attach="geometry"/>
            <meshStandardMaterial color={"orange"}/>
        </mesh>
    );
}

export default StlLoader