import React, {useCallback, useEffect, useState} from 'react'
import s from './_add.module.css'
import {
    Button,
    Flex,
    Form,
    Input,
    InputNumber,
    Layout,
    Radio,
    Select,
    Space,
    Steps,
    Typography,
    Upload,
    message,
    Spin, notification
} from "antd";
import a from './Add.module.scss'
import am from './AddManually.module.scss'
import {useForm} from "antd/lib/form/Form";
import debounce from 'lodash/debounce'
import {AimOutlined, LeftOutlined, MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {FileService} from "../../../../../services/FileService";
import {useNavigate, useOutletContext, useSearchParams} from "react-router-dom";
import {useGeneralNotificationContext} from "../../../../../Web/GeneralComponent/GeneralComponent";
import {useNotificationContext} from "../../../../../context/NotificationProvider";


const Manually = () => {
    const [draftData, setDraftData] = useState(null)
    const [itemUuid, setItemUuid] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        if (!!!itemUuid && !!!draftData && !!!searchParams.get('draftId'))
            fetch(`${process.env.REACT_APP_DOMAIN_BFF}/trade/draft/create/product`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status !== 200) {
                        message.error('')
                        throw new Error('Ошибка!!!!!!')
                    } else return res
                })
                .then(res => res.json())
                .then(res => {
                    console.log(res.id)
                    setItemUuid(res.id)
                })
        else if (!!searchParams.get('draftId')) setItemUuid(searchParams.get('draftId'))
    }, []);

    useEffect(() => {
        if (!!searchParams.get('indexId')) setItemUuid(searchParams.get('indexId'))
    }, [searchParams]);

    useEffect(() => {
        if (!!itemUuid)
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/trade/draft/get/product/${itemUuid}/content`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(res.status)
                } else return res
            })
            .then(res => res.json())
            .then(res => {
                setDraftData(res)
            })
            .catch(e => {
                console.log(e)
            })
    }, [itemUuid]);

    const [firstStepResult, setFirstStepResult] = useState(null)
    const [secondStepResult, setSecondStepResult] = useState(null)
    const [thirdStepResult, setThirdStepResult] = useState(null)
    const [fourthStepResult, setFourthStepResult] = useState(null)

    const [stepsResult, setStepsResult] = useState(null)

    useEffect(() => {
        form.setFieldsValue({
            product_price: 0,
            product_tax: '10',
            product_order_minimum: 1,
            product_package_count: 1,
            product_isSeparability: 'false',
            product_weight: 0,
            product_size_x: 0,
            product_size_y: 0,
            product_size_z: 0,
            product_availability: 'true',
            product_quantity_available: 0,
            product_warranty: 0
        })

        if (!!draftData) {
            const getPureObject = (obj) => !obj ? {} : Object.fromEntries(Object.entries(obj).filter(([_, value]) => !!value))

            setStepsResult({...draftData})
            form.setFieldsValue(Object.assign({},getPureObject(draftData.first), getPureObject(draftData.second), getPureObject(draftData.third), getPureObject(draftData.fourth)))
        }
    }, [draftData]);

    useEffect(() => {
        setStepsResult({
            first: firstStepResult,
            second: secondStepResult,
            third: thirdStepResult,
            fourth: fourthStepResult
        })
    }, [firstStepResult, secondStepResult, thirdStepResult, fourthStepResult])

    useEffect(() => {
        console.log(firstStepResult)
    }, [firstStepResult])

    const updateDocument = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/trade/draft/update/product/${itemUuid}`, {
            method: 'PUT',
            body: JSON.stringify(stepsResult),
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error(`Ошибка! ${res.status}`)
                } else {
                    return res
                }
            })
            .then(res => res.json())
            .then(res => {
                openNotification('info','Сохранено!', 'Текущая версия черновика товара сохранена')
                return res
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        return
        console.log('Итоговое: ',stepsResult)
        if (itemUuid && stepsResult) {
            console.log('UUID => ',itemUuid)
            fetch(`${process.env.REACT_APP_DOMAIN_BFF}/trade/draft/update/product/${itemUuid}`, {
                method: 'PUT',
                body: JSON.stringify(stepsResult),
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.status !== 200) {
                        message.error(`Ошибка! ${res.status}`)
                    } else {
                        return res
                    }
                })
                .then(res => res.json())
                .then(res => {
                    return res
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }, [stepsResult]);

    const [form] = useForm();

    const submitStep = (values, step) => {
        switch (step) {
            case 0:
                setFirstStepResult(values)
                break;
            case 1:
                setSecondStepResult(values)
                break;
            case 2:
                setThirdStepResult(values)
                break;
            case 3:
                setFourthStepResult(values)
                break;
            default:
                return;
        }
        setCurrentStep(step + 1)
    }

    const [first_countries, setFirst_countries] = useState([])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/trade/diary/country`, {
            method: 'GET'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error('Произошла ошибка получения стран! Мы уже в этом разбираемся')
                } else return res
            })
            .then(res => res.json())
            .then(res => {
                setFirst_countries(res)
            })
    }, [])

    const [first_isSearching_category, setFirst_isSearching_category] = useState(false)
    const [first_searchPrompt_category, setFirst_searchPrompt_category] = useState('')
    const [first_searchResult_category, setFirst_searchResult_category] = useState([])
    const first_handleSearch_category = useCallback(debounce((value) => {
        fetch(`${process.env.REACT_APP_DOMAIN_BFF}/search/?alias=${encodeURIComponent(value)}`, {
            method: 'GET'
        })
            .then(res => {
                if (res.status !== 200) {
                    message.error('Произошла ошибка! Мы уже в этом разбираемся')
                } else return res
            })
            .then(res => res.json())
            .then(res => {
                setFirst_searchResult_category(res.categories.filter(f => f.alias !== 'Главная').map((category) => ({
                    key: category.alias,
                    label: <Flex align={'center'} gap={'small'}><div className={am.categoryImage} style={{backgroundImage: `url(${process.env.REACT_APP_DOMAIN_CDN}${category.image})`}}></div>{category.alias}</Flex>,
                    value: `${category.alias}`
                })))
                setFirst_isSearching_category(false)
            })
            .catch(e => {
                message.error(`Ошибка! ${e}`)
            })
    }, 300), [])
    useEffect(() => {
        first_handleSearch_category(first_searchPrompt_category)
    }, [first_searchPrompt_category]);

    const [currentStep, setCurrentStep] = useState(0)

    const { openNotification } = useNotificationContext()

    useEffect(() => {
        if (currentStep >= 4) {
            setTimeout(() => {
                // api.open({
                //     message: 'я какаю',
                //     description: 'ANUSSS',
                //     showProgress: true,
                //     pauseOnHover: true,
                // })

                openNotification('success','Успешно!', 'Ваша заявка отправлена администратору платформы')

                setSearchParams({page: 'ListProducts'})

            }, 2000)
        }
    }, [currentStep])


    // const [api, contextHolder] = notification.useNotification()

    const steps = [
        {
            key: 0,
            title: 'Информация о товаре',
            content:
                <Form
                    name={'first_step_form'}
                    style={{width: '854px'}}
                    form={form}
                    layout={'vertical'}
                    variant={'outlined'}
                    onFinish={(values) => { submitStep(values, 0) }}
                >
                    <Form.Item
                        className={am.formItem}
                        label={'Наименование'}
                        name={'product_name'}
                        rules={[{required: true}]}
                    >
                        <Input placeholder={'Наименование'}/>
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Страна производства'}
                        name={'product_country'}
                        rules={[{required: true}]}
                    >
                        <Select
                            optionFilterProp="label"
                            showSearch
                            options={first_countries?.sort((a,b) => {
                                return a.short_name.toLowerCase() < b.short_name.toLowerCase() ? -1 : a.short_name.toLowerCase() > b.short_name.toLowerCase() ? 1 : 0
                            })?.map(country => ({
                                key: country.id,
                                label: country.full_name ?? country.short_name,
                                value: country.id
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Артикул'}
                        name={'product_article'}
                        rules={[{required: true}]}
                    >
                        <Input placeholder={'Артикул'}/>
                    </Form.Item>
                    <Form.Item style={{width: '100%'}}
                               className={am.formItem}
                               label={'Категория'}
                               name={'product_category'}
                               rules={[{required: true}]}
                    >
                        <Flex gap={'small'}>
                            <Select
                                allowClear
                                loading={first_isSearching_category}
                                options={first_searchResult_category}
                                showSearch
                                onChange={(value) => {form.setFieldsValue({
                                    product_category: value
                                })}}
                                onSearch={(value) => {
                                    setFirst_isSearching_category(true)
                                    setFirst_searchPrompt_category(value ?? '')
                                }}
                            />
                            <Button ghost>Категории нет в списке</Button>
                        </Flex>
                    </Form.Item>
                    <Flex justify={'flex-end'} gap={'small'}>
                        <Button onClick={() => { updateDocument() }}>Сохранить</Button>
                        <Button htmlType={'submit'}>Далее</Button>
                    </Flex>
                </Form>
        },
        {
            key: 1,
            title: 'Дополнительная информация',
            content:
                <Form
                    name={'second_step_form'}
                    style={{width: '854px'}}
                    form={form}
                    layout={'vertical'}
                    variant={'outlined'}
                    onFinish={(values) => { submitStep(values, 1) }}
                >
                    <Form.Item
                        className={am.formItem}
                        label={'Описание'}
                        name={'product_description'}
                        rules={[{required: false}]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Изображения'}
                        name={'product_images'}
                        rules={[{required: false}]}
                    >
                        <Upload
                            action={`${process.env.REACT_APP_DOMAIN_CDN}/content/raw/img/png/upload`}
                            listType="picture-card"
                        >
                            <button
                                style={{
                                    border: 0,
                                    background: 'none',
                                }}
                                type="button"
                            >
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'3D-модель'}
                        name={'product_model3d'}
                        rules={[{required: false}]}
                    >

                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'2D-модель'}
                        name={'product_model2d'}
                        rules={[{required: false}]}
                    >

                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Лицензия / сертификат'}
                        name={'product_certificate'}
                        rules={[{required: false}]}
                    >

                    </Form.Item>
                    <Flex justify={'space-between'}>
                        <Button onClick={() => { setCurrentStep(currentStep - 1) }}>
                            Назад
                        </Button>
                        <Button onClick={() => { updateDocument() }}>Сохранить</Button>
                        <Button htmlType={'submit'}>Далее</Button>
                    </Flex>
                </Form>
        },
        {
            key: 2,
            title: 'Данные товара',
            content:
                <Form
                    name={'third_step_form'}
                    style={{width: '854px'}}
                    form={form}
                    layout={'vertical'}
                    variant={'outlined'}
                    onFinish={(values) => { submitStep(values, 2) }}
                >
                    <Form.Item
                        className={am.formItem}
                        label={'Цена, ₽'}
                        name={'product_price'}
                        rules={[{
                            required: false,
                            type: 'number',
                            min: 0,
                        }]}
                    >
                        <InputNumber
                            defaultValue={'0'}
                            formatter={(value) => `${value} ₽`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            min={'0'}
                            step={'0.01'} />
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'НДС (налог на добавочную стоимость)'}
                        name={'product_tax'}
                        rules={[{required: false}]}
                    >
                        <Radio.Group>
                            <Radio value={'10'}>10%</Radio>
                            <Radio value={'20'}>20%</Radio>
                            <Radio value={'0'}>Налог не облагается</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Минимальный заказ, шт'}
                        name={'product_order_minimum'}
                        rules={[{required: false,
                            type: 'number',
                            min: 1,
                        }]}
                    >
                        <InputNumber min={'1'} />
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Количество в упаковке, шт'}
                        name={'product_package_count'}
                        rules={[{required: false,
                            type: 'number',
                            min: 0,
                        }]}
                    >
                        <InputNumber min={'0'} step={'1'} />
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Делимость упаковки'}
                        name={'product_isSeparability'}
                        rules={[{required: false}]}
                    >
                        <Radio.Group>
                            <Radio value={'true'}>Да</Radio>
                            <Radio value={'false'}>Нет</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Вес, кг'}
                        name={'product_weight'}
                        rules={[{required: false,
                            type: 'number',
                            min: 0,
                        }]}
                    >
                        <InputNumber min={'0'} step={'0.001'}  />
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Длина/ширина/высота упаковки, см'}
                        rules={[{required: false, type: 'number'}]}
                    >
                        <Flex>
                            <Form.Item name={'product_size_x'}>
                                <InputNumber min={'0'} step={'0.001'} placeholder={'Длина'} />
                            </Form.Item>
                            <Form.Item name={'product_size_y'}>
                                <InputNumber min={'0'} step={'0.001'} placeholder={'Ширина'} />
                            </Form.Item>
                            <Form.Item name={'product_size_z'}>
                                <InputNumber min={'0'} step={'0.001'} placeholder={'Высота'} />
                            </Form.Item>
                        </Flex>
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Наличие'}
                        name={'product_availability'}
                        rules={[{required: false}]}
                    >
                        <Radio.Group>
                            <Radio value={'true'}>В наличии</Radio>
                            <Radio value={'false'}>Под заказ</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Количество в наличии, шт'}
                        name={'product_quantity_available'}
                        rules={[{required: false}]}
                    >
                        <InputNumber min={'0'} step={'1'} />
                    </Form.Item>
                    <Form.Item
                        className={am.formItem}
                        label={'Гарантийный срок, лет'}
                        name={'product_warranty'}
                        rules={[{required: false}]}
                    >
                        <InputNumber min={'0'} step={'0.01'} />
                    </Form.Item>
                    <Flex justify={'space-between'}>
                        <Button onClick={() => { setCurrentStep(currentStep - 1) }}>
                            Назад
                        </Button>
                        <Button onClick={() => { updateDocument() }}>Сохранить</Button>
                        <Button htmlType={'submit'}>Далее</Button>
                    </Flex>
                </Form>
        },
        {
            key: 3,
            title: 'Технические характеристики',
            content:
                <Form
                    className={am.Form}
                    style={{width: '856px'}}
                    name={'fourth_step_form'}
                    form={form}
                    layout={'vertical'}
                    variant={'outlined'}
                    onFinish={(values) => { submitStep(values, 3) }}
                    disabled={currentStep > 3}
                >
                    <Form.List name={'characteristics'}>
                        {(fields, { add, remove }) => (
                            <>
                                <Flex vertical>
                                    {fields.map(({key, name, ...restField}) => (
                                        <Space
                                            key={key}
                                            align={'baseline'}
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'key']}
                                                rules={[{
                                                    required: true,
                                                    message: 'Пропущено название параметра'
                                                }]}
                                            >
                                                <Input placeholder={'Параметр'} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{
                                                    required: true,
                                                    message: 'Пропущено значение параметра'
                                                }]}
                                            >
                                                <Input placeholder={'Значение'} />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                </Flex>
                                <Flex gap={'small'} justify={'space-between'}>
                                    <Flex gap={'small'}>
                                        <Button onClick={() => { setCurrentStep(currentStep - 1) }}>
                                            Назад
                                        </Button>
                                        <Form.Item>
                                            <Button ghost onClick={() => add()} block icon={<PlusOutlined />}>
                                                Добавить параметр
                                            </Button>
                                        </Form.Item>
                                    </Flex>
                                    <Button onClick={() => { updateDocument() }}>Сохранить</Button>
                                    <Button htmlType={'submit'}>Отправить на модерацию</Button>
                                </Flex>
                            </>
                        )}
                    </Form.List>
                </Form>
        },
    ]

    return (
        <Flex vertical className={a.container}>
            <Flex justify={'space-between'}>
                <Button icon={<LeftOutlined />} type={'text'} onClick={() => {}}>Назад к выбору типа добавления товаров</Button>
                <Button icon={<QuestionCircleOutlined />} type={'text'} onClick={() => {}}>Правила добавления товаров</Button>
            </Flex>
            <Flex vertical>
                <Typography.Text>Создание товара вручную</Typography.Text>
                <Steps
                    size={'small'}
                    style={{width: '854px'}}
                    // onChange={(value) => { setCurrentStep(value) }}
                    direction={'horizontal'}
                    current={currentStep}
                    items={steps}
                />
                <Flex>
                    {currentStep >= 4 &&
                        <Flex style={{position: 'absolute'}} className={am.sendSpin}>
                            <Spin size={'large'} />
                        </Flex>
                    }
                    {steps[currentStep < 4 ? currentStep : 3].content}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Manually
