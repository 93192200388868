import React, {useEffect, useRef, useState} from 'react'
import adh from './HeaderAdmin.module.scss'
import {DownOutlined,} from '@ant-design/icons'
import {Flex, Typography} from 'antd'
import logo from '../../../image/logo_full.svg'
import {Link, useNavigate} from 'react-router-dom'
import icon_admin_logout from '../../../image/icon_admin_logout.svg'
import icon_admin_marketplace from '../../../image/icon_admin_marketplace.svg'
import {AuthService} from "../../../services/AuthService";

const HeaderAdmin = () => {
    const [userContextMenuCollapsed, setUserContextMenuCollapsed] = useState(true)
    const userContextMenuRef = useRef()

    const handleClickOutsideContextMenu = (event) => {
        if (userContextMenuRef.current && !userContextMenuRef.current.contains(event.target)) setUserContextMenuCollapsed(true)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideContextMenu)
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideContextMenu)
        }
    }, [])

    const navigate = useNavigate()

    return (
        <Flex className={adh.headerMainDiv}>
            <Flex className={adh.rightSide}>
                <div className={adh.logo} style={{backgroundImage: `url(${logo})`}}></div>
            </Flex>
            <Flex className={adh.leftSide}>
                <Flex className={adh.userBlock} ref={userContextMenuRef}>
                    <Flex onClick={() => {
                        setUserContextMenuCollapsed(!userContextMenuCollapsed)
                    }} className={adh.userButton}>
                        <div className={adh.userImage}></div>
                        <Typography.Text className={adh.userName}>Админ</Typography.Text>
                        <DownOutlined className={adh.userButtonIcon}/>
                    </Flex>
                    <Flex vertical className={adh.userContextMenu}
                          style={{display: userContextMenuCollapsed ? 'none' : 'flex'}}>
                        <span onClick={() => {
                            navigate('/')
                            AuthService.logout()
                        }} className={adh.userContextMenu__item}>
                          <div style={{backgroundImage: `url(${icon_admin_logout})`}}
                               className={adh.userContextMenu__item_image}></div>
                          <Typography.Text className={adh.userContextMenu__item_text}>Выйти</Typography.Text>
                        </span>
                        {/*<Link to={'/profile/admin'} className={adh.userContextMenu__item}>*/}
                        {/*    <div style={{backgroundImage: `url(${icon_admin_user})`}}*/}
                        {/*         className={adh.userContextMenu__item_image}></div>*/}
                        {/*    <Typography.Text className={adh.userContextMenu__item_text}>Аккаунт</Typography.Text>*/}
                        {/*</Link>*/}
                        <Link to={'/'} className={adh.userContextMenu__item}>
                            <div style={{backgroundImage: `url(${icon_admin_marketplace})`}}
                                 className={adh.userContextMenu__item_image}></div>
                            <Typography.Text className={adh.userContextMenu__item_text}>Маркетплейс</Typography.Text>
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default HeaderAdmin
