import React from 'react'
import ca from '../../common__Admin.module.scss'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {Button, Input, Layout} from 'antd'

import {useNavigate} from 'react-router'
import {Asterisk} from 'lucide-react'

const EditingAccount_staffAdmin = () => {
  const navigate = useNavigate()
  return (
    <Layout style={{ rowGap: '24px' }}>
      <Layout className={ca.common_Div}>
        <Layout className={ca.common_Revers}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            className={ca.common_ReverseNavigation}
            onClick={() => navigate(-1)}
          >
            Назад
          </Button>
          <span className="fontSiz_24_500">
            Редактирование аккаунта сотрудника
          </span>
        </Layout>
        <Layout className={ca.common_MainContent}>
          <Layout className={ca.common_FormLeft}>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">ФИО</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="ФИО"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">E-mail</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="E-mail"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
            <Layout>
              <label htmlFor="" className={ca.modalContent__labFlex}>
                <span className="fontSiz_16_400Blc">Телефон</span>
                <Asterisk
                  color="red"
                  style={{ width: '14px', paddingBottom: '10px' }}
                />
              </label>
              <Input
                allowClear
                className={ca.modalContent__inptStaff}
                placeholder="Телефон"
                //   value={newCompanyAd.name}
                // onChange={(e) =>
                //   setNewCompanyAd({ ...newCompanyAd, name: e.target.value })
                // }
              />
            </Layout>
          </Layout>
          <Layout className={ca.common_DeledBtn}></Layout>
        </Layout>
      </Layout>
      <Layout className={ca.common_BtnSave}>
        <Button style={{ width: '100%' }}>Сохранить </Button>
        <Button style={{ width: '100%' }}>Отменить изменения</Button>
      </Layout>
    </Layout>
  )
}

export default EditingAccount_staffAdmin
