import React, { useState } from 'react'
import p from '../../../../Web/profile/profile.module.scss'

import {
  Button,
  Input,
  InputNumber,
  Form,
  Popconfirm,
  Table,
  Typography,
  Popover,
  Radio,
  Layout,
} from 'antd'
import { ListProdARR } from '../../../../Utils/ListProductsARR'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}
const ListProducts_Distributor = () => {
  const { Search } = Input
  const [form] = Form.useForm()
  const [listArr, setListArr] = useState(ListProdARR)
  const [editingKey, setEditingKey] = useState('')
  const isEditing = (record) => record.key === editingKey
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    })
    setEditingKey(record.key)
  }
  const cancel = () => {
    setEditingKey('')
  }
  const save = async (key) => {
    try {
      const row = await form.validateFields()
      const newData = [...listArr]
      const index = newData.findIndex((item) => key === item.key)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        setListArr(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        setListArr(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  const content = (
    <Layout>
      <ul>
        <li>3D</li>
        <li>фото</li>
        <li>сертификат товара</li>
      </ul>
    </Layout>
  )
  const columns = [
    {
      title: '',
      dataIndex: 'operation',
      width: '15%',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Сохранить
            </Typography.Link>
            <Popconfirm
              title="Уверены, что хотите отменить?"
              onConfirm={cancel}
            >
              <a>Отмена</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
          >
            <EditOutlined />
          </Typography.Link>
        )
      },
    },
    {
      title: 'Артикул',
      dataIndex: 'key',
      //   width: '25%',
      editable: true,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      //   width: '15%',
      editable: true,
      render: (text) => (
        <Layout className={p.common_column_status}>
          {text}
          {text === 'На доработку' && (
            <Popover content={content} title="Отсутствуют">
              <InfoCircleOutlined />
            </Popover>
          )}
        </Layout>
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      //   width: '20%',
      editable: true,
    },
    {
      title: 'Производитель',
      dataIndex: 'Manufacturer',
      //   width: '10%',
      editable: true,
    },
    {
      title: 'Дата добавления',
      dataIndex: 'dateAddition',
      //   width: '10%',
      editable: true,
    },
    {
      title: 'Наличие',
      dataIndex: 'availability',
      //   width: '10%',
      editable: true,
    },
  ]
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_staff_topInfo}>
        <span className="fontSiz_24_500">Список товаров</span>
        <Radio.Group
          className="radioGrpBtn"
          defaultValue="all"
          buttonStyle="solid"
          onChange={(e) => console.log(e.target.value)}
        >
          <Radio.Button className="radBtn" value="all">
            Все
          </Radio.Button>
          <Radio.Button className="radBtn" value="InStock">
            В наличии
          </Radio.Button>
          <Radio.Button className="radBtn" value="UnderOrder">
            Под заказ
          </Radio.Button>
          <Radio.Button
            className="radBtn"
            value="ForRevision"
            style={{ width: '165px' }}
          >
            На доработку
          </Radio.Button>
          <Radio.Button
            className="radBtn"
            value="WithdrawnSale"
            style={{ width: '185px' }}
          >
            Сняты с продажи
          </Radio.Button>
        </Radio.Group>
        <Search
          placeholder="Поиск"
          className={`${p.common_searchHead} searchHeadG`}
        />
      </Layout>
      <Layout>
        <Form form={form} component={false}>
          <Table
            className={p.common_table}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={listArr}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
            }}
          />
        </Form>
      </Layout>
    </Layout>
  )
}

export default ListProducts_Distributor
