import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Carousel, Layout, Tabs, message, Image, Upload, Select} from "antd";
import {Link, useLocation, useParams} from "react-router-dom";
import iv from './ItemView.module.scss'
import gc from '../GeneralComponent/GeneralComponent.module.scss'
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import cicss from "../../components/for_Catalog/CatalogItem/CatalogItem.module.scss";
import ItemRecommendationsManufacturer
    from "../../components/for_ItemView/ItemRecommendationsManufacturer/ItemRecommendationsManufacturer";
import DrawerItemDescription from "../../components/for_ItemView/Drawers/DrawerItemDescription";
import DrawerItemSellers from "../../components/for_ItemView/Drawers/DrawerItemSellers";
import {getPhoto} from "../../Utils/FileManager";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import {Helmet} from "react-helmet-async";
import useFetch from "../../hooks/useFetch";
import {ModalItemView3DModel} from "./ItemViewModals";

const ItemView = () => {
    const [item, setItem] = useState(null);
    const { itemId } = useParams()

    const [openModalItemView3DModel,setOpenModalItemView3DModel] = useState(false)
    const handleCloseModalItemView3DModel = () => { setOpenModalItemView3DModel(false) }

    const itemResponse = useFetch()
    useEffect(() => {
        itemResponse.get(
            `${process.env.REACT_APP_DOMAIN_BFF}/catalog/product/${itemId}`,
            setItem,
        )
    },[])

    const [drawerItemDescription, setDrawerItemDescription] = useState(false);
    const onDrawerItemDescriptionClose = () => {setDrawerItemDescription(false)}
    const [drawerItemSellers, setDrawerItemSellers] = useState(false)
    const onDrawerItemSellersClose = () => {setDrawerItemSellers(false)}

    return (
        <Layout className={iv.container}>
            <Layout className={iv.itemHead}>
                <Layout className={gc.breadcrumbsBlock}>
                    <Breadcrumbs/>
                </Layout>
                <span className={iv.itemName}>{item?.name}</span>
                <Layout className={iv.itemOtherInfo}>
                    <span className={iv.itemOtherInfoArticule}>Артикул {item?.article}</span>
                    <Layout className={iv.itemOtherInfoRating}>
                        <Layout className={iv.itemOtherInfoStars}>
                            <div className={cicss.rating} style={{'--rate': '4.7'}}>
                                <div className={cicss.star}></div>
                                <div className={cicss.star}></div>
                                <div className={cicss.star}></div>
                                <div className={cicss.star}></div>
                                <div className={cicss.star}></div>
                            </div>
                        </Layout>
                        <span className={iv.itemOtherInfoRatingCount}>{item?.reviews ? item?.reviews : ""}</span>
                    </Layout>
                </Layout>
            </Layout>
            <Layout className={iv.itemBlock}>
                <Layout className={iv.itemLeftSide}>
                    <Layout className={iv.itemLeftSideBody}>
                        <Layout className={iv.itemPictures} style={{maxWidth: '536px'}}>
                            <Carousel arrows infinite={true} autoplay className={iv.itemPictureCarousel}>
                                {item?.media?.images.map((image, i) => {
                                    return (
                                        <div key={i}>
                                            <div style={{
                                                backgroundImage: `url("${process.env.REACT_APP_DOMAIN_CDN}${image}")`,
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                width: '536px',
                                                height: '529px'
                                            }}></div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                            <Layout className={iv.itemPictures_controls}>
                                <Button className={iv.control_button} onClick={() => { setOpenModalItemView3DModel(true) }}>3D</Button>
                            </Layout>
                        </Layout>
                        <Layout className={iv.itemLeftSideBodyDescription}>
                            <Layout className={iv.itemShortDescriptionBlock}>
                                <table className={iv.itemDescriptionShort}>
                                    <tbody>
                                    <tr>
                                        <td>Производитель</td>
                                        <td>{item?.manufacture?.name}</td>
                                    </tr>
                                    {item?.params?.map((el, i) => {
                                        if (i > 2) return
                                        return (
                                            <tr key={i}>
                                                <td>{el.alias}</td>
                                                <td>{el.value}</td>
                                            </tr>
                                        )
                                    })}
                                    {item?.params?.length > 4 ?
                                        <tr>
                                            <td colSpan={2} style={{padding: 0}}>
                                                <Button
                                                    type={'link'}
                                                    className={iv.linkFullDescription}
                                                    onClick={() => {
                                                        setDrawerItemDescription(true)
                                                    }}
                                                >
                                                    Все характеристики
                                                </Button>
                                            </td>
                                        </tr> : ""}
                                    </tbody>
                                </table>
                            </Layout>
                            {/*<Layout className={iv.itemParameters}>*/}
                            {/*    <span style={{fontWeight: 500}}>Параметры</span>*/}
                            {/*    <Select className={iv.itemParametersSelect}/>*/}
                            {/*</Layout>*/}
                            <Layout className={iv.itemVarietyFieldsBlock}>
                                <span style={{fontWeight: 500}}>Области применения</span>
                                <Layout className={iv.itemVarietyFields}>
                                    <Link className={iv.itemVarietyField}>Сталь</Link>
                                    <Link className={iv.itemVarietyField}>Нержавеющая сталь</Link>
                                    <Link className={iv.itemVarietyField}>Цветные металлы</Link>
                                    <Link className={iv.itemVarietyField}>Чугун</Link>
                                </Layout>
                            </Layout>
                            <Layout className={iv.itemBottomLinks}>
                                <Link className={iv.itemDescriptionBottomLink}>Задать вопрос о товаре
                                    производителю</Link>
                                <Link className={iv.itemDescriptionBottomLink}>Все товары производителя</Link>
                                <Link className={iv.itemDescriptionBottomLink}>Все товары данного класса</Link>
                            </Layout>
                        </Layout>
                    </Layout>
                    <Tabs className={iv.itemFullDescriptionTabs}/>
                </Layout>
                <Layout className={iv.itemRightSide}>
                    <Layout className={iv.itemBracketBlock}>
                        <table>
                            <tbody>
                            <tr>
                                <td>Срок изготовления</td>
                                <td>В наличии</td>
                            </tr>
                            <tr>
                                <td>Стоимость</td>
                                <td>Под запрос у производителя</td>
                            </tr>
                            </tbody>
                        </table>
                        <Button className={iv.itemButton} onClick={() => {
                            message.info('Добавлено в корзину!')
                        }}>В корзину</Button>
                    </Layout>
                    <Layout className={iv.itemBracketRecommendations}>
                        <ItemRecommendationsManufacturer
                            productId={'uuid'}
                            setDrawerItemSellers={setDrawerItemSellers}

                            tempPrice={item?.price}
                        />
                    </Layout>
                </Layout>
            </Layout>
            <Layout className={iv.itemPlaylists}>
                {/*этот товар из подборок*/}
            </Layout>
            <Layout className={iv.itemRecomendations}>
                {/*еще могут подойти*/}
            </Layout>
            <Layout className={iv.itemsHistory}>
                {/*ранее просмотренные*/}
            </Layout>

            <DrawerItemDescription
                data={item}
                callback_cancel={onDrawerItemDescriptionClose}
                callback_open={drawerItemDescription}
            />
            <DrawerItemSellers
                callback_cancel={onDrawerItemSellersClose}
                callback_open={drawerItemSellers}

                tempPrice={item?.price}
            />
            <ModalItemView3DModel
                callback_open={openModalItemView3DModel}
                callback_close={handleCloseModalItemView3DModel}

                // url={`${process.env.REACT_APP_DOMAIN_CDN}/content/model/e6dac82b-ed67-420f-bd10-b75913ea7834`}
                url={`${process.env.REACT_APP_DOMAIN_CDN}${item?.media?.models[0] ?? `${process.env.REACT_APP_DOMAIN_CDN}e6dac82b-ed67-420f-bd10-b75913ea7834`}`}
            />
        </Layout>
    )
}

export default ItemView;