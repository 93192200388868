import React, { useState } from 'react'
import p from '../../../../Web/profile/profile.module.scss'

import { Button, Input, Select, Layout } from 'antd'
const SettingPrices_Distributor = () => {
  return (
    <Layout className={p.mainDiv}>
      <Layout className={p.common_company_topInfo}>
        <span className="fontSiz_24_500">Установка цен на товары</span>
        {/* <Button>Предварительный просмотр</Button> */}
      </Layout>
      <Layout className={p.common_company_formInfo}>
        <Layout className={p.common_ul}>
          <ul>
            <li>Инструкция по загрузке</li>
            <li>1. Инструкция по загрузке</li>
            <li style={{ color: 'rgba(141, 36, 248, 1)' }}>
              Шаблон импорта цен
            </li>
            <li>2. Заполните шаблон</li>
            <li>3. Загрузите на маркетплейс </li>
            <li>
              4. Дождитесь подтверждения о загрузке от Администратора платформа{' '}
            </li>
          </ul>
        </Layout>
        <Layout>
          <span style={{ color: 'rgba(141, 36, 248, 1)' }}>
            Что делать, если Производитель не загрузил товары?{' '}
          </span>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default SettingPrices_Distributor
