import React, {useState} from 'react'

import ca from '../common__Admin.module.scss'
import gca from '../../../Web/GeneralComponentAdmin/GeneralComponentAdmin.module.scss'

import {ReactComponent as EdditSVG} from '../../../image/Edit_light.svg'

import {useNavigate} from 'react-router-dom'
import {Button, Input, Layout, Table} from 'antd'

const Orders_admin = () => {
  const navigate = useNavigate()
  const { Search } = Input
  const [dataOrdersAd, setDataOrdersAd] = useState([
    {
      key: '1',
      issued: 'ГК Инструмент',
      supplier: 'ГК Инструмент',
      registrationDate: '24.05.2025',
    },
  ])
  const [newDataOrdersAd, setNewDataOrdersAd] = useState({
    key: dataOrdersAd.length + 1,
    issued: 'ГК Инструмент',
    supplier: 'ГК Инструмент',
    registrationDate: '24.05.2025',
  })
  const columnsOrdersAd = [
    {
      title: '',
      dataIndex: 'red',
      render: (text) => (
        <Button type="text" onClick={() => navigate('#')}>
          <EdditSVG />
        </Button>
      ),
      width: '5%',
    },
    {
      title: 'Оформил',
      dataIndex: 'issued',
    },
    {
      title: 'Поставщик',
      dataIndex: 'supplier',
    },
    {
      title: 'Дата оформления',
      dataIndex: 'registrationDate',
    },
  ]
  return (
    <Layout className={ca.common_MainDiv}>
      <Layout className={ca.common_InputGrup}>
        <Search
          suffix
          placeholder="input search text"
          style={{
            width: 200,
          }}
        />
      </Layout>
      <Layout className={ca.common_scrollWrapper}>
        <Table
          columns={columnsOrdersAd}
          dataSource={dataOrdersAd}
          className={gca.tableGeneral}
          pagination={{
            pageSize: 40,
          }}
          scroll={{ x: 'max-content' }}
        />
      </Layout>
    </Layout>
  )
}

export default Orders_admin
