import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    openLogin: false,
    openRegister: false,
}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_LOGIN_FORM':
            return { ...state, openLogin: action.payload }
        case 'TOGGLE_REGISTER_FORM':
            return { ...state, openRegister: action.payload }
        default:
            return state
    }
}

export default modalReducer