import React from 'react'
import s from './_for_help.module.css'
// import { Link } from 'react-router-dom'
const Legal_Information = () => {
  return (
    <div className={s.itemChildTab}>
      <span className="fontSiz_24_500">Правовая информация</span>
      <p className="fontSiz_16_400Blc" style={{ color: 'rgb(172, 174, 176)' }}>
        Условия использования Платформы<br></br>
        (редакция от 01.11.2022)<br></br>
        Настоящие Условия разработаны Обществом с ограниченной ответственностью
        «Энекс», они являются юридическим документом и регламентируют порядок
        использования платформы Enex.<br></br>
        Для того, чтобы сделать наше сотрудничество полезным и избежать
        недопонимания, убедительно просим Вас перед началом использования
        Платформы ознакомиться с настоящими Условиями. В случае несогласия с
        каким-либо положением Условий просим воздержаться от использования
        Платформы.<br></br>
        1. Термины и определения<br></br>
        Маркетплейс – Общество с ограниченной ответственностью «Энекс», ИНН
        5835131764, ОГРН 1195835004540, место нахождения: Россия, 440028,
        Пензенская область, г. Пенза, ул. Циолковского, 12 А.<br></br>
        Платформа – веб-сайт https://enex.market/, включая все уровни данного
        домена.<br></br>
        Пользователь – физическое либо юридическое лицо, имеющее доступ к
        Платформе посредством сети Интернет и использующее сервисы Платформы.
        <br></br>
        Товар – товарно-материальные ценности, информация о которых представлена
        на Платформе.<br></br>
        Поставщик – юридическое лицо (индивидуальный предприниматель), которое
        зарегистрировано на Платформе и разместило на ней Информацию о товаре в
        целях продажи либо в выставочных целях. <br></br>
        Производитель – Поставщик, который размещает на Платформе Товар
        собственного производства.<br></br>
        Торговая компания – Поставщик, который размещает на Платформе Товар не
        собственного производства.<br></br>
        Покупатель - юридическое лицо или физическое лицо, которое приобрело на
        Платформе Товар либо имеет намерение его приобрести.<br></br>
        Заказ – оформленный с помощью функционала Платформы запрос Покупателя на
        приобретение выбранного Товара, а также запрос на оказание услуг по
        организации доставки такого Товара.<br></br>
        Информация о Товаре – любые сведения о Товаре, размещенные Поставщиком
        на Платформе, в том числе, наименование Товара, торговая марка (модель),
        сведения об ассортименте, технических, функциональных, потребительских
        характеристиках Товара, о производителе Товара, о цене Товара, о скидках
        на Товар, о проводимых в отношении Товара маркетинговых и иных акциях.
        <br></br>
        Политика обработки персональных данных - документ, определяющий порядок
        и условия обработки Маркетплейсом персональных данных Пользователей и
        размещенный на Платформе по адресу:
        https://enex.market/data-protection/privacy-policy/.<br></br>
        Правила продажи и доставки - документ, определяющий порядок и условия
        приобретения Товара на Платформе, а также услуг по организации его
        доставки и размещенный на Платформе по адресу:
        {/* <a>https://enex.market/data-protection/payment-policy/</a> */}
        <a
          href="https://enex.market/data-protection/payment-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://enex.market/data-protection/payment-policy/
        </a>
        .<br></br>
        2. Общие положения<br></br>
        2.1. Настоящие Условия регулируют отношения, возникающие между
        Пользователем и Маркетплейсом при использовании сервисов Платформы.
        <br></br>
        2.2. Регистрируясь на Платформе, Пользователь безусловно соглашается с
        настоящими Условиями, а также принимает положения всех иных документов,
        регулирующих использование Платформы: Политики обработки персональных
        данных, Правил продажи и доставки.<br></br>
        Регистрация на Платформе является подтверждением того, что Пользователь
        ознакомился с указанными документами и обязуется соблюдать их положения.{' '}
        <br></br>
        2.3. Заключение на Платформе договоров купли-продажи/оказания услуг и
        совершение в связи с этим любых иных действий регламентируется
        применимым законодательством, настоящими Условиями, а также Правилами
        продажи и доставки.<br></br>
        2.4. Функционал Платформы предоставляется Пользователю бесплатно, если
        соглашением с Маркетплейсом не предусмотрено иное. К отношениям по
        предоставлению функционала Платформы на безвозмездной основе не подлежат
        применению положения законодательства РФ о защите прав потребителей.
        <br></br>
        2.5. Действующая редакция Условий размещена на Платформе в открытом
        доступе.<br></br>
        3. Общие условия использования Платформы<br></br>
        3.1. Использование Платформы возможно на персональном компьютере,
        мобильном телефоне, планшете или другом устройстве, подходящем для
        корректного использования Платформы. Использование Платформы допускается
        на неограниченном количестве устройств Пользователя.<br></br>
        3.2. Определенный функционал Платформы может отличаться или быть
        недоступен для Пользователя на страницах Платформы, оптимизированных для
        мобильных устройств.<br></br>
        3.3. Право использования Платформы включает в себя право использовать
        Платформу для просмотра, ознакомления, оставления комментариев/отзывов и
        реализации иного доступного функционала Платформы.<br></br>
        3.4. Пользователю предоставляется право цитировать элементы
        пользовательского контента Платформы с указанием источника цитирования,
        включающего ссылку на URL-адрес Платформы.<br></br>
        3.5. Пользователь гарантирует, что он обладает правоспособностью для
        вступления в правоотношения по использованию Платформы в соответствии с
        настоящими Условиями и иными документами, регулирующими порядок
        использования Платформы.<br></br>
        4. Общий функционал Платформы<br></br>
        4.1. На Платформе доступна база данных (информационно-справочная
        система), содержащая информацию о товарах и услугах с возможностью их
        приобретения.<br></br>
        4.2. Пользователь, не зарегистрированный/не авторизованный на Платформе
        имеет статус посетителя. Доступ посетителя к некоторым сервисам
        Платформы может быть ограничен. Например, посетитель не может размещать
        товарные предложения, оформлять Заказы, отправлять сообщения со страницы
        «Вопрос Платформе/Производителю/технологу», оставлять комментарии и
        отзывы на странице Товара и на странице Поставщика.<br></br>
        4.3. Пользователь, зарегистрированный на Платформе в качестве
        Покупателя, после прохождения авторизации, помимо просмотра страниц,
        может добавлять выбранный Товар в корзину и оформлять Заказы (если
        Поставщик установил возможность заказа Товара). В личном кабинете
        Покупатель может редактировать контактные данные, а также просматривать
        историю Заказов. Функционал оформления заказов доступен также Торговым
        компаниям.<br></br>
        4.4. Покупатель, являющийся юридическим лицом либо индивидуальным
        предпринимателем, при создании суббакаунтов может ограничить и разделить
        права на использование функционала Платформы (просмотр всей истории
        заказов, отмена неоплаченных заказов, оплата заказов, изменение статуса
        заказов, создание и редактирование страницы компании, создание новостей,
        просмотр и ответ на обращения).<br></br>
        4.5. Поставщик имеет возможность разместить на Платформе Информацию о
        Товаре самостоятельно, используя функционал Платформы, либо с
        привлечением специалистов Маркетплейса. Между Поставщиком и
        Маркетплейсом может быть заключен договор оказания услуг по
        сопровождению участия на Платформе.<br></br>
        УСЛОВИЯ ПРОДВИЖЕНИЯ<br></br>
        4.6. Сценарии участия Поставщиков на Платформе.<br></br>
        4.6.1. Производитель может разместить на платформе Информацию о Товаре
        без возможности оформить Заказ. В этом случае Информация о Товаре
        считается размещенной в целях ознакомления неопределённого круга лиц со
        свойствами и характеристиками Товара. Если Производитель примет решение
        продавать свой Товар на Платформе, то между ним и Маркетплейсом
        заключается договор, регулирующий отношения сторон при осуществлении
        продаж.<br></br>
        4.6.2. Участие на Платформе в статусе Торговой компании является
        платным, при условии размещения на Платформе предложения о продаже более
        чем 1000 наименований товара (размещение менее 1000 товарных предложений
        – бесплатно). Цена услуги зависит от выбранного тарифа. При регистрации
        в качестве Торговой компании пользователь может выбрать подходящий ему
        тариф. Сменить тариф можно в личном кабинете. При заказе тарифа будет
        произведена вся работа по созданию информационного и коммерческого
        контента на основе любой исходной информации от Торговой компании.
        <br></br>
        4.6.3. Если Поставщик примет решение продавать свой Товар на Платформе и
        загрузить на Платформу свои ценовые предложения, то межу Поставщиком и
        Маркетплейсом заключается договор, регулирующий отношения сторон при
        осуществлении продаж. Таким договором может быть: агентский договор либо
        соглашение о реализации товара. Договор может быть заключён путем
        подписания единого документа, а также путем принятия Поставщиком
        соответствующих условий, в том числе с использованием функционала
        личного кабинета. Условия считаются принятыми Поставщиком с момента
        получения Маркетплейсом соответствующего заявления.<br></br>
        УСЛОВИЯ АГЕНТИРОВАНИЯ<br></br>
        <br></br>
        УСЛОВИЯ РЕАЛИЗАЦИИ<br></br>
        4.7. Пользователь, зарегистрированный на Платформе в качестве
        Производителя, не имеет в данном аккаунте доступа к функционалу покупки
        Товара.<br></br>
        4.8. Пользователи – юридические лица и индивидуальные предприниматели
        могут публиковать на Платформе новости. Возможность публикации новостей
        предоставляется по согласованию с Маркетплейсом.<br></br>
        4.9. Если в настоящих Условиях отсутствует описание какого-либо сервиса
        Платформы, использование такого сервиса допускается в соответствии с
        доступным Пользователю интерфейсом.<br></br>
        5. Регистрация на Платформе<br></br>
        5.1. Для получения доступа к определённому функционалу Платформы
        Пользователю необходимо пройти процедуру регистрации, в результате
        которой для Пользователя будет создана уникальная учетная запись и
        личный кабинет.<br></br>
        5.2. Процедура регистрации Пользователя осуществляется в соответствии с
        правилами, установленными настоящими Условиями и актуальным интерфейсом
        Платформы.<br></br>
        5.3. Регистрация на Платформе бесплатна, если иное не установлено в
        отдельном соглашении с Маркетплейсом.<br></br>
        5.4. При регистрации на Платформе Пользователь выбирает, в качестве кого
        он регистрируется: Покупатель либо Поставщик. В случае если выбрано
        значение «Поставщик», в дальнейшем Пользователю нужно выбрать значение
        «Производитель» либо «Торговая компания». Любой Пользователь имеет
        возможность создать дополнительные учетные записи с иным статусом
        (например, Торговая компания может дополнительно зарегистрироваться в
        качестве Покупателя и/или Производителя).<br></br>
        5.5. Покупатель – юридическое лицо либо индивидуальный предприниматель
        может создавать субаккаунты.<br></br>
        5.6. Самостоятельная регистрация предусмотрена только для Покупателя.
        Для регистрации в качестве Поставщика Пользователю необходимо оставить
        заявку на Платформе через форму обратной связи.<br></br>
        5.7. После регистрации на Платформе совершение Пользователем любых
        действий в рамках использования Платформы вызывает возникновение
        соответствующих прав и обязанностей.<br></br>
        5.8. Маркетплейс оставляет за собой право отказать Пользователю в
        регистрации на Платформе без объяснения причин.<br></br>
        5.9. При регистрации на Платформе Пользователь обязуется предоставить
        достоверную и полную информацию и поддерживать эту информацию в
        актуальном состоянии. Если Пользователь предоставляет неверную
        информацию, Маркетплейс имеет право по своему усмотрению заблокировать
        либо удалить учетную запись Пользователя и отказать Пользователю в
        использовании Платформы. Маркетплейс может запрашивать у Пользователя
        предоставить документы, подтверждающие достоверность данных указанных
        при регистрации.<br></br>
        5.10. При завершении процедуры регистрации Пользователь самостоятельно
        устанавливает пароль для доступа к учетной записи. Маркетплейс вправе
        устанавливать требования к логину и паролю (длина, допустимые символы и
        т.д.). В качестве логина Пользователя может использоваться адрес
        электронной почты.<br></br>
        5.11. Выбранные Пользователем логин и пароль являются необходимой и
        достаточной информацией для авторизации на Платформе. Пользователь не
        имеет права передавать свои логин и пароль третьим лицам, несет полную
        ответственность за их сохранность, самостоятельно выбирая способ их
        хранения<br></br>
        5.12. Пользователь самостоятельно несет ответственность за безопасность
        (устойчивость к угадыванию) ввыбранных логина и пароля, а также
        самостоятельно обеспечивает их конфиденциальность. Пользователь
        самостоятельно несет ответственность за все действия с использованием
        сервисов Платформы, совершенные под его учетной записью.<br></br>
        5.13. О любом известном случае несанкционированного доступа к Платформе
        с использованием учетной записи Пользователя последний обязан
        незамедлительно сообщить Маркетплейсу.<br></br>
        5.14. При утрате пароля Пользователь может восстановить его, заполнив
        форму «Восстановить пароль».<br></br>
        5.15. Вне зависимости от удаления своей учетной записи на Платформе все
        обязательства Пользователя, в том числе действующие Заказы,
        осуществлённые посредством Платформы, остаются в силе до полного их
        исполнения.<br></br>
        6. Права и обязанности Маркетплейса и Пользователя<br></br>
        6.1. Маркетплейс вправе:<br></br>
        6.1.1. Заблокировать или удалить учетную запись Пользователя/ограничить
        использование функционала Платформы, удалить Информацию о Товаре в
        случае нарушения Пользователем настоящих Условий или условий иных
        документов, регулирующих взаимоотношения Пользователя и Маркетплейса, а
        также требований законодательства.<br></br>
        6.1.2. В целях улучшения работы Платформы собирать информацию о
        предпочтениях Пользователей и способах использования ими Платформы
        (наиболее часто используемые функции, настройки, предпочитаемое время
        доступа к Платформе), которая не является персональными данными.
        <br></br>
        6.1.3. Удалять пользовательский контент, в том числе по требованию
        уполномоченных органов либо заинтересованных лиц, если данный контент
        нарушает настоящие Условия, требования законодательства или права
        третьих лиц.<br></br>
        6.1.4. Временно прекращать работу Платформы, а равно частично или
        полностью прекращать доступ к Платформе до завершения необходимого
        технического обслуживания и (или) модернизации Платформы. Пользователь
        не вправе требовать убытков за такое временное ограничение доступности
        Платформы.<br></br>
        6.2. Маркетплейс обязуется:<br></br>
        6.2.1. В максимально короткие сроки устранять выявленные недостатки в
        работе Платформы.<br></br>
        6.2.2. Воздержаться от каких-либо действий, способных затруднить
        осуществление Пользователем предоставленного ему права использования
        Платформы.<br></br>
        6.2.3. Предоставлять по запросу Пользователя необходимую информацию по
        вопросам использования Платформы.<br></br>
        6.2.4. Обеспечить конфиденциальность и сохранность всех данных,
        полученных от Пользователя, кроме случаев, когда такое разглашение
        произошло по независящим от Маркетплейса причинам, а также за
        исключением случаем предусмотренных законодательством.<br></br>
        6.3. Пользователь вправе:<br></br>
        6.3.1. Использовать Платформу способами, предусмотренными настоящими
        Условиями, а также доступным интерфейсом Платформы.<br></br>
        6.3.2. Получать от Маркетплейса информацию, относящуюся к
        функционированию Платформы.<br></br>
        6.4. Пользователь обязуется:<br></br>
        6.4.1. При использовании Платформы не нарушать интеллектуальные права
        Маркетплейса, других Пользователей либо третьих лих.<br></br>
        6.4.2. Не использовать Платформу для осуществления противоправных
        действий.<br></br>
        6.4.3. Не предпринимать действий, в результате которых может быть
        нарушена работа Платформы либо ее отдельных сервисов.<br></br>
        6.4.4. Не размещать на Платформе любую конфиденциальную либо иную
        запрещенную к распространению информацию.<br></br>
        6.4.5. Не использовать Платформу для распространения информации
        рекламного характера, иначе как с согласия Маркетплейса.<br></br>
        6.4.6. Не использовать Платформу для:<br></br>
        загрузки контента, который пропагандирует насилие, жестокость, ненависть
        и (или) дискриминацию по расовому, национальному, половому,
        религиозному, социальному признакам; содержит недостоверные сведения и
        (или) оскорбления в адрес конкретных лиц, организаций, органов власти;
        <br></br>
        представления себя за другое лицо или представителя без достаточных на
        то прав, в том числе за сотрудников Маркетплейса;<br></br>
        введения в заблуждение относительно свойств и характеристик какого-либо
        Товара;<br></br>
        некорректного сравнения Товара, а также формирования негативного
        отношения к каким-либо лицам;<br></br>
        несанкционированного доступа к функциям и сервисам Платформы, любым
        другим системам или сетям, относящимся к Платформе, а также к любым
        услугам/товарам, предлагаемым на Платформе.<br></br>
        7. Условия рассылки<br></br>
        7.1. Маркетплейс вправе направлять своим пользователям информационные и
        рекламные сообщения (уведомления), такие как: изменение статуса заказов,
        ответы на комментарии, ответы на обращения, новости Производителей,
        новости Enex, новости покупателей, акции и специальные предложения, иные
        сообщения и уведомления.<br></br>
        7.2. В личном кабинете Пользователь может настроить параметры подписки
        (отказаться от некоторых категорий рассылки либо запретить ее
        полностью).<br></br>
        7.3. Отправка некоторых сообщений информационного характера, например,
        кассовых чеков, необходима для использования сервисов Платформы и не
        может быть отключена.<br></br>
        8. Требования к отзывам, комментариям, размещаемым новостям<br></br>
        8.1. Пользователи имеют возможность оставлять на Платформе отзывы,
        комментарии и новости (размещать новости могут Пользователи, являющиеся
        юридическими лицами либо индивидуальными предпринимателями).<br></br>
        8.2. Отзывы, комментарии и новости размещаются на Платформе после
        предварительной модерации администраторами Платформы.<br></br>
        8.3. Размещая на Платформе отзывы/комментарии/новости, Пользователь
        делает сведения, содержащиеся в них, общедоступными и принимает на себя
        все риски, связанные с таким размещением.<br></br>
        8.4. Пользователь несет полную ответственность за содержание и форму
        размещенных им отзывов/комментариев/новостей. Пользователь гарантирует,
        что отзывы/комментарии/новости не нарушают нормы законодательства и
        требования настоящих Условий.<br></br>
        8.5. Если размещенные Пользователем отзывы/комментарии/новости являются
        объектом авторского права или включают в себя такой объект, то публикуя
        их, Пользователь разрешает Маркетплейсу использовать их любым способом,
        в том числе путем воспроизведения, распространения, перевода, доведения
        до всеобщего сведения без ограничения по территории и сроку
        использования.<br></br>
        8.6. Запрещается размещать на Платформе отзывы, комментарии и новости,
        которые содержат:<br></br>
        ненормативную лексику, клевету и оскорбления, иные сведения порочащие
        чьи-либо честь и достоинство;<br></br>
        ложные сведения;<br></br>
        информацию непристойного характера;<br></br>
        персональные данные третьих лиц;<br></br>
        любые иные сведение, публичное распространение которых запрещено в
        соответствии с законодательством.<br></br>
        8.7. Маркетплейс вправе удалить отзыв/комментарий/новость в случае
        нарушения Пользователем при размещении такого материала настоящих
        Условий либо требований законодательства.<br></br>
        9. Исключительные права<br></br>
        9.1. Все объекты, размещенные на Платформе, в том числе элементы
        дизайна, текст, графические изображения, иллюстрации, базы данных и
        другие объекты, являются объектами исключительных прав Маркетплейса и
        (или) Поставщиков.<br></br>
        9.2. Использование размещенных на Платформе объектов (контента) возможно
        только в рамках функционала, предлагаемого Платформой. Никакие элементы
        Платформы не могут быть использованы иным образом без предварительного
        разрешения правообладателя. В частности, запрещается копирование,
        скачивание и любое другое использование контента Платформы в
        коммерческих целях без предварительного письменного согласия
        Маркетплейса и/или Поставщика.<br></br>
        10. Ограничение ответственности<br></br>
        10.1. Пользователь использует Платформу на свой собственный риск.
        Маркетплейс не принимает на себя никакой ответственности, в том числе за
        соответствие функционала Платформы целям Пользователя.<br></br>
        10.2. Все сервисы Платформы предоставляются «как есть», «как доступно»,
        Маркетплейс не гарантирует бесперебойную работу Платформы и соблюдение
        каких-либо сроков восстановления ее работоспособности в случае перерывов
        в работе. Маркетплейс не несет ответственности ни за какие задержки,
        сбои, ошибки в работе Платформы, неверную или несвоевременную доставку
        сообщений. При этом Маркетплейс обязуется принимать все разумные меры
        для предотвращения указанных неисправностей и оперативного устранения
        неисправностей (сбоев).<br></br>
        10.3. Маркетплейс не несет ответственности за неисполнение либо
        ненадлежащее исполнение своих обязательств вследствие сбоев в
        телекоммуникационных и энергетических сетях, действий вредоносных
        программ, а также недобросовестных действий третьих лиц, направленных на
        несанкционированный доступ и/или выведение из строя программного и/или
        аппаратного комплекса Платформы.<br></br>
        10.4. Маркетплейс не несет ответственности за ненадлежащее
        функционирование Платформы, в случае если Пользователь не имеет
        необходимых технических средств для ее использования.<br></br>
        10.5. Маркетплейс не несет ответственности за утерю Пользователем своих
        регистрационных данных, в том числе по причине мошеннических действий
        третьих лиц.<br></br>
        10.6. Маркетплейс не несет ответственности за убытки, которые
        Пользователь может понести в результате того, что его логин и пароль
        стали известны третьему лицу по вине Пользователя.<br></br>
        10.7. Маркетплейс не несет ответственности за содержание сообщений или
        материалов Пользователей (пользовательский контент), любые мнения,
        рекомендации или советы, содержащиеся в таком контенте.<br></br>
        11. Заключительные положения<br></br>
        11.1. Настоящие Условия вступают в силу с момента опубликования на
        Платформе и действуют до их отмены (отзыва) Маркетплейсом.<br></br>
        11.2. Настоящие Условия могут быть изменены и/или дополнены
        Маркетплейсом в любое время в одностороннем порядке по своему усмотрению
        без указания причин и без получения чьего-либо согласия, а также без
        какого-либо специального уведомления Пользователей. Измененные Условия
        вступают в силу с момента их публикации на Платформе.<br></br>
        11.3. Если по каким-либо причинам одна или несколько норм настоящих
        Условий окажутся недействительными (не имеющими юридической силы), то
        это не оказывает влияния на действительность или применимость остальных
        норм.<br></br>
        11.4. Пользователь и Маркетплейс признают всю информацию, полученную
        Пользователем в процессе работы Пользователя с Платформой и получения
        услуг Маркетплейса, обладающей конфиденциальностью (конфиденциальной
        информацией). Пользователь обязуется не разглашать конфиденциальную
        информацию, за исключением случаев, предусмотренных законодательством
        или когда такая информация является общедоступной, или по согласованию с
        Маркетплейсом.<br></br>
        11.5. Маркетплейс обязуется обеспечить конфиденциальность персональных
        данных Пользователей в соответствии с условиями Политики
        конфиденциальности.<br></br>
        11.6. К отношениям, которые складывают в связи с использованием
        Платформы, применяется законодательство Российской Федерации. Настоящие
        Условия регулируются и толкуются в соответствии с законодательством
        Российской Федерации. Вопросы, не урегулированные настоящими Условиями,
        подлежат разрешению в соответствии с законодательством Российской
        Федерации.<br></br>
        11.7. Контакты для получения информации о работе Платформы и порядке ее
        использования:<br></br>
        ООО «Энекс»<br></br>
        Телефон: 8 800 707-56-00<br></br>
        E-mail: info@enex.market
      </p>
    </div>
  )
}

export default Legal_Information
