import React, { createContext, useContext } from 'react';
import {notification} from "antd";

const NotificationContext = createContext();



export const NotificationProvider = ({ children }) => {
    const openNotification = (type='info',message, description) => {
        notification[type]({
            message,
            description,
            showProgress: true,
            pauseOnHover: true,
        })
    }

    return (
        <NotificationContext.Provider value={{ openNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = () => {
    return useContext(NotificationContext);
};