import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from 'antd'
import ce from './CatalogExplorer.module.scss'
import { getPhoto } from '../../Utils/FileManager'
import CatalogExplorerItem from '../../components/for_Catalog/CatalogExplorerItem/CatalogExplorerItem'
import gc from '../GeneralComponent/GeneralComponent.module.scss'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'

const CatalogExplorer = () => {
  const { category } = useParams()
  const [catalogs, setCatalogs] = useState([])
    const [refetch, setRefetch]= useState(null)
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DOMAIN_BFF}/catalog/api/nav/${category.split('+').join('/')}`,
      {
        method: 'GET',
      }
    )
      .then((res) => {
          return res
      })
      .then((res) => res.json())
      .then((res) => {
          if (!!!res.id) setRefetch(!refetch) // TODO: исправить
        setCatalogs(res)
      })
  }, [category, refetch])

  return (
    <Layout className={ce.container}>
      <Layout className={gc.breadcrumbsBlock}>
        <Breadcrumbs breadcrumbs={catalogs} />
      </Layout>
      <Layout className={ce.headerBlock}>
        <span className={ce.headerTitle}>Каталог</span>
        <Layout className={ce.headerItemsCount}>
          <span>{catalogs?.inner?.length} товаров</span>
        </Layout>
      </Layout>
      <Layout className={ce.catalogsGrid}>
        {catalogs?.inner?.map((el, i) => {
          return <CatalogExplorerItem element={el} />
        })}
      </Layout>
      <Layout></Layout>
    </Layout>
  )
}

export default CatalogExplorer
