import React, {useCallback} from 'react'
import {useNavigate} from "react-router-dom";
import {AuthService} from "../services/AuthService";
import {message} from "antd";


const useFetch = () => {
    const navigate = useNavigate()
    const get = async (url, setState, callback_after=()=>{} ) => {
        try {
            await fetch(url, {
                method: 'GET',
            })
                .then(res => {
                    switch (res.status) {
                        case 200:
                        case 202:
                            return res.json();
                        case 401:
                            message.error('401. Неавторизовано')
                            break;
                        case 403:
                            message.warning(`Отказано в доступе: 403`)
                            setTimeout(() => navigate('/'), 1000)
                            break;
                        case 404:
                            message.error('Ошибка: Not Found')
                            break;
                        default:
                            break;
                    }
                })

                .then(res => {
                    setState(res)
                    callback_after()
                })
        } catch (e) {
            message.error('Что-то пошло не так! Мы уже разбираемся в этой ошибке.')
            console.error(e)
        }
    }
    const post = async (url, setState, body, customError=null) => {
        try{
            console.log(body)
            return await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
                .then(res => {
                    switch (res.status) {
                        case 200:
                        case 202:
                            if (customError !== null) {
                                customError['200']()
                            }
                            break;
                        case 401:
                            message.error('401. Неавторизовано')
                            break;
                        case 403:
                            message.warning(`Отказано в доступе: 403`)
                            setTimeout(() => navigate('/'), 1000)
                            break;
                        case 404:
                            message.error('Ошибка: Not Found')
                            break;
                        case 500:
                            if (customError !== null) {
                                customError['500']()
                            } else {
                                message.error('Ошибка! Internal Server Error')
                            }
                            break;
                        default:
                            break;
                    }
                })
                .then(res => {
                    if(setState !== undefined && setState !== null)
                        setState(res)
                    return res
                })
        } catch(e) {
            message.error('Что-то пошло не так! Мы уже разбираемся в этой ошибке.')
            console.error(e)
        }}
    return { get , post }


}

export default useFetch;