import React from 'react'
import s from './HistoryAdding.module.css'
const HistoryAdding = () => {
  return (
    <div className={s.mainDiv}>
      <div className={s.topInfo}>
        <span className="fontSiz_24_500">История добавления</span>
      </div>
    </div>
  )
}

export default HistoryAdding
